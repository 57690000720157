import React, {useEffect , useState } from 'react';
import { connect } from 'react-redux';
import * as moment from 'moment';
import dollar from  '../../assets/images/dollar.svg';
import { exportFinancial } from '../../actions/adminActions';
import Spinner from '../Explorer/Spinner';

function FileAdminItem(props) {
  const [animFade , setAnimFade ] = useState("d-none");
  const [ isUploadedFile , setUploadedFile ] = useState(0);
  const [ financialIsLoading , setFinancialIsLoading ] = useState(false);

  var item = props.data;

  var exportData = props.export;

  var timer;

  function launchAnim() {
    setAnimFade("slide-in-bottom item-list-row")
    clearTimeout(timer)
  }

  useEffect(() => {
    timer = setTimeout(launchAnim, 100 * props.index);
    return () => {
      clearTimeout(timer)
      setAnimFade('d-none')
    };
  }, [item])

  useEffect(() => {
    var found = props.uploadedList.find(updated => updated._id === item._id);
    if(found) {
      setUploadedFile(found)
    } else {
      setUploadedFile()
    }
  }, [props.uploadedList]);

  useEffect(() => {
    if(exportData && exportData.financial) {
      setFinancialIsLoading(false);
    }
  },[exportData]);

  function renderFileStateColor() {
    if(isUploadedFile.fileState === 'error') {
      return 'rgba(241, 130, 141, 0.2)'
    } else if (isUploadedFile.fileState === 'fileUpdated') {
      return 'rgba(123, 239, 178, 0.2)'
    } else {
      return;
    }
  }

  function exportFinancial() {
    setFinancialIsLoading(true);
    props.exportFinancial({
      company: item._id,
      type:"finance"
    })
  }

  return (
    <>
      <div id={props.index + item.name} className={animFade}>
        <div style={{ backgroundColor : item.color ? item.color : '#A1BCDE' , width: 15 , height: '100%' , border: '1px solid ' + item.color ? item.color : '#A1BCDE' }}>
        </div>
        <div className="item name">
          {item.name}
        </div>
        <div className="item-update-date">
          {item.financeLastPeriod && <>Last Period <span className="date">{item.financeLastPeriod}</span><br/></> }
          Last update <span className="date">{moment(item.updatedAt).locale('en').format('LLLL')}</span>
        </div>
        {props.activeList === 'Manufacturer' ?
          <>
           {financialIsLoading?
            <div className="spinner-container">
              <Spinner />
            </div>
            :
            <button className="btn-blue-light-rounded"
              onClick={() => exportFinancial()}>
              <img style={{ width: 7 }} src={dollar} alt="financial"/>
              Control data
            </button>
          }
            <button
              className="btn-blue-light-rounded submit"
              onClick={() => props.openModale(item , "financial")}>
              <img style={{ width: 7 }} src={dollar} alt="financial"/>
              Submit file
            </button>
          </>
          :
          null
        }
        {isUploadedFile && isUploadedFile.progress > 0 ?
          <div className="progress-bar">
            <div
              className="progress"
              style={{
                transition: 'all 0.5s ease-in',
                width:  isUploadedFile.progress + '%',
                backgroundColor : renderFileStateColor()
              }}
            />
          </div>
          :
          null
        }
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return({
    uploadedList: state.admin.uploadedList,
    export: state.admin.export
  });
}

const mapDispatchToProps = {
  exportFinancial: exportFinancial
}

export default connect(mapStateToProps, mapDispatchToProps)(FileAdminItem);