import React, { Component } from 'react';
import _ from 'lodash';
import * as d3 from 'd3';

import '../../styles/css/industry.css';
import '../../styles/css/analysis.css';

import { hexToRGBa , setLabelColor } from '../../utils/utils';
import DataVizPercentage from '../DataViz/DataVizPercentage';
import DataVisSankey from '../DataViz/DataVizSankey';
import DataVizMultipleBars from '../DataViz/DataVizMultipleBars';
import DataVizWaterfall from '../DataViz/DataVizWaterfall';
import DataVizNoData from '../DataViz/DataVizNoData';
import DataVizMap from '../DataViz/Map/DataVizMap';
import Spinner from '../Explorer/Spinner';

export default class Industry extends Component {
    constructor(props) {
        super(props)
        this.state = {
            waterfallData: [],
            displayWaterfall: false,
            industrialFootprint: [],
            footprintLp: {}
        }
    }
    componentDidMount() {
        this.setStateWaterfall()
        this.setColorFootprintLp()
        this.setColorIndustrialFootprint()
        this.props.scrollToTop("industry-top")
    }

    componentDidUpdate(prevProps) {
        if(prevProps !== this.props ) {
            this.setStateWaterfall()
            this.setColorFootprintLp()
            this.setColorIndustrialFootprint()
        }
    }

    renderIndustrialCapacitySales = () => {
        if (this.props.analysisData.compare && this.props.analysisData.compare.length > 0) {
            let yearsFilter = this.props.analysisData.compare.filter(i => i.year <= this.props.selectedYear.toString())
            const yearsValues = yearsFilter.filter(i => i.year > (this.props.selectedYear - 5).toString())
            yearsValues.map( item => {
                    item.valueCapacityColor = item.color
                    item.valueSalesColor = "#CFDDEE"
                    return item
            })
            return (
                <div className="height-280" id="curve-size">
                    <DataVizMultipleBars
                        margin={{
                            "top": 35,
                            "right": 35,
                            "bottom": 50,
                            "left": 50
                        }}
                        keys={[
                            "valueCapacity",
                            "valueSales"
                        ]}
                        tickPadding={15}
                        padding={0.6}
                        groupMode="grouped"
                        indexBy="year"
                        colorBy={function(e){var t = e.id ;return e.data[ t+"Color"]}}
                        displayLabel={false}
                        layers={[
                            "grid",
                            "axes",
                            "bars",
                            this.generateCapacitySalesLine,
                        ]}
                        tooltip={d => (
                            <span style={{ color: '#000000' }}>
                             <strong style={{ color: d.color }}> {d.indexValue} {d.id === "valueCapacity" ? "capacity" : "sales"} </strong> { d.value.toFixed()} Kt{d.id === "valueCapacity" ? "c" : " sold"}
                            </span>
                        )}
                        data={yearsValues}/>
                </div>
            )
        }
    }

    renderFactoriesCapacity = () => {
        if (this.props.analysisData.factories && this.props.analysisData.factories.length > 0) {
            const yearData = this.props.analysisData.factories.filter( item => item.year === this.props.selectedYear.toString())
            yearData[0].data.map(d => d.value = parseFloat(d.value))
            return  <div className="height-300" id="curve-size">
                        <DataVizMultipleBars
                            margin={{
                                "top": 35,
                                "right": 35,
                                "bottom": 120,
                                "left": 50
                            }}
                            keys={[
                                "value",
                            ]}
                            tickPadding={10}
                            padding={0.5}
                            groupMode="stacked"
                            indexBy="label"
                            displayLabel={false}
                            tickRotation={-40}
                            colorBy={function(e){var t = e.id ;return e.data[ t+"Color"]}}
                            layers={[
                                "grid",
                                "axes",
                                "bars",
                                this.generateCapacitySalesLine,
                            ]}
                            data={yearData[0].data}
                            unit={"ktc"}
                            tooltip={(d) => {
                                return (
                                    <div className="tooltip">
                                        <span style={{ color: "#000000" }}><strong style={{ color: setLabelColor(d.color) }}>{d.data.label}, {d.data.country}, {d.data.company} </strong> {" " +Math.round(d.value)} ktc</span>
                                    </div>
                                )
                              }}
                              />
                    </div>
        } else {
            return <DataVizNoData />
        }
    }
    
    renderBiggestPlayerByLP = () => {
        if (this.props.analysisData.biggestPlayers ) {
            const biggestData = this.props.analysisData.biggestPlayers.filter( item => item.label === this.props.selectedYear.toString())
            if (biggestData[0].data.length > 0) {
                return <DataVizMultipleBars
                            keys={[
                                "value",
                            ]}
                            margin={{
                                "top": 35,
                                "right": 35,
                                "bottom": 70,
                                "left": 50
                            }}
                            padding={0.5}
                            tickPadding={10}
                            groupMode="stacked"
                            indexBy="label"
                            displayLabel={false}
                            // label={d => d.data["code"]}
                            tickRotation={-40}
                            layers={[
                                "grid",
                                "axes",
                                "bars",
                            ]}
                            data={biggestData[0].data}
                            colorBy={function(e){var t = e.id ;return e.data[ t+"Color"]}}
                            unit={"Mu"}
                            tooltip={({ indexValue, value, color }) => (
                                <div className="tooltip">
                                    <span><strong style={{ color }}>{indexValue} :</strong> {value.toFixed(2)} Mu</span>
                                </div>
                                )}
                            />
            } else {
                return <DataVizNoData />
            }
        } else {
            return <DataVizNoData />
        }
    }

    setColorFootprintLp = () => {
        let footprint = []
        if (this.props.analysisData.footprint) {
            let data = this.props.analysisData.footprint.filter( year => year.label === this.props.selectedYear.toString())
            let dataObj = {}
            data[0].data.map((item) => {
                if (item.value > 0) {
                    return dataObj[item.id] = { value : Math.round(item.value) }
                }
                return dataObj
            })
            let coloredData = _.cloneDeep(dataObj)
            let maxArray = []
            for (let i in coloredData) {
                maxArray.push(coloredData[i].value);
            }
            const max = _.max(maxArray)
            let color =
                d3.scaleLinear()
                .domain([0, max ])
                .range([ hexToRGBa("#1C4998", 0.2) , d3.rgb("#1C4998")]);
            for (let val in coloredData) {
                val = Object.assign( coloredData[val], {color : color(coloredData[val].value)})
            }

            footprint.push(coloredData)
            this.setState({
                footprintLp: footprint[0]
            })
        }
    }

    renderFootprintLp = () => {
        if (this.state.footprintLp !== {}) {
            return (
                <div className="big-map">
                    <DataVizMap
                        enableMarkers={false}
                        iconSize={ 15 }
                        unit={"ktp"}
                        enableZoom={true}
                        color={"#1C4998"}
                        mapDatas={this.state.footprintLp}/>
                </div>
            )
        } else {
            return (
                <div className="big-map">
                    <DataVizNoData/>
                </div>
            )
        }
    }

    setColorIndustrialFootprint = () => {
        let industrialFootprint = []
        if (this.props.analysisData.industrialFootprint) {
            this.props.analysisData.industrialFootprint.map((item) => {
            if (item.years.length > 0) {
                let data = item.years.filter( year => year.label === this.props.selectedYear.toString())
                let dataObj = {}
                data[0].data.map(item => {
                    return dataObj[item.id] = { value : Math.round(item.value) , valueZone : Math.round(item.valueZone) , markers : item.markers}
                })
                let coloredData = _.cloneDeep(dataObj)
                let maxArray = []
                let maxArrayZone = []
                for (let i in coloredData) {
                    maxArray.push(coloredData[i].value);
                    maxArrayZone.push(coloredData[i].valueZone);
                }
                const max = _.max(maxArray)
                const maxZone = _.max(maxArrayZone)
                let color =
                    d3.scaleLinear()
                    .domain([0, max ])
                    .range([ d3.rgb("#E5E5E5").darker(0.1) , d3.rgb(item.color)]);
                let colorZone =
                    d3.scaleLinear()
                    .domain([0, maxZone ])
                    .range([ d3.rgb("#E5E5E5").darker(0.1) , d3.rgb(item.color)]);
                for (let val in coloredData) {
                    val = Object.assign( coloredData[val], {color : color(coloredData[val].value)}, {colorZone : colorZone(coloredData[val].valueZone)}, {unit : item.unit}, { colorId : item.color}, {name: item.name})
                }
                return industrialFootprint.push(coloredData)
            } else {
                return industrialFootprint.push("no-data")
            }
        })

        this.setState({
            industrialFootprint: industrialFootprint
        })
    }
    }

    renderIndustrialFootprint = () => {
        let render = this.state.industrialFootprint.map((item, index) => {
            if (item !== "no-data") {
                const styledText = { color: setLabelColor(this.props.analysisData.industrialFootprint[index].color)}
                return (
                    <div className="col-global-map" key={index} >
                        <div className="global-map">
                            <DataVizMap
                                enableMarkers={true}
                                enableZoom={true}
                                unit={"ktp"}
                                iconSize={ 8 }
                                setColorOnZoom={this.setStateIndustrialFootprint}
                                color={this.props.analysisData.industrialFootprint[index].color}
                                mapDatas={item}/>
                        </div>
                        <span className="map-legend" style={styledText}>{this.props.analysisData.industrialFootprint[index].name}</span>
                    </div>
                )
            } else {
                const styledText = { color: this.props.analysisData.industrialFootprint[index].color}
                return (
                    <div className="col-global-map" key={index} >
                        <div className="global-map-no-data">
                            <DataVizNoData />
                        </div>
                        <span className="map-legend" style={styledText}>{this.props.analysisData.industrialFootprint[index].name}</span>
                    </div>
                )
            }
        })
        return render
    }

    renderTireNewRetreaded = () => {
        const NewRetreaded = this.props.analysisData.production.map((item, index) => {
            let value;
            if (item.years.length > 0) {
                value = item.years.filter( year => year.label === this.props.selectedYear.toString())[0].value
            } else {
                value = null
            }
            return <DataVizPercentage
                        idIsOnActiveIdList={this.props.idIsOnActiveIdList}
                        activeIdList={this.props.activeIdList}
                        data={item}
                        unit={"ktp"}
                        value={value !== null ? value.toFixed(): value}
                        key={index}/>
        })
        return NewRetreaded
    }

    renderBalanceCaProducts = () => {
        const NewRetreaded = this.props.analysisData.ratio.map( (item, index) => {
            let value;
            if (item.years.length > 0) {
                value = item.years.filter( year => year.label === this.props.selectedYear.toString())[0].value
            } else {
                value = null
            }
            return <DataVizPercentage
                        idIsOnActiveIdList={this.props.idIsOnActiveIdList}
                        activeIdList={this.props.activeIdList}
                        data={item}
                        unit={"€/kg"}
                        value={value !== null ? value.toFixed(2): value}
                        key={index}/>
            })
        return NewRetreaded
    }

    setStateWaterfall = () => {
        let dataSet = []

        if (this.props.analysisData.waterfall && this.props.analysisData.waterfall.length > 0 ) {
            // sort by year 
            this.props.analysisData.waterfall.sort((a, b) => (a.label > b.label) ? 1 : -1);
        
            const firstYearCalc = this.props.selectedYear - 5
            const firstYear = this.props.analysisData.waterfall.filter( year => year.label === firstYearCalc.toString())
            if (firstYear.length > 0) {
                dataSet.push(firstYear[0])
            } else {
                dataSet.push(this.props.analysisData.waterfall[0])
            }
            
            const middleYearCalc = this.props.selectedYear
            dataSet.push(this.props.analysisData.waterfall.filter( year => year.label === middleYearCalc.toString())[0])
            
            const lastYearCalc = this.props.selectedYear + 5
            const lastYear = this.props.analysisData.waterfall.filter( year => year.label === lastYearCalc.toString())
            if (lastYear.length > 0) {
                dataSet.push(lastYear[0])
            } else {
                dataSet.push(this.props.analysisData.waterfall.filter( year => year.label === "2024")[0])
            }

            if (dataSet[0].label === dataSet[1].label || parseFloat(dataSet[1].label) < parseFloat(dataSet[0].label) + 5 ) {
                dataSet = dataSet.slice(1)
            }
            if (dataSet.length > 2 ) {
                if (dataSet[1].label === dataSet[2].label || parseFloat(dataSet[1].label) + 5 > 2024) {
                    dataSet.pop()
                }
            }

            // sort by label

            var prodZonesSort = ['EUR','NCA','JPK',  'ANA', 'CHN', 'AIM' , 'EUO', 'ADS'];

            var salesZonesSort = ['EUR','NCA','E2A', 'CHN', 'AIM' , 'EUO', 'ADS'];

            // var productSort = ['PC/LT' , 'TB' ,'Off The Road' , 'AG' , '2W' , 'AR'];

            var productSort = ['TC' , 'TB', 'GC', 'G4', 'AG' , '2W', '2M',  'AR' , 'AP' ]

            dataSet.forEach(year => {
                // console.log('prodZonesSort.length' , prodZonesSort.length)
                // console.log('salesZonesSort.length' , salesZonesSort.length)
                // console.log('productSort.length' , productSort.length)
                if (year.data.length === prodZonesSort.length  && year.data.find(item  => item.label === 'EUR') ) {
                    year.data = this.sortWaterfalldataByLabel(year.data , prodZonesSort );
                } else if (year.data.length === salesZonesSort.length && year.data.find(item  => item.label === 'EUR') ) {
                    year.data = this.sortWaterfalldataByLabel(year.data , salesZonesSort );
                } else if (year.data.find(item  => item.label === "TC") ) {
                    year.data = this.sortWaterfalldataByLabel(year.data , productSort );
                }
            });

            var allDatas = 0 ;
            dataSet.forEach(item => {
                return allDatas += item.data.length
            });
            if (allDatas > 0) {
                this.setState({
                    displayWaterfall: true,
                    waterfallData: dataSet
                });
            } else {
                this.setState({
                    displayWaterfall: true,
                    waterfallData: []
                });
            }

        } else {
            this.setState({
                waterfallData: dataSet
            })
        }
    }

    sortWaterfalldataByLabel = (data , labels )  => {
        var sorted = data.sort((a , b) => {
            if(labels.indexOf(a.label) < labels.indexOf(b.label)) {
                return -1;
            } else if(labels.indexOf(a.label) > labels.indexOf(b.label)){
                return 1;
            }
            return 0;
        })
        return sorted;
    }

    renderWaterfall = ()  => {

        if (this.state.waterfallData.length > 0 && this.state.displayWaterfall ) {
            return <DataVizWaterfall
                        width={650}
                        height={478}
                        waterfallData={this.state.waterfallData}/>
        } else if (this.state.waterfallData.length === 0 && this.state.displayWaterfall ) {
            return (
                <div className="height-478">
                    <DataVizNoData />
                </div>
            )
        } else {
            return (
                <div className="height-478 spinner-container">
                    <Spinner />
                </div>
            )
        }
    }

    renderSankey = () => {
        let sankeyData = null
        if (this.props.analysisData.sankeyChart) {
            sankeyData = this.props.analysisData.sankeyChart.filter(item => item.year === this.props.selectedYear.toString())[0]
            sankeyData.links.map(item => {
                if (item.target === "Off The Road") {
                   return item.target = "Off"
                }
                return item
            })
            sankeyData.nodes.map(item => {
                if (item.id === "Off The Road") {
                    return item.id = "Off"
                }
                return item
            });

            if (sankeyData && sankeyData.nodes.length) {
                return (
                    <DataVisSankey data={sankeyData}/>
                )
            } else {
                return (
                    <div className="height-478">
                        <DataVizNoData />
                    </div>
                )
            }
 
        } else {
            return (
                <div className="height-478 spinner-container">
                    <Spinner />
                </div>
            )
        }
    }

    renderCompaniesIndustry = () => {
        return (
            <>
                <section className="row">
                    <div className="col-1">
                        <h4 className="analysis-title">Plant Capacity (in ktc)</h4>
                        <div className="analysis-card svg-legend-blue height-300">
                            {this.renderFactoriesCapacity()}
                        </div>
                    </div>
                </section>
                <section className="row">
                    <div className="col-1">
                        <h4 className="analysis-title">Production Footprint (in ktp)</h4>
                        <div className="global-map-container">
                            {this.renderIndustrialFootprint()}
                        </div>
                    </div>
                </section>
                <section className="row">
                    <div className="col-1">
                        <h4 className="analysis-title">Global new tire production (in ktp)</h4>
                        <div className="global-map-container">
                            {this.renderTireNewRetreaded()}
                        </div>
                    </div>
                </section>
                <section className="row">
                    <div className="col-1">
                    <h4 className="analysis-title">Ratio : Turnover/ kg of products (€/kg)</h4>
                        <div className="all-balance-bar-container">
                            {this.renderBalanceCaProducts()}
                        </div>
                    </div>
                </section>
            </>
        )
    
    
    
    }

    renderProductsIndustry = () => {
        return (
            <>
                <section className="row">
                    <div className="col-2">
                        <h4 className="analysis-title">Production capacity by zone</h4>
                        <div className="analysis-card">
                            <div className="sankey-container">
                                <div className="chart-legend">ktc</div>
                                {this.renderSankey()}
                            </div>
                        </div>
                    </div>
                    <div className="col-2">
                        <h4 className="analysis-title">Production capacity evolution  by zone (-5, 0, +5 years)</h4>
                        <div className="analysis-card">
                            <div className="chart-legend">ktc</div>
                            {this.renderWaterfall()}
                        </div>
                    </div>
                </section>
                <section className="row">
                    <div className="col-2">
                        <h4 className="analysis-title">Production capacity vs sales</h4>
                        <div className="analysis-card svg-legend-blue">
                            <div className="chart-legend">ktc / kt sold</div>
                            {this.renderIndustrialCapacitySales()}
                        </div>
                    </div>
                    <div className="col-2">
                        <h4 className="analysis-title">Manufacturer capacities</h4>
                        <div className="analysis-card svg-legend-blue height-280">
                            {this.renderBiggestPlayerByLP()}
                        </div>
                    </div>
                </section>
                <section className="row">
                    <div className="col-1">
                        <h4 className="analysis-title">Tire plants capacities (in ktc)</h4>
                        <div className="analysis-card svg-legend-blue height-300">
                            {this.renderFactoriesCapacity()}
                        </div>
                    </div>
                </section>
                <section className="row">
                    <div className="col-1">
                        <h4 className="analysis-title">Production footprint (in ktp)</h4>
                        <div className="global-map-container">
                            {this.renderFootprintLp()}
                        </div>
                    </div>
                </section>
                
            </>
        )
    }

    renderZonesIndustry = () => {
        return (
                <section className="row">
                    <div className="col-2">
                        <h4 className="analysis-title">Production capacity by tire market</h4>
                        <div className="analysis-card svg-legend-blue">
                            <div className="sankey-container">
                                <div className="chart-legend">ktc</div>
                                {this.renderSankey()}
                            </div>
                        </div>
                    </div>
                    <div className="col-2">
                        <h4 className="analysis-title">Production capacity evolution by tire market (-5, 0, +5 years)</h4>
                        <div className="analysis-card">
                            <div className="chart-legend">ktc</div>
                            {this.renderWaterfall()}
                        </div>
                    </div>
                </section>
        )
    }

    render() {
        return (
        <div id="industry-top">
            {this.props.activeAnalysis === "companies" ? this.renderCompaniesIndustry() : null }
            {this.props.activeAnalysis === "products" ? this.renderProductsIndustry() : null }
            {this.props.activeAnalysis === "zones" ? this.renderZonesIndustry() : null }
        </div>
        )
    }
}
