import React, { Component } from 'react'
import InputRange from 'react-input-range'
import '../../styles/css/YearsSlider.css'

export default class YearsSlider extends Component {
    constructor() {
        super()
        this.state = {
            value: 2018 
        }
    }
      render() {
        return (
          <div>
            <InputRange
                maxValue={2024}
                minValue={2002}
                value={this.state.value}
                onChange={value => this.setState({ value })}
                onChangeComplete={() => this.props.getSelectedYear(this.state.value) }
                />
          </div>
        );
      }
    }
    
