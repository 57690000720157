import React, { Component } from 'react';
import {ResponsiveBar } from '@nivo/bar';
import '../../styles/css/financial.css';

export default class DataVizMultipleBars extends Component {
    render() {
        return (
                <>
                    <div className="chart-legend">{this.props.unit}</div>
                        <ResponsiveBar
                            data={this.props.data}
                            keys={this.props.keys}
                            indexBy={this.props.indexBy}
                            margin={this.props.margin}
                            padding={this.props.padding}
                            groupMode={this.props.groupMode}
                            colorBy={this.props.colorBy}
                            color="#004a98"
                            axisTop={null}
                            minValue={this.props.minValue}
                            maxValue={this.props.maxValue}
                            axisRight={null}
                            axisBottom={{
                                "tickSize": 0,
                                "tickPadding": this.props.tickPadding,
                                "tickRotation": this.props.tickRotation ? this.props.tickRotation : 0,
                                "legendPosition": "middle",
                                "legendOffset": 32,
                            }}
                            axisLeft={{
                                "tickSize": 0,
                                "tickPadding": 5,
                                "tickRotation": 0,
                                "legendPosition": "middle",
                                "legendOffset": -40
                            }}
                            markers={[
                                {
                                    axis: 'y',
                                    value: 0,
                                    lineStyle: { stroke: 'rgba(0, 0, 0, .35)', strokeWidth: 1 },
                                    legendOrientation: 'vertical',
                                }
                            ]}
                            theme={{
                                markers: {
                                    textColor: "#FFFFF",
                                    fontSize: 10
                                }
                            }}
                            layers={this.props.layers}
                            label={this.props.label}
                            enableLabel={this.props.displayLabel}
                            labelSkipWidth={12}
                            labelSkipHeight={12}
                            labelTextColor="#FFFFFF"
                            tooltip={this.props.tooltip}
                            animate={true}
                            motionStiffness={90}
                            motionDamping={15}
                            legends={[]}
                        />
                    </>
        )
  }
}
