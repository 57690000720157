import React, { Component } from 'react';
import * as d3 from 'd3';
import _ from 'lodash';

import '../../styles/css/analysis.css';
import '../../styles/css/global.css';

import { setLabelColor } from '../../utils/utils';

import DataVizBalanceBarCompanies from '../DataViz/DataVizBalanceBarCompanies';
import DataVizBalanceBar from '../DataViz/DataVizBalanceBar';
import DataVizPie from '../DataViz/DataVizPie';
import DataVizNoData from '../DataViz/DataVizNoData';
import DataVizMap from '../DataViz/Map/DataVizMap';

export default class Global extends Component {
    constructor(props) {
        super(props) 
        this.state = {
            commercialFootprint: [],
        }
    }

    componentDidMount() {
        this.setStateCommercialFootprint();
        this.props.scrollToTop("global-top");
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps !== this.props ) {
            this.setStateCommercialFootprint();
        }
    }

    renderBalanceTrade = () => {
        if (this.props.analysisData.balanceBar) {
            const allBalancesBars = this.props.analysisData.balanceBar.map((item, index) => {
                const yearValues  = item.years.filter( year => year.label === this.props.selectedYear.toString())
                    if (yearValues.length > 0) {
                        item.value1 = Math.round(yearValues[0].sizeP)
                        item.value2 = Math.round(yearValues[0].sizeS)
                        item.label1value = Math.round(yearValues[0].valueP)
                        item.label2value = Math.round(yearValues[0].valueS)
                        return <DataVizBalanceBar
                                    idIsOnActiveIdList={this.props.idIsOnActiveIdList}
                                    activeIdList={this.props.activeIdList}
                                    id={item.id}
                                    key={index}
                                    balanceData={item} />
                    }
                    return yearValues
                });
            return allBalancesBars;
        } else {
            return (
                <div className="height-280">
                    <DataVizNoData />
                </div>
            )
        }
    }

    renderBalanceMix = () => {
        if (this.props.analysisData.mixBar) {
            const mixBars = this.props.analysisData.mixBar.map((item, index) => {
                const yearValues  = item.years.filter( year => year.label === this.props.selectedYear.toString())
                if (yearValues.length > 0) {
                    item.tcoe = Math.round(yearValues[0].tcoe)
                    item.tcrt = Math.round(yearValues[0].tcrt)
                    item.tcoeLabel = Math.round(yearValues[0].tcoeValue)
                    item.tcrtLabel = Math.round(yearValues[0].tcrtValue)
                    item.ploe = Math.round(yearValues[0].ploe)
                    item.plrt = Math.round(yearValues[0].plrt)
                    item.ploeLabel = Math.round(yearValues[0].ploeValue)
                    item.plrtLabel= Math.round(yearValues[0].plrtValue)
                    return <DataVizBalanceBarCompanies
                                idIsOnActiveIdList={this.props.idIsOnActiveIdList}
                                activeIdList={this.props.activeIdList}
                                id={item.id}
                                key={index}
                                balanceData={item} />
                }
                return yearValues
            });
            return mixBars;
        }
    }

    renderProductLineMix = () => {
        if (this.props.analysisData.productMix) {
            const plData = []
            
            this.props.analysisData.productMix.map(item => {
                const pie = item.years.filter(d => d.label === this.props.selectedYear.toString())
                if (pie.length !== 0) {
                    const logo = item.label.split('')
                    plData.push({ label: item.label, data: pie[0].data , pictoRef : logo[0] + logo[1]})
                }
                return plData
            })

            return (
                <>
                    <h4 className="analysis-title">Production by market type<span className="line-chart-title-details"></span></h4>
                    <div className="lp-card ">
                        {plData.map((item, index ) => 
                            <div key={index} className="lp-pie">
                                <img src={process.env.PUBLIC_URL + "/assets/images/"+ item.pictoRef +".png"} className="picto-products-line" alt="picto-products-line"/>
                                <DataVizPie
                                    pieDatas={item.data}
                                    innerRadius={0.75}
                                    margin={{
                                        "top": 10,
                                        "right": 10,
                                        "bottom": 10,
                                        "left": 10
                                    }}
                                    tooltip={({ label, value, color }) => {
                                        return (
                                        <div className="tooltip">
                                            <img src={process.env.PUBLIC_URL + "/assets/images/" + item.pictoRef + ".png"} className="picto-vehicles" alt="close"/>
                                            <span style={{ color: "#000000" }}>
                                            <strong style={{ color: setLabelColor(color) }}> {label} </strong>
                                             {value.toFixed()} ktp
                                            </span>
                                        </div>
                                        )
                                    }}
                                    enableSlicesLabels={false}
                                    />
                            </div>
                        )}
                    </div>
                </>
            )
        }
    }

    setStateCommercialFootprint = () => {
        let commercialFootprint = []
        if (this.props.analysisData.commercialFootprint) {
            this.props.analysisData.commercialFootprint.map((item, index) => {
                    let data = item.years.filter( year => year.label === this.props.selectedYear.toString())
                    if (data.length > 0 ) {
                        if (data[0].data.length > 0) {
                            let dataObj = {}
                            data[0].data.map(item => {
                                return dataObj[item.id] = { value : Math.round(item.value) }
                            })
                            let coloredData = _.cloneDeep(dataObj)
                            let maxArray = []
                            for (let i in coloredData) {
                                maxArray.push(coloredData[i].value);
                            }
                            const max = _.max(maxArray)
                            let color =
                                d3.scaleLinear()
                                .domain([0, max ])
                                .range([ d3.rgb("#dddddd").darker(0.3) , d3.rgb(item.color)]);
                            for (let val in coloredData) {
                                val = Object.assign( coloredData[val], {color : color(coloredData[val].value)}, {unit : item.unit}, { colorId : item.color}, {name: item.name})
                            }
                            return commercialFootprint.push(coloredData)
                        } else {
                            return commercialFootprint.push("no-data")
                        }
                    } else {
                        return commercialFootprint.push("no-data")
                    }
            })

            this.setState({
                commercialFootprint: commercialFootprint
            })
        }
    }

    renderCommercialFootprint = () => {
        let render = this.state.commercialFootprint.map((item, index) => {
            var color = "#000000";
            if (this.props.analysisData.commercialFootprint[index] && this.props.analysisData.commercialFootprint[index].color ) {
                color = setLabelColor(this.props.analysisData.commercialFootprint[index].color);
            };
            const styledText = { color: setLabelColor(color)}
            if (item !== "no-data") {
                return (
                    <div className="col-global-map" key={index} >
                        <div className="global-map">
                            <DataVizMap
                                enableZoom={false}
                                unit={"kUp"}
                                noBorders={true}
                                color={this.props.analysisData.commercialFootprint[index].color}
                                mapDatas={item}/>
                            <span className="map-legend" style={styledText}>{this.props.analysisData.commercialFootprint[index].name}</span>
                        </div>

                    </div>
                )
            } else {
                return (
                    <div className="col-global-map" key={index} >
                        <div className="global-map-no-data">
                            <DataVizNoData />
                        </div>
                        <span className="map-legend" style={styledText}>{this.props.analysisData.commercialFootprint[index].name}</span>
                    </div>
                )
            }
        })
        return render
    }

    renderCompaniesGlobal = () => {
        return (
            <>
                <section>
                    <h5 className="analysis-section-title">Mix OE/RT sold in kT & out of Retread</h5>
                    <div className="all-balance-bar-container">
                        {this.renderBalanceMix()}
                    </div>
                </section>
                <section className="row">
                    <div className="col-2">
                        {this.props.renderMarketShare()}
                    </div>
                    <div className="col-2">
                        {this.renderProductLineMix()}
                    </div>
                </section>
                <section className="analysis-section">
                    <h4 className="analysis-title map-title">Sales per region</h4>
                    <div className="global-map-container">
                        {this.renderCommercialFootprint()}
                    </div>
                </section>
            </>
        )
    }

    renderProductsGlobal = () => {
        return (
            <section className="analysis-section">
                <h4 className="analysis-title">Balance of trade by zone<span className="lighter-details"> (without Michelin)</span></h4>
                <div className="all-balance-bar-container">
                    {this.renderBalanceTrade()}
                </div>
            </section>
        )
    }

    renderZonesGlobal = () => {
        return (
            <section className="analysis-section">
                <h4 className="analysis-section-title">Balance of trade <span>(Production/sales)</span> <span className="lighter-details"> (without Michelin)</span></h4>
                <div className="all-balance-bar-container">
                    {this.renderBalanceTrade()}
                </div>
            </section>
        )
    }

    render() {
        return (
            <div id="global-top">
                {this.props.activeAnalysis === "companies" && this.props.analysisData ? this.renderCompaniesGlobal() : null }
                {this.props.activeAnalysis === "products" && this.props.analysisData ? this.renderProductsGlobal() : null }
                {this.props.activeAnalysis === "zones" && this.props.analysisData ? this.renderZonesGlobal() : null }
            </div>
        )
    }
}

