import React, { Component } from 'react'
import {ResponsivePie} from '@nivo/pie'

export default class DataVizPie extends Component {
  render() {
    return <ResponsivePie
            data={this.props.pieDatas}
            margin={this.props.margin}
            fit={true}
            innerRadius={this.props.innerRadius}
            padAngle={0.7}
            cornerRadius={0}
            colorBy={d => d.color}
            borderWidth={0}
            enableSlicesLabels={this.props.enableSlicesLabels}
            borderColor="inherit:darker(0.2)"
            enableRadialLabels={false}
            radialLabelsSkipAngle={10}
            radialLabelsTextXOffset={6}
            radialLabelsTextColor="#FFFFFF"
            radialLabelsLinkOffset={0}
            radialLabelsLinkDiagonalLength={16}
            radialLabelsLinkHorizontalLength={24}
            radialLabelsLinkStrokeWidth={1}
            radialLabelsLinkColor="inherit"
            slicesLabelsSkipAngle={10}
            animate={true}
            tooltip={this.props.tooltip}
            motionStiffness={90}
            motionDamping={15}
        />
  }
}
