import React, { Component } from 'react'

export default class Spinner extends Component {
  render() {
    return (
      <div >
        <img src={process.env.PUBLIC_URL + "/assets/images/spinner.svg"} className="spinner" alt="spinner" />
      </div>
    )
  }
}

