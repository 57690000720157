// eslint-disable-next-line no-unused-vars
import React, {Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import _ from 'lodash';
import { hexToRGBa } from '../../../utils/utils'

export default class BubbleChart extends Component {
    constructor(props) {
      super(props);
      this.state = {
        lastData: []
      }
  
      this.renderChart = this.renderChart.bind(this);
      this.renderBubbles = this.renderBubbles.bind(this);
      // this.renderLegend = this.renderLegend.bind(this);
    }
  
    componentDidMount() {
      this.div = ReactDOM.findDOMNode(this);
      this.renderChart();
    }
  
    componentDidUpdate(prevProps) {
      if (prevProps.data !== this.props.data) {
        this.renderChart();
      }
    }
  
    render() {
      const {
        width,
        height
      } = this.props;
      return ( <div style = {{width: width, height: height}} ></div> )
    }
  
    renderChart() {
      const {
        graph,
        data,
        height,
        width,
        showLegend,
        legendPercentage,
      } = this.props;
      // Reset the svg element to a empty state.
      this.div.innerHTML = '';
  
      const bubblesWidth = showLegend ? width * (1 - (legendPercentage / 100)) : width;
      const legendWidth = width - bubblesWidth;
      const color = d3.scaleOrdinal(d3.schemeCategory10);
  
      const pack = d3.pack()
        .size([width * graph.zoom, width * graph.zoom])
        .padding(0);
  
      // Process the data to have a hierarchy structure;
      const root = d3.hierarchy({
          children: data
        })
        .sum(function(d) {
          return d.value;
        })
        .sort(function(a, b) {
          return b.value - a.value;
        })
        .each((d) => {
          if (d.data.label) {
            d.label = d.data.label;
            d.id = d.data.label.toLowerCase().replace(/ |\//g, "-");
          }
        });
  
      // Pass the data to the pack layout to calculate the distribution.
      const nodes = pack(root).leaves();
  
      // Call to the function that draw the bubbles.
      this.renderBubbles(bubblesWidth, nodes, color);
      // Call to the function that draw the legend.
      if (showLegend) {
        this.renderLegend(legendWidth, height, bubblesWidth, nodes, color);
      }
    }
  
    renderBubbles(width, nodes) {
      const {
        graph,
        labelFont,
        displayCompany,
        addToAnalysis,
        sizeFisActive
      } = this.props;

      const bubbleChart = d3.select(this.div).append("div")
        .attr("class", "bubble-chart")
        .style("transform", function() {
          return "translate(" + (width * graph.offsetX) + "px," + (width * graph.offsetY) + "px)";
        });
  
      var _this = this
      const node = bubbleChart.selectAll(".node")
        .data(nodes)
        .enter().append("div")
        .attr("class", "node")
        .attr("draggable","true")
        .style("transform-origin", "center")
        .style("opacity",  function(d){
          var foundNode = _.find(_this.state.lastData, function(n) {
            return n.data._id === d.data._id
          })
          if(foundNode){
            return 1
          }else{
            return 0
          }
        })
        .style("position", "absolute")
        .on("click", function(d) {
          displayCompany(d.data);
        })
        .on("mouseover", function() { //Mouse event
          d3.select(this).select(".label-text")
            .transition()
            .duration(200)
            .style("background-color", "#FFFFFF")
            .style("padding", "3px")
            .style("border-radius", "3px")
            .style("box-shadow", "0.2rem 0.5rem 1rem rgba(48, 48, 48, 0.5)")
            .style("display", "block")
            .style("cursor", "pointer")
            .text(function(d) {
                return d.data.name
            });
        })
        .on("mouseout", function() { //Mouse event
          d3.select(this).select(".label-text")
            .transition()
            .duration(1000)
            .style("background-color", "transparent")
            .style("padding", "3px")
            .style("border-radius", "3px")
            .style("box-shadow", "none")
            .style("display", function(d) {
              if (d.r < 25 && !sizeFisActive ) {
                return "none" 
              } else {
                return "block"
              }
            })
            .style("cursor", "pointer")
            .text(function(d) {
                return d.data.code
            });
        });

        d3.selectAll(".node").call(d3.drag().on("start", started));

        function started() {
            var circle = d3.select(this).classed("dragging", true);
          
            d3.event.on("drag", dragged).on("end", ended);
          
            function dragged() {
                circle.raise().style("transform", function(d) {
                  if (d3.event.y > window.innerHeight - 300) {
                      addToAnalysis(d.data)
                      circle.style("transform", function(d) {
                          return "translate(" + d.x + "px," + d.y + "px)";
                      });
                  }
                  return "translate(" + d3.event.x + "px," + d3.event.y + "px)";

                })
                
            }
          
            function ended() {
                circle.style("transform", function(d) {
                    if (d3.event.y > window.innerHeight - 300) {
                        addToAnalysis(d.data)
                    }
                    return "translate(" + d.x + "px," + d.y + "px)";
                });
            }
          }

      node.each(function(d) {
        d.x0 = width / 2
        d.y0 = width / 2
        d.r0 = 0
        var foundNode = _.find(_this.state.lastData, function(n) {
          return n.data._id === d.data._id
        })
        if (foundNode) {
          d.x0 = foundNode.x
          d.y0 = foundNode.y
          d.r0 = foundNode.r
        }
      })
    //   console.log(this.state.lastData)
      node
        .style("transform", function(d) {
          return "translate(" + d.x0 + "px," + d.y0 + "px)";
        })
  
      node.transition()
        .duration(2000)
        .style("opacity", "1")
        .style("transform", function(d) {
          return "translate(" + d.x + "px," + d.y + "px)";
        })
  
      node.append("div")
        .attr("class", "bubble")
        .style("cursor", "pointer")
        .style("position", "absolute")
        .attr("draggable","true")
        .style("top", "50%")
        .style("left", "50%")
        .style("transform", "translate(-50%,-50%)")
        .style("width", function(d) {
            return d.r0 - (d.r0 * .008) + "px";
          })
          .style("height", function(d) {
            return d.r0 - (d.r0 * .008) + "px";
          })
        .attr("id", function(d) {
          return d.id;
        })
        .style("border-radius", "50%")
        .style("background-color", function(d) {
          return hexToRGBa(d.data.color, 0.4);
        })
        .style("border", "1px solid")
        .style("border-color", function(d) {
            return d.data.color;
        })

        node.selectAll(".bubble")
            .transition()
            .duration(2000)
            .style("opacity", "1")
            .style("width", function(d) {
                return d.r - (d.r * .008) + "px";
              })
              .style("height", function(d) {
                return d.r - (d.r * .008) + "px";
              });


        node.append("p")
        .attr("class", "label-text")
        .style("z-index", "150")
        .style("position", "absolute")
        .style("margin", "0px")
        .style("padding", "3px")
        .style("top", "50%")
        .style("left", "50%")
        .style("transform", "translate(-50%,-50%)")
        .style("display", function(d) {
          // console.log(this.props.sizeFisActive)
          if (d.r < 25 && !sizeFisActive ) {
            return "none" 
          } else {
            return "block"
          }
        })
        .style("text-align", "center")
        .style("font-size", `${labelFont.size}px`)
        .style("font-weight", () => {
          return labelFont.weight ? labelFont.weight : 600;
        })
        .style("font-family", labelFont.family)
        .style("color", () => {
          return labelFont.color ? labelFont.color : '#000';
        })
        .style("color", () => {
          return labelFont.lineColor ? labelFont.lineColor : '#000';
        })
        .text(function(d) {
          return d.data.code
        });
  
      this.setState({
        lastData: nodes
      })
    }
  
  }

  
  
  BubbleChart.propTypes = {
    graph: PropTypes.shape({
      zoom: PropTypes.number,
      offsetX: PropTypes.number,
      offsetY: PropTypes.number,
    }),
    width: PropTypes.number,
    height: PropTypes.number,
    showLegend: PropTypes.bool,
    legendPercentage: PropTypes.number,
    legendFont: PropTypes.shape({
      family: PropTypes.string,
      size: PropTypes.number,
      color: PropTypes.string,
      weight: PropTypes.string,
    }),
    valueFont: PropTypes.shape({
      family: PropTypes.string,
      size: PropTypes.number,
      color: PropTypes.string,
      weight: PropTypes.string,
    }),
    labelFont: PropTypes.shape({
      family: PropTypes.string,
      size: PropTypes.number,
      color: PropTypes.string,
      weight: PropTypes.string,
    }),
  }
  BubbleChart.defaultProps = {
    graph: {
      zoom: 1.1,
      offsetX: -0.05,
      offsetY: -0.01,
    },
    width: 1000,
    height: 800,
    showLegend: true,
    legendPercentage: 20,
    legendFont: {
      family: 'Arial',
      size: 12,
      color: '#000',
      weight: 'bold',
    },
    valueFont: {
      family: 'Arial',
      size: 16,
      color: '#fff',
      weight: 'bold',
    },
    labelFont: {
      family: 'Arial',
      size: 11,
      color: '#fff',
      weight: 'normal',
    },
}