import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loginUser } from '../../actions/authentification';
import classnames from 'classnames';
import '../../styles/css/Register.css';

import DisplayErrors from './DisplayErrors'

class Login extends Component {
    constructor( ) {
        super();
        this.state = {
            email: '',
            password: '',
            errors: {},
            displayPassword: true,
        }
    }

    componentDidMount() {
        this.props.setActiveView('login')
        if(this.props.auth.isAuthenticated) {
            this.props.history.push('/explorer')
        }
    }

    handleInputChange = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const user =  {
            email: this.state.email,
            password: this.state.password,
        }
        this.props.loginUser(user);
        // console.log(user);
    }

    componentDidUpdate(prevProps) {
        if(this.props.auth.isAuthenticated) {
            this.props.history.push('/explorer')
        }
        if(this.props.errors && this.props.errors !== prevProps.errors ) {
            this.setState({
                errors: this.props.errors
            })
        }
    }

    displayPassword = () => {
        this.setState({
            displayPassword: !this.state.displayPassword
        })
    }

    render() {
        const { errors, displayPassword } = this.state;
        let passwordType = "password"
        if(displayPassword) {
            passwordType = "password"
        } else {
            passwordType = "text"
        }
        return (
            <div className="container-register">
                <div className="img-and-form-container" >
                    <form className="form-container" onSubmit={this.handleSubmit}>
                        <img src={process.env.PUBLIC_URL + "assets/images/michelin-logo.png"} alt="logo" className="logo-login"/>
                        <div className="register-form">
                            <div className="form">
                                <h2 className="text-blue">Connectez-vous à votre espace <br/>Michelin concurrence</h2>
                                <label className="input-label text-blue">Votre email</label>
                                <div className="input-container">
                                    <img src={process.env.PUBLIC_URL + "assets/images/mail.png"} alt="email" className="icon-input" />
                                    <input
                                    type='email'
                                    placeholder='Email...'
                                    className={classnames('input-text', {
                                        'is-invalid': errors.email
                                    })} 
                                    autoComplete="email"
                                    name='email'
                                    onChange={this.handleInputChange }
                                    value={this.state.email}
                                    />
                                </div>
                                <label className="input-label text-blue">Votre mot de passe</label>
                                <div className="input-container password-container">
                                    <div>
                                    <img src={process.env.PUBLIC_URL + "assets/images/password.png"} alt="email" className="icon-input" />
                                        <input
                                        type={passwordType}
                                        autoComplete="new-password"
                                        placeholder='mot de passe...'
                                        className={classnames('input-text', {
                                            'is-invalid': errors.password
                                        })} 
                                        name='password'
                                        onChange={this.handleInputChange }
                                        value={this.state.password}
                                        />
                                    </div>
                                    <img src={process.env.PUBLIC_URL + "assets/images/afficher.png"} alt="email" className="icon-password" onClick={() => this.displayPassword()}/>
                                </div>
                                <div className='form-group'>
                                    <button className="m-none btn-blue-rounded" type="submit">Se connecter</button>
                                    {/* <button className="btn-blue-rounded" onClick={() => this.props.history.push('/register')}>Créer votre compte</button> */}
                                </div>
                                <div className="errors-container">
                                    <DisplayErrors errors={this.state.errors} />
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className="img-container">
                        <img src={process.env.PUBLIC_URL + "assets/images/Bibendum.png"} className="bibendum" alt="bibendum"/>
                    </div>
                </div>
            </div>
        )
    }
}

Login.propTypes = {
    errors: PropTypes.object.isRequired,
    loginUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors
})

export default connect(mapStateToProps, { loginUser } )(Login);
