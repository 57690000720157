import axios from 'axios';
import { SET_UPLOADED_LIST, POST_FILE } from './types';
import { API_URL } from '../utils/utils';


export function getData(action, errorType, isAuthReq, url, dispatch) {

  const requestUrl = API_URL + url;

  let config = {};

  if (isAuthReq) {
    config = { headers: {
      Authorization : localStorage.getItem('jwtToken')
    }}
  }

  axios.get(requestUrl, config)
  .then((response) => {
    dispatch({
      type: action,
      payload: response.data,
    });
  })
  .catch((error) => {
    console.log(error);
    errorHandler(dispatch, error.response, errorType);
  });
}

// Post Files Request
export function postFiles(action, errorType, isAuthReq, url, dispatch, data , info) {

  const requestUrl = API_URL + url;

  let headers = {};

  if (isAuthReq) {
    headers = { headers: {
      Authorization : localStorage.getItem('jwtToken'),
      'Content-Type' : 'multipart/form-data'
      }
    };
  };

  const config = {
    onUploadProgress: function(progressEvent) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      var progress = {
        progress: percentCompleted,
        fileState: "progress",
        _id: info._id,
        name: info.name
      }

      dispatch({
        type: SET_UPLOADED_LIST,
        payload: progress,
      });
    }
  }

  axios.post(requestUrl, data, config, headers , info)
  .then((response) => {
      const result200 = {
        progress: 100,
        fileState: "fileUpdated",
        _id: info._id,
        name: info.name,
        response: response.data
      }
      dispatch({
        type: POST_FILE,
        payload: result200,
      });
      dispatch({
        type: action,
        payload: result200,
      });
    }
  )
  .catch((error) => {
    console.log(error);
    var message = "";
    
    if (!error.status) {
      message = "network error"
    }

    if (error.response && error.response.data.error) {
      message = error.response.data.error
    }

    var result400 = {
      progress: 100,
      fileState: "error",
      _id: info._id,
      name: info.name,
      message: message
    }

    dispatch({
      type: action,
      payload: result400,
    });
    // errorHandler(dispatch, error.response, errorType);
  });
}

// Post Request
export function postData(action, errorType, isAuthReq, url, dispatch, data) {
  const requestUrl = API_URL + url;
  let headers = {};

  if (isAuthReq) {
    headers = { headers: { Authorization: localStorage.getItem('jwtToken') } };
  }

  axios.post(requestUrl, data, headers)
  .then((response) => {
    dispatch({
      type: action,
      payload: response.data,
    });
  })
  .catch((error) => {
    errorHandler(dispatch, error.response, errorType);
  });
}

// Put Request
export function putData(action, errorType, isAuthReq, url, dispatch, data) {
  const requestUrl = API_URL + url;
  let headers = {};

  if (isAuthReq) {
    headers = { headers: { Authorization: localStorage.getItem('jwtToken') } };
  }

  axios.put(requestUrl, data, headers)
  .then((response) => {
    dispatch({
      type: action,
      payload: response.data,
    });
  })
  .catch((error) => {
    errorHandler(dispatch, error.response, errorType);
  });
}

// Delete Request
export function deleteData(action, errorType, isAuthReq, url, dispatch , id) {
  const requestUrl = API_URL + url;
  let headers = {};

  if (isAuthReq) {
    headers = { headers: { Authorization: localStorage.getItem('jwtToken') } };
  }

  axios.delete(requestUrl, headers)
  .then((response) => {
    dispatch({
      type: action,
      payload: id,
    });
  })
  .catch((error) => {
    errorHandler(dispatch, error.response, errorType);
  });
}

export function errorHandler(dispatch, error, type) {
  console.log('Error type: ', type);
  console.log(error);

  let errorMessage = error.status === 401 ? "You are not authorized" : error.data.error;

   // NOT AUTHENTICATED ERROR

  dispatch({
    type,
    payload: errorMessage,
  });
}
