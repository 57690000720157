import React from 'react';
import Select from 'react-select';

const customSearch = {
    option: (provided, state) => ({
        ...provided,
        color:'white',
        backgroundColor: state.isSelected || state.isFocused? '#194084' : "transparent",
        paddingLeft: 12,
    }),
    control: () => ({
      width: '100%',
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        display:'none',
    }),
    container: (provided, state) => ({
      ...provided,
      width: '100%',
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        color:'#004A98',
        left: 12,
        paddingLeft: 0,
        width: '100%',
    }),
    input:(provided, state) => ({
        ...provided,
        width: 650,
        color:'#004A98',
        fontSize: 14,
        marginLeft:0,
    }),
    menu:(provided, state) => ({
        ...provided,
        marginTop: 20,
        padding: 0,
        fontSize: 14,
        top: 34,
        color: "white",
        backgroundColor:'#004A98',
        borderRadius: 8,
    }),
    menuList: (provided, state) => ({
        ...provided,
        color:'white',
        margin: 0,

    }),
    placeholder: (provided, state) => ({
        ...provided,
        color: '#A1BCDE',
        fontSize: 14,
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
      const color = '#004A98';
  
      return { ...provided, opacity, transition , color };
    }
}

function SearchAdmin(props) {
  return (
    <div className="input-search">
      <Select
        styles={customSearch}
        inputValue={props.inputValue}
        onInputChange={props.onInputChange}
        placeholder="Your research"
        options={props.options}
        menuIsOpen={false}
        escapeClearsValue={false}
        isSearchable />
      <img src={process.env.PUBLIC_URL + "assets/images/search-blue.png"} alt="search"/>
    </div>
  )
}

export default SearchAdmin;
