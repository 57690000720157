import React, { Component } from 'react';
import { setLabelColor } from '../../utils/utils';

export default class DataVizPercentage extends Component {
  render() {
    const isActive = this.props.idIsOnActiveIdList(this.props.data.id)
    const { activeIdList, data } = this.props
    return (
        <div className={activeIdList.length === 0 || isActive ? "percentage-container" : "percentage-container dataViz-unfocus" }>
            <div className="color-left" style={{backgroundColor: data.color}}>
            </div>
            <div className="balance-bar-content">
                <h4 className="percentage-name">{data.name}</h4>
                {this.props.value !== null ?
                    <span className="percentage" style={{color: setLabelColor(data.color)}}>{this.props.value } {this.props.unit}</span>
                :
                    <span className="percentage-no-data">Data not Found</span>
                }
            </div>
        </div>
    )
  }
}
