import React, { Component } from 'react';
import { API_URL } from '../../utils/utils';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

class Connexion extends Component {
  componentWillMount() {
    this.props.setActiveView('connexion')
    if(this.props.auth.isAuthenticated) {
      this.props.history.push('/explorer')
    }
  } 

  render() {
    return (
      <div className="connexion-container">
        <Link to="/login">
          <button className="btn-blue-rounded" >Connexion Standard</button>
        </Link>
        <a href={API_URL + "/auth/login-saml"}>
          <button className="btn-blue-rounded">Connexion SSO</button> 
        </a>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  auth: state.auth,
})

const mapDispatchToProps = {
    // ssoConnexion : ssoConnexion,
}

export default connect(mapStateToProps, mapDispatchToProps)(Connexion)