import React, { Component } from 'react';
import { ResponsiveLine } from '@nivo/line';
import DataVizNoData from '../DataViz/DataVizNoData';

export default class DataVizLinesChart extends Component {
  render() {
    const { titles , data} = this.props
    //If total value > 0 display component else display no datas
    let totalValuesToDisplay = 0
    data.map(item => {
        return item.data.map(item => {
            return totalValuesToDisplay = totalValuesToDisplay + item.y
        })
    })
    let minValueY = 0
    data.map(item => {
        return item.data.map(item => {
             if (item.y < minValueY ) {
                minValueY = item.y
             }
             return minValueY
        })
    })

    data.sort(function(a, b){return a.data.length - b.data.length}).reverse()

    return (
        <>
            <div className="analysis-title">
              <span className="font-weight-bold" >{titles.title} </span>
              <span className="line-chart-title-details">{titles.details}</span>
              <span className="line-chart-title-details type-flag">{titles.flag}</span>
            </div>
            <div className="line-chart-card">
                <div className="chart-legend">{this.props.unit}</div>
                {totalValuesToDisplay !== 0 ?
                <ResponsiveLine
                    data={data}
                    margin={{
                        "top": 35,
                        "right": 35,
                        "bottom": 50,
                        "left": 50
                    }}
                    // xFormat="time:%Y-%m-%d"
                    xScale={this.props.xScale}
                    yScale={{
                        "type": "linear",
                        "stacked": false,
                        "min": this.props.minValueY ? this.props.minValueY : minValueY,
                        "max": "auto"
                    }}
                    curve="cardinal"
                    axisBottom={{
                        "format": this.props.format,
                        "orient": "bottom",
                        "tickSize": 0,
                        "tickPadding": 15,
                        "tickRotation": 0,
                        "legend": "",
                        "legendOffset": 36,
                        "legendPosition": "middle",
                    }}
                    fontFamily="Montserrat-Regular"
                    axisLeft={{
                        "orient": "top",
                        "tickSize": 0,
                        "tickPadding": 5,
                        "tickRotation": 0,
                        "legendOffset": 0,
                        "legendPosition": "end"
                    }}
                    markers={[
                        {
                            axis: 'y',
                            value: 0,
                            lineStyle: { stroke: 'rgba(0, 0, 0, .35)', strokeWidth: 1 },
                            legendOrientation: 'vertical',
                        }
                    ]}
                    theme={{
                        markers: {
                            textColor: "#FFFFF",
                            fontSize: 10
                        }
                    }}
                    colorBy={d => d.color}
                    enableGridX={false}
                    enableDots={this.props.enableDots}
                    dotSize={8}
                    dotColor="inherit:darker(0.3)"
                    dotBorderWidth={2}
                    dotBorderColor="#ffffff"
                    enableDotLabel={false}
                    dotLabel="y"
                    dotLabelYOffset={-12}
                    areaOpacity={0}
                    animate={this.props.animate}
                    motionStiffness={90}
                    motionDamping={14}
                    legends={[]}
                    tooltip={this.props.tooltip}
                />
                :
                    <DataVizNoData />
                }
            </div>
        </>
    )
  }
}
