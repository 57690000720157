import React from 'react'

export default function Loader(props) {
  return (
    <svg version="1.1" id="L4" xmlns="http://www.w3.org/2000/svg" width="55px" x="0px" y="0px"
      >
      <circle fill={props.color} stroke="none" cx="6" cy="20" r="4">
        <animate
          attributeName="opacity"
          dur="1s"
          values="0;1;0"
          repeatCount="indefinite"
          begin="0.1"/>
        <animate
          attributeName="r"
          dur="1s"
          values="4;6;4"
          repeatCount="indefinite"
          begin="0.1"/>        
      </circle>
      <circle fill={props.color} stroke="none" cx="26" cy="20" r="4">
        <animate
          attributeName="opacity"
          dur="1s"
          values="0;1;0"
          repeatCount="indefinite" 
          begin="0.2"/>
        <animate
          attributeName="r"
          dur="1s"
          values="4;6;4"
          repeatCount="indefinite"
          begin="0.2"/>         
      </circle>
      <circle fill={props.color} stroke="none" cx="46" cy="20" r="4">
        <animate
          attributeName="opacity"
          dur="1s"
          values="0;1;0"
          repeatCount="indefinite" 
          begin="0.3"/>
        <animate
          attributeName="r"
          dur="1s"
          values="4;6;4"
          repeatCount="indefinite"
          begin="0.3"/>        
      </circle>
    </svg>
  )
}
