import {
  SET_ADMIN_IS_ACTIVE ,
  FETCH_COMPANIES_LIST ,
  FETCH_ZONES_LIST ,
  FETCH_PRODUCTS_LIST,
  RESET_ADMIN_LIST ,
  SET_UPLOADED_LIST ,
  EXPORT_FINANCIAL,
  RESET_EXPORT,
  ADMIN_ERROR,
  POST_FILE } from '../actions/types';

import * as _ from "lodash";

const initialState = {
  adminIsActive: false,
  adminLists : {
    companies: null,
    zones: null,
    products: null
  },
  export: {},
  fileStatus : {},
  uploadedList : [],
  error: null
}

var list;
var i;

export default function(state = initialState, action ) {
    switch (action.type) {

      case ADMIN_ERROR:
        return  {
          ...state,
          error: action.payload
        };

        case SET_ADMIN_IS_ACTIVE:
            return  {
              ...state,
              adminIsActive: action.payload
          };

        case POST_FILE:
            list = _.cloneDeep(state.uploadedList.filter(item => item.fileState !== "fileUpdated"));
            i = list.findIndex(item => item._id === action.payload._id);
            if (list.length !== 0 && i !== -1) {
              list.splice(i, 1, action.payload)
            } else {
              list.push(action.payload)
            }
            return  {
              ...state,
              uploadedList: list
            }
            
        case SET_UPLOADED_LIST:
            list = _.cloneDeep(state.uploadedList);
            i = list.findIndex(item => item._id === action.payload._id);
            if (list.length !== 0 && i !== -1) {
              list.splice(i, 1, action.payload)
            } else {
              list.push(action.payload)
            }
            return  {
              ...state,
              uploadedList: list
            }

        case FETCH_COMPANIES_LIST:
            return  {
              ...state,
              adminLists : {
                ...state.adminLists,
                companies: action.payload.companies
              }
          }

        case FETCH_PRODUCTS_LIST:
          return  {
            ...state,
            adminLists : {
              ...state.adminLists,
              products: action.payload.products
            }
        }

        case EXPORT_FINANCIAL:
            return  {
              ...state,
              export : {
                ...state.export,
                financial: action.payload
              }
          };

        case RESET_EXPORT:
          return  {
            ...state,
            export : {}
        };
        
        case FETCH_ZONES_LIST:
            return  {
              ...state,
              adminLists : {
                ...state.adminLists,
                zones: action.payload.zones
              }
          }
          
        case RESET_ADMIN_LIST:
            return  {
              ...state,
              adminLists : []
          }
        default: 
            return state;
    }
}