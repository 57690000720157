import React, { Component } from 'react';
import { connect } from 'react-redux';

import BubbleChart from './react-bubble-chart-d3';
import { addToAnalysis } from '../../../actions/analysisActions'

class DataVizBubbles extends Component {
    constructor(props) {
        super(props) 
        this.state = {
            data: this.props.data,
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.data !== this.props.data ) {
            this.setState({
                data: this.props.data,
                readyToDisplay: true
            })
        }
    }

    render() {
        let setSize = 580
        if (window.innerHeight < 772) {
            setSize = 470
        }
        if (window.innerWidth < 900) {
            setSize = 450
        }
        return (
            <div>
                <BubbleChart
                    sizeFisActive={this.props.sizeFisActive}
                    graph= {{
                        zoom: 1,
                        offsetX: -0.00,
                        offsetY: -0.01
                     }}
                    width={setSize}
                    height={setSize}
                    showLegend={false} // optional value, pass false to disable the legend.
                    legendPercentage={20} // number that represent the % of with that legend going to use.
                    legendFont={{
                        family: 'Arial',
                        size: 12,
                        color: '#000',
                        weight: 'bold',
                    }}
                    valueFont={{
                        family: 'Arial',
                        size: 10,
                        color: '#fff',
                        weight: 'regular',
                    }}
                    labelFont={{
                        family: 'Montserrat-Regular',
                        size: 13,
                        color: '#fff',
                        weight: 'regular',
                    }}
                    addToAnalysis={this.props.addToAnalysis}
                    displayCompany={this.props.displayCompany}
                    data={this.props.data}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    addToAnalysis: addToAnalysis,
}

export default connect(mapStateToProps, mapDispatchToProps)(DataVizBubbles);

