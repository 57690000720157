import { ADD_TO_ANALYSIS , REMOVE_FROM_ANALYSIS } from '../actions/types';

let initialState = { companies: [], zones: [], products:[] , analysisIsActive: false } 

export default function(state = initialState, action ) {
    switch (action.type) {
        case ADD_TO_ANALYSIS:
            // eslint-disable-next-line no-case-declarations
            let found = state[action.analysisType].find((d) =>{
                return d._id === action.payload._id
            })
            if (found === undefined && action.analysisType === "companies") {
                const add = {...state,
                    [action.analysisType]: [
                        ...state[action.analysisType], action.payload
                    ]
                }
                return add
            } else if (found === undefined && action.analysisType !== "companies") {
                const add = {...state,
                    [action.analysisType]: [action.payload]
                }
                return add
            } else {
                return state;
            }

        case REMOVE_FROM_ANALYSIS:
            // eslint-disable-next-line no-case-declarations
            const filter = state[action.analysisType].filter(item => 
                item._id !== action.payload._id
            )
            // eslint-disable-next-line no-case-declarations
            const remove = {...state, [action.analysisType]: filter }
            return remove;
            
        default: 
            return state;
    }
}