import React, { Component } from 'react';
import { connect } from 'react-redux';

import '../../styles/css/admin.css';

import { fetchCompanies, fetchZones , fetchProducts , setAdminIsActive , resetAdminError , resetExport } from '../../actions/adminActions';

import Spinner from '../Explorer/Spinner';
import NavAdmin from './NavAdmin';
import SearchAdmin from '../Partials/SearchAdmin';
import FileAdminItem from './FileAdminItem';
import ModaleFinancial from './ModaleFinancial';
import ModaleExchangeRate from './ModaleExchangeRate';
import ModaleFormula from './ModaleFormula';
import DataVizNoData from '../DataViz/DataVizNoData';

import download from '../../assets/images/download.png';
import ModaleStockValues from './ModaleStockValues';

const ExportJsonExcel = require('js-export-excel');

export class Admin extends Component {
  constructor(props) {
    super(props)
      this.state ={

        activeList: "Manufacturer",
        list: [],
        savedList: [],
        companiesIsActive: true,
        productsIsActive: false,
        zonesIsActive: false,

        file: null,
        fileData: {},

        selectedOption: null,
        selectInputValue: "",
        options: [],

        modaleType: null

      }
  };

  componentDidMount() {
    this.props.resetAdminError();
    this.props.fetchCompanies();
    this.props.fetchZones();
    this.props.fetchProducts();
    this.props.setAdminIsActive(true)
    this.props.setActiveView("admin");
  };

  componentDidUpdate(prevProps) {
    if(this.props.lists.companies && JSON.stringify(prevProps.lists) !== JSON.stringify(this.props.lists)) {
      this.resetList();
    }
    // if (this.props.fileStatus.message && this.props.fileStatus.date !== prevProps.fileStatus.date) {
    //   console.log("fetch after update")
    //   this.props.fetchAdminLists();
    // }
  }

  resetList = () => {
    if (this.state.activeList === "Zones") {
      this.setZonesIsActive();
    } else if (this.state.activeList === 'Products') {
      this.setProductsIsActive();
    } else {
      this.setCompaniesIsActive();
    }
  };

  setActiveList = (activeListName) => this.setState({ activeList: activeListName })

  setCompaniesIsActive = () => {
    this.createOptions(this.props.lists.companies);
    this.setActiveList("Manufacturer");
  };

  setProductsIsActive = () => {
    this.createOptions(this.props.lists.products);
    this.setActiveList('Products');
  };

  setZonesIsActive = () => {
    this.createOptions(this.props.lists.zones);
    this.setActiveList('Zones');
  };

  onChangeFile = (e , data) => {
    this.props.handleModaleState(true);
    this.setState({
      file: e.target.files[0],
      fileData: data
    });
  };

  openModale = (data , modaleType ) => {
    this.props.handleModaleState(true);
    this.setState({
      fileData: data,
      modaleType: modaleType
    });
  };

  removeFile = () => {
    this.props.handleModaleState(false);
    this.setState({
      file: null,
      fileData: {}
    });
  };

  onInputChange= (value , { action }) => {
    if (action === "input-change") {
      this.setState( { selectInputValue : value} )
      var filtredOptions = [];
      if (this.state.savedList.length > 0) {
        this.state.savedList.filter(item => {
          if (item.name.toLowerCase().includes(value.toLowerCase())) {
            return filtredOptions.push(item) 
          }
          return item;
        })
        if (filtredOptions.length > 0 && value !== "" ) {
          this.setState({ list: filtredOptions})
        } else if (value === "" ) {
          this.setState({ list: filtredOptions})
        } else if ( filtredOptions.length === 0 ) {
          this.setState({ list: []})
        }
      }
    }
  };


  createOptions = (list) => {
    var optionsList = [];
    list.map(item => {
      return optionsList.push({
          value: item.name,
          label: item.name
        });
    });
    this.setState({
      list: list.sort(function(a, b){ 
        if(a.name < b.name) { return -1; }
        if(a.name > b.name) { return 1; }
        return 0;
       }),
      savedList: list,
      options: optionsList,
    })
  }

  renderExport = () => {
    if(this.props.export && this.props.export.financial) {
      var data = {}
      data.fileName = this.props.export.financial.fileName;
      data.datas = [...this.props.export.financial.dataToExport];
      let toExcel = new ExportJsonExcel(data);
      this.props.resetExport();
      return toExcel.saveExcel();
    }
  }

  renderList = () => {
    if( this.props.lists.companies && this.state.list.length > 0) {
      return this.state.list.map((item, i ) => {
        return (<FileAdminItem
          updateType={this.state.activeList}
          openModale={this.openModale}
          fileData={this.state.fileData}
          file={this.state.file}
          activeList={this.state.activeList}
          fileState={this.props.uploadedList.find(fileData => fileData._id === item._id )}
          index={i}
          key={i}
          data={item} />)
      })
    } else if (this.props.lists.companies && this.props.lists.companies.length > 0 && this.state.list.length === 0 ) {
      return (
        <div className="loader-container">
          <DataVizNoData />
        </div>
      );
    } else if (this.props.error) {
      return (
        <div className="loader-container">
          {this.props.error}
        </div>
      )
    } else {
      return (
        <div className="loader-container">
          <Spinner/>
        </div>
      );
    }
  }

  render() {
    return (
      <>
        <div className={`app-container ${this.props.modaleIsActive ? "blur" : "" }`}>
          <div className="admin-container">
            <nav>
            {this.props.lists && this.props.lists.companies ?
              <>
                <NavAdmin
                  activeList={this.state.activeList}
                  setActiveList={this.setActiveList}
                  list={this.props.lists}
                  setCompaniesIsActive={this.setCompaniesIsActive}
                  setProductsIsActive={this.setProductsIsActive}
                  setZonesIsActive={this.setZonesIsActive}
                /> 
              </>
              :
              null
            }
            </nav>
            <SearchAdmin
              inputValue={this.state.selectInputValue}
              onInputChange={this.onInputChange}
              selectedOption={this.state.selectedOption}
              options={this.state.options}
              />
            {this.state.activeList === "Manufacturer" && this.props.user.role !== "Owner" ?
              <div className="options-btn-container">
                {this.renderExport()}
                <div>
                  <button
                    style={{ marginRight : 0 , marginTop : 0}}
                    className="btn-blue-rounded"
                    onClick={() => this.openModale(null, "formula")}
                    >
                    <img style={{ width: 12 , marginRight: 5 }} src={download} alt="download"/>
                    Formula
                  </button>
                  <button
                    style={{ marginRight : 0 , marginTop : 0}}
                    className="btn-blue-rounded"
                    onClick={() => this.openModale(null , "stock-values")}>
                    <img style={{ width: 12 , marginRight: 5 }} src={download} alt="download"/>
                    Stock value
                  </button>
                  <button
                    style={{ marginRight : 0 , marginTop : 0}}
                    className="btn-blue-rounded"
                    onClick={() => this.openModale(null, "exchange")}
                    >
                    <img style={{ width: 12 , marginRight: 5 }} src={download} alt="download"/>
                    Exchange rates
                  </button>
                </div>
              </div>
              :
              null
            }
            <main>
              <div className="list-container">
                {this.renderList()}
              </div>
            </main>
          </div>
        </div>
          <ModaleFinancial
            modaleType={this.state.modaleType}
            removeFile={this.removeFile}
            onChangeFile={this.onChangeFile}
            modaleIsActive={this.props.modaleIsActive}
            file={this.state.file}
            fileData={this.state.fileData}/>
          <ModaleStockValues
            modaleType={this.state.modaleType}
            removeFile={this.removeFile}
            onChangeFile={this.onChangeFile}
            modaleIsActive={this.props.modaleIsActive}
            file={this.state.file}
            fileData={this.state.fileData}/>
          <ModaleExchangeRate
            modaleType={this.state.modaleType}
            removeFile={this.removeFile}
            onChangeFile={this.onChangeFile}
            modaleIsActive={this.props.modaleIsActive}
            file={this.state.file}/>
          <ModaleFormula
            modaleType={this.state.modaleType}
            removeFile={this.removeFile}
            onChangeFile={this.onChangeFile}
            modaleIsActive={this.props.modaleIsActive}
            file={this.state.file}/>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return({
  lists: state.admin.adminLists,
  error: state.admin.error,
  user: state.auth.user,
  fileStatus: state.admin.fileStatus,
  uploadedList: state.admin.uploadedList,
  export: state.admin.export
});
}

const mapDispatchToProps = {
  fetchCompanies: fetchCompanies,
  fetchZones: fetchZones,
  fetchProducts : fetchProducts,
  setAdminIsActive: setAdminIsActive,
  resetAdminError: resetAdminError,
  resetExport: resetExport
}

export default connect(mapStateToProps, mapDispatchToProps)(Admin)
