import React, { Component } from 'react';
import { connect } from 'react-redux';

import '../../styles/css/users.css';

import { setAdminIsActive } from '../../actions/adminActions';
import { fetchUsersList } from '../../actions/usersActions';

import Spinner from '../Explorer/Spinner';
import SearchAdmin from '../Partials/SearchAdmin';
import UserItem from './UserItem';
import ModaleAddUser from './ModaleAddUser';
import DataVizNoData from '../DataViz/DataVizNoData';

export class Admin extends Component {
  constructor(props) {
    super(props)
      this.state ={
        usersList: [],
        savedUsersList: [],
        options: [],

        modaleUserIsActive: false
      }
  };

  componentDidMount() {
    this.props.fetchUsersList();
    this.props.setAdminIsActive(true)
    this.props.setActiveView("users");
    this.createOptions(this.props.usersList)
  };

  componentDidUpdate(prevProps) {
    if(JSON.stringify(prevProps.usersList) !== JSON.stringify(this.props.usersList)) {
      this.createOptions(this.props.usersList)
    }
  }

  createOptions = (list) => {
    var optionsList = [];
    list.forEach(item => {
      return optionsList.push({
          value: item.name,
          label: item.name
        });
    });
    this.setState({
      usersList: list,
      savedUsersList: list,
      options: optionsList,
    })
  }

  ModaleAddUserToggle = () => {
    this.setState({
      modaleUserIsActive: !this.state.modaleUserIsActive
    })
  }

  onInputChange= (value , { action }) => {
    if (action === "input-change") {
      this.setState( { selectInputValue : value} )
      var filtredOptions = [];
      if (this.state.savedUsersList.length > 0) {
        this.state.savedUsersList.filter(item => {
          if (item.profile.firstName.toLowerCase().includes(value.toLowerCase()) || item.profile.lastName.toLowerCase().includes(value.toLowerCase()) || item.email.toLowerCase().includes(value.toLowerCase()) ) {
            return filtredOptions.push(item) 
          }
          return item;
        })
        if (filtredOptions.length > 0 && value !== "" ) {
          this.setState({ usersList: filtredOptions})
        } else if (value === "" ) {
          this.setState({ usersList: filtredOptions})
        } else if ( filtredOptions.length === 0 ) {
          this.setState({ usersList: []})
        }
      }
    }
  };

  renderList = () => {
    if( this.props.usersList && this.state.usersList.length > 0) {
      return this.state.usersList.map((item, i ) => {
        return (<UserItem
                index={i}
                key={item._id}
                data={item} />)
      })
    } else {
      return (
        <div className="loader-container">
          <DataVizNoData />
        </div>
      );
    }
  };

  render() {
    return (
      <>
        <div className={`app-container ${this.state.modaleUserIsActive ? "blur" : "" }`}>
          <div className="users-container">
            <header className="users-header">
              <SearchAdmin
                  inputValue={this.state.selectInputValue}
                  onInputChange={this.onInputChange}
                  selectedOption={this.state.selectedOption}
                  options={this.state.options}
                />
                <button onClick={() => this.ModaleAddUserToggle()} className="btn-blue-rounded">
                  <span>Add user</span>
                </button>
            </header>
            <main>
              <div className="list-container">
                {this.props.usersList.length === 0 ?
                  <div className="loader-container">
                    <Spinner/>
                  </div>
                :
                  this.renderList()
                } 
              </div>
            </main>
          </div>
        </div>
        <ModaleAddUser
          modaleUserIsActive={this.state.modaleUserIsActive}
          ModaleAddUserToggle={this.ModaleAddUserToggle}
        />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return({
    usersList: state.users.usersList,
  });
}

const mapDispatchToProps = {
  fetchUsersList: fetchUsersList,
  setAdminIsActive: setAdminIsActive
}

export default connect(mapStateToProps, mapDispatchToProps)(Admin)
