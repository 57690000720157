import React, { Component } from 'react';
import '../../styles/css/bubble-nav.css';

import DataVizBubbles from '../DataViz/Bubbles/DataVizBubbles';
import Spinner from '../../Components/Explorer/Spinner';
import CompanyDetails  from './CompanyDetails';
import DataVizNoData from '../DataViz/DataVizNoData';

class BubblesNav extends Component {
    constructor(props) {
        super(props) 
        this.state = {
            dataBubbles: null,
            companiesIsActive: true,
            productsIsActive: false,
            zonesIsActive: false,
            sizeFisActive: false,
            sizePisActive: true
        }
    }

    componentWillMount() {
        this.displayBubblesSizeF();
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.dataFromApi !== this.props.dataFromApi ) {
            if (this.props.dataFromApi && this.state.sizeFisActive ) {
                this.displayBubblesSizeF();
            } else {
                this.displayBubblesSizeP();
            }
        }
        if(prevProps.selectedYear !== this.props.selectedYear ) {
            if (this.props.selectedYear && this.state.sizeFisActive ) {
                this.displayBubblesSizeF();
            } else {
                this.displayBubblesSizeP();
            }
        }
    }

    displayBubblesSizeP = () => {
        // this.props.setFilterDisable("")
        let bubbles = [];
        this.props.dataFromApi.map((item) => {
            const yearArray = item.years.filter(year => year.label === this.props.selectedYear.toString() && year.sizeP > 0)
            if (yearArray.length > 0 ) {
                let main = {
                    _id : item._id,
                    name : item.name,
                    code : item.code !== undefined ? item.code : item.name,
                    label:  item.name,
                    color : item.color,
                    value: yearArray[0].sizeP
                }
                let analysisType = { analysisType : item.analysisType }

                return bubbles.push(Object.assign( main , analysisType ) )
            }
            return bubbles
        })
        if (bubbles.length > 0 ) {
            this.setState({
                dataBubbles: bubbles,
                sizeFisActive: false,
                sizePisActive: true
            });
        } else {
            this.setState({
                dataBubbles: [],
                sizeFisActive: false,
                sizePisActive: true
            });
        }
    }

    displayBubblesSizeF = () => {
        // this.props.setFilterDisable(" avoid-clicks")
        let bubbles = [];
        this.props.dataFromApi.map( item => {
            const yearArray = item.years.filter(year => year.label === this.props.selectedYear.toString() && year.sizeF > 0);
            if (yearArray.length > 0 ) {
                let main = {
                    _id : item._id,
                    name : item.name,
                    code : item.code !== undefined ? item.code : item.name,
                    label:  item.name,
                    color : item.color,
                    value: yearArray[0].sizeF
                };
                let analysisType = { analysisType : item.analysisType };

                return bubbles.push(Object.assign( main, analysisType ));
            }
            return bubbles;
        });
        if (bubbles.length > 0 ) {
            this.setState({
                dataBubbles: bubbles,
                sizeFisActive: true,
                sizePisActive: false
            });
        } else {
            this.setState({
                dataBubbles: [],
                sizeFisActive: true,
                sizePisActive: false
            });
        }
    }

    displayCompaniesBubbles = () => {
        this.props.getCompaniesFromApi();
        this.setState({
            companiesIsActive: true,
            productsIsActive: false,
            zonesIsActive: false
        });
    }

    displayProductsBubbles = () => {
        this.props.getProductsFromApi()
        this.props.removeCompanyDetails(null)
        this.setState({
            companiesIsActive: false,
            productsIsActive: true,
            zonesIsActive: false
        })
    }

    displayZonesBubbles = () => {
        this.props.getZonesFromApi()
        this.props.removeCompanyDetails(null)
        this.setState({
            companiesIsActive: false,
            productsIsActive: false,
            zonesIsActive: true
        })
    }

    render() {
    const { dataBubbles } = this.state;
    const { companyDetails, displayFilterSpinner } = this.props;

    const renderNavButton = [
        {
            function: () => this.displayCompaniesBubbles(),
            icon: "assets/images/factory.png",
            isActive: this.state.companiesIsActive,
            label: "Manufacturer"
        },
        {
            function: () => this.displayProductsBubbles(),
            icon: "assets/images/tires.png",
            isActive: this.state.productsIsActive,
            label: "Products"
        },
        {
            function: () => this.displayZonesBubbles(),
            icon: "assets/images/localisation.png",
            isActive: this.state.zonesIsActive,
            label: "Zones"
        }
    ]

    const renderSizeButton = [
        {
            function: () => this.displayBubblesSizeF(),
            icon: "assets/images/vente.png",
            isActive: this.state.sizeFisActive,
            label: "Sales"
        },
        {
            function: () => this.displayBubblesSizeP(),
            icon: "assets/images/production.png",
            isActive: this.state.sizePisActive,
            label: "Production"
        }
    ];

    let setSize = 580
    if (window.innerHeight < 772) {
        setSize = 470
    }
    if (window.innerWidth < 900) {
        setSize = 450
    }
    
    return (
        <div >
            {this.props.explorerIsActive ?
                <div className={this.props.displayModal ? "blur bubbles-nav bubble-nav-enter" : "bubbles-nav bubble-nav-enter" }>
                    {renderNavButton.map((item, index) => {
                        return (
                        <div key={index} className="bubble-nav-button" onClick={item.function}>
                            <img
                                src={process.env.PUBLIC_URL + item.icon}
                                className={item.isActive ? "icon-bubble-nav-active":"icon-bubble-nav" }
                                alt={item.label}
                                />
                            <span className={item.isActive ? "bubble-nav-label-active" : "bubble-nav-label"} >{item.label}</span>
                        </div>
                        )
                    })}
                    <div className="nav-separation"></div>
                    {renderSizeButton.map((item, index) => {
                        return (
                        <div key={index} className="bubble-nav-button" onClick={item.function}>
                            <img
                                src={process.env.PUBLIC_URL + item.icon}
                                className={item.isActive ? "icon-bubble-nav-active":"icon-bubble-nav" }
                                alt={item.label}
                                />
                            <span className={item.isActive ? "bubble-nav-label-active" : "bubble-nav-label"} >{item.label}</span>
                        </div>
                        )
                    })}
                </div>
            :
            null
            }
                {companyDetails ? 
                    <CompanyDetails
                        companyDetails={this.props.companyDetails}
                        displayCompanyDetails={this.props.displayCompanyDetails}
                        selectedYear={this.props.selectedYear}
                        explorerIsActive={this.props.explorerIsActive}
                        idIsOnActiveIdList={this.props.idIsOnActiveIdList}
                        removeCompany={this.props.removeCompanyDetails}/>
                :
                    null
                }
            <div className={this.props.displayModal ? "blur data-viz-container":"data-viz-container"}>
                {!displayFilterSpinner && dataBubbles.length > 0 ? <DataVizBubbles data={dataBubbles} sizeFisActive={this.state.sizeFisActive} displayCompany={this.props.displayCompanyDetails}/> : null }
                {displayFilterSpinner ? <div style={{ height: setSize , display:'flex' ,justifyContent:'center' , alignItems: 'center' }}><Spinner /></div> : null }
                {!displayFilterSpinner && dataBubbles.length === 0 ? <div style={{ height: setSize }}><DataVizNoData /></div> : null }
            </div>
        </div>
    )
  }
}


export default BubblesNav;
