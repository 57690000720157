import React, {useEffect , useState } from 'react';
import { connect } from 'react-redux';
import Select, { components } from 'react-select';


import arrow from '../../assets/images/arrow-down.svg';
import UserIcon from '../../assets/images/UserIcon.svg';
import AdministratorIcon from '../../assets/images/AdministratorIcon.svg';
import deleteIcon from '../../assets/images/delete.svg';
import deleteIconActive from '../../assets/images/delete-active.svg';

import { deleteUserAction , updateUserAction } from '../../actions/usersActions';

const userLevelOptions = [
  { value: 'Admin', label: 'Administator' },
  { value: 'Member', label: 'Member' },
  { value: 'Bakasable', label: 'Bakasable' },
  { value: 'Owner', label: 'Owner' },
];

function UserItem(props) {
  const [ animFade , setAnimFade ] = useState("d-none");
  const [ deleteIsOver , setDeleteIsOver ] = useState(false);
  const [ userLevel , setUserLevel] = useState();

  var item = props.data;

  var timer;

  function launchAnim() {
    setAnimFade("slide-in-bottom item-list-row")
    clearTimeout(timer)
  }

  useEffect(() => {
    timer = setTimeout(launchAnim, 100 * props.index);
    return () => {
      clearTimeout(timer)
    };
  }, [item])

  useEffect(() => {
    setUserLevel(item.role)
  },[item.role])

  const customFilters = {
    container: (provided, state) => ({
      ...provided,
      width: 180,
      margin: 10,
      color: "#0092c5",
      height: 32,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
    }),
    option: (provided, state) => ({
      ...provided,
      display: "flex",
      height: 38,
      alignItems: "center",
      cursor: "pointer",
      color:'#58585a',
      backgroundColor: state.isFocused ? 'rgba(161, 188, 222 , 0.2)': '#FCFDFE',
      transition:"all 1s cubic-bezier(0.075, 0.82, 0.165, 1)",
      paddingLeft: 20,
    }),
    indicatorSeparator: (provided, state) => ({
        ...provided,
        display: 'none'
    }),
    control: (provided, state) => {
      return ({
        ...provided,
        borderRadius: 0,
        cursor: "pointer",
        border: '0 !important',
        backgroundColor: 'transparent',
        marginTop: -2,
        // This line disable the blue border
        boxShadow: '0 !important',
        '&:hover': {
            border: '0 !important'
        }
    })},
    menuPortal: base => ({
        ...base,
        margin: 0,
        zIndex: 9999,
    }),
    menuList: (provided, state) => {
      return ({
      })
    },
    menu: (provided, state) => {
      return ({
        ...provided,
        padding: 0,
        width: 202,
        marginTop: -6,
        marginLeft: -11,
        fontSize: 13,
        borderRadius: 0,
        boxShadow: '0px 3px 5px 0px rgba(204, 213, 222, .8) !important',
        border: '0 !important',
        borderTop: "0px solid #FFFFFF !important",
        borderLeft: "1px solid #A1BCDE !important",
        borderRight: "1px solid #A1BCDE !important",
        borderBottom: "1px solid #A1BCDE !important"
      }
    )},
    singleValue: (provided, state) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      color: "#004a98",
      fontWeight: 700,
      fontSize: 13,
    }),
    input: (provided, state) => ({
      ...provided,
      fontSize: 13,
  }),
    placeholder: (provided, state) => ({
      ...provided,
      fontSize: 13,
  })
  };

  const IconOption = (props) => (
    <components.Option {...props}>
      {props.data.label === "Member"? 
        <img src={UserIcon} style={{ marginRight: 12 }} alt="role-icon"/>
      :
        <img src={AdministratorIcon} style={{ marginRight: 8 }} alt="role-icon"/>
      }
      {props.data.label}
    </components.Option>
  )

  const IconSingleValue = (props) => (
    <components.SingleValue {...props}>
      {props.data.label === "Member"? 
        <img src={UserIcon} style={{ marginRight: 12 }} alt="role-icon"/>
      :
        <img src={AdministratorIcon} style={{ marginRight: 8 }} alt="role-icon"/>
      }      {props.data.label}
    </components.SingleValue>
  )

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={arrow} alt="arrow" className={props.selectProps.menuIsOpen? "arrow arrow-up": "arrow arrow-down" } />
      </components.DropdownIndicator>
    );
  };

  function handleChangeUserLevel(level) {
    setUserLevel(level.value)
    props.updateUserAction( {
      _id : item._id,
      role: level.value
    })
  }

  return (
    <div id={props.index + item._id} className={animFade}>
      <div className="item name">
        {item.profile ? <>{item.profile.firstName} {item.profile.lastName}</> : null }
      </div>
      <div className="item email">
        {item.email}
      </div>
      <div className={`item select ${item._id === props.activeUser._id ? "disabled" : "" }`}> 
        <Select
          isDisabled={item._id === props.activeUser._id ? true : false }
          getOptionLabel={option => `${option.label}`}
          isSearchable={false}
          value={userLevelOptions.filter(option => option.value === userLevel)}
          styles={customFilters}
          menuPortalTarget={document.body}
          onChange={(option) => handleChangeUserLevel(option)}
          components={{ DropdownIndicator , Option: IconOption , SingleValue: IconSingleValue}}
          options={userLevelOptions.filter(option => option.label !== userLevel )} />
      </div>
      <div className="item icons">
        {item._id !== props.activeUser._id ?
          <div
            className="icon-container"
            id={item._id + "delete"}
            onMouseOver={() => setDeleteIsOver(!deleteIsOver)}
            onMouseOut={() => setDeleteIsOver(!deleteIsOver)}
            >
            <span className={deleteIsOver? "" : "d-none"}>Supprimer</span>
            <img
              src={deleteIsOver? deleteIconActive : deleteIcon}
              onClick={() => props.deleteUserAction(item._id) }
              alt="delete-icon"
              />
          </div>
          :
          null
        }
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    activeUser: state.auth.user
  };
}

const mapDispatchToProps = {
  deleteUserAction: deleteUserAction,
  updateUserAction: updateUserAction
}

export default connect(mapStateToProps, mapDispatchToProps)(UserItem);