/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import {Route } from 'react-router';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import history from './history';
import jwt_decode from 'jwt-decode';
import './styles/css/App.css';

import setAuthToken from './actions/setAuthToken';
import {setCurrentUser , logoutUser} from './actions/authentification';

import store from './store';

import Users from './Components/Users/Users'
import Admin from './Components/Admin/Admin';
import Explorer from './Components/Explorer/Explorer';
import Connexion from './Components/Auth/Connexion';
import ConnexionSso from './Components/Auth/ConnexionSso';

import Login from './Components/Auth/Login';
import Header from './Components/Partials/Header';

class App extends Component {
	constructor() {
		super()
		this.state = {
			activeView: 'explorer',
			modaleIsActive: false,
		}
	}

	componentWillMount() {
		if(localStorage.jwtToken) {
			setAuthToken(localStorage.jwtToken);
			const decode = jwt_decode(localStorage.jwtToken);
			store.dispatch(setCurrentUser(decode));
			const currentTime = Date.now() /1000;
			if(decode.exp < currentTime) {
					store.dispatch(logoutUser());
					history.push('/')
			}
		}
	}

	setActiveView =(view) => {
		this.setState({activeView: view})
	};


  handleModaleState = (boolean) => {
    this.setState({
      modaleIsActive: boolean,
    })
  };

	ExplorerComponent = (props) => {
		return (
			<Explorer
				setActiveView={this.setActiveView}
				{...props}
			/>
		);
	};

	LoginComponent = (props) => {
		return (
			<Login
				setActiveView={this.setActiveView}
				{...props}
			/>
		);
	};


	ConnexionComponent = (props) => {
		return (
			<Connexion
				setActiveView={this.setActiveView}
				{...props}
			/>
		);
	};

	AdminComponent = (props) => {
		return (
			<Admin
				handleModaleState={this.handleModaleState}
				modaleIsActive={this.state.modaleIsActive}
				activeView={this.state.activeView}
				setActiveView={this.setActiveView}
				{...props}
			/>
		);
	};

	UsersComponent = (props) => {
		return (
			<Users
				// handleModaleState={this.handleModaleState}
				// modaleIsActive={this.state.modaleIsActive}
				activeView={this.state.activeView}
				setActiveView={this.setActiveView}
				{...props}
			/>
		);
	};

	SsoComponent = (props) => {
		return (
			<ConnexionSso
				setActiveView={this.setActiveView}
				{...props}
			/>
		);
	};


  render() {
    return (
			<Provider store={store}>
				<Router history={history}>
					<>
					  {this.state.activeView === 'explorer' || this.state.activeView === 'admin' || this.state.activeView === 'users' ?
							<Header
								activeView={this.state.activeView}
								modaleIsActive={this.state.modaleIsActive}
								/>
							:
							null
						}
						<Route exact path="/users" render={this.UsersComponent} />
						<Route exact path="/admin" render={this.AdminComponent} />
						<Route exact path="/explorer" component={this.ExplorerComponent} />
						<Route exact path="/" component={this.ConnexionComponent} />
						<Route exact path="/login" component={this.LoginComponent} />
						<Route exact path="/sso/:token" component={this.SsoComponent} />
					</>
				</Router>
			</Provider>
    );
  }
}

export default App;
