import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { postStockValueFile } from '../../actions/adminActions';

import '../../styles/css/adminModale.css';

import Loader from '../Partials/Loader';
import waitingImg from '../../assets/images/waiting.svg';


function ModaleStockValues(props) {
  const [ activeView , setActiveView ] = useState('upload');
  const [ fileState , setFileState] = useState({});

  const fileData = {
    name: "Stock value",
    _id: "stock_value",
    color: "#004a98"
  };

  useEffect(() => {
    if (props.modaleIsActive && props.uploadedList && props.uploadedList.length > 0 ) {
    var isOnUpdateList = props.uploadedList.find(item => item._id === fileData._id);
      if (isOnUpdateList) {
        setFileState(isOnUpdateList)
        if(isOnUpdateList.fileState === "progress") {
          setActiveView('waiting');
        }
        if(isOnUpdateList.fileState === "fileUpdated") {
          setActiveView('completed');
        }
        if(isOnUpdateList.fileState === "error") {
          setActiveView('error');
        }
      }
    }

  }, [props.uploadedList , props.modaleIsActive])

  useEffect(() => {
    setActiveView('options')
    if (!props.file) {
      removeModale();
    }
  }, [props.file])

  function removeModale() {
    props.removeFile();
    setActiveView('upload')
  }

  function sumbitFile(years) {
    const formData = new FormData();

    const options = {
      _id: props.fileData._id,
      selectedYears: years
    };

    formData.append("file", props.file, props.file.name);
    formData.append("options", JSON.stringify(options) );
    
    props.postStockValueFile(formData , fileData );
    setActiveView('waiting');
  };

  function chooseNewFile(e) {
    props.onChangeFile(e , fileData );
  };

  function renderModale() {
    if (activeView  === 'upload') {
      return (
        <div className="modale-row">
          <img src={waitingImg} alt="waiting" />
          <div id={activeView} className="col-text anim-bottom">
            <p>
             Upload your <span style={{ color: fileData.color }} >{fileData.name}</span> file
            </p>
            <input
              className="input-file d-none"
              id={fileData._id}
              type="file"
              name={fileData._id}
              onChange={(e) => chooseNewFile(e)}/>
            <label className="btn-blue-rounded" htmlFor={fileData._id} >Choose a file</label>
          </div>
        </div>
      )
    } else if(activeView  === 'options') {
      return (
        <div className="modale-row">
          <img src={waitingImg} alt="waiting" />
          <div className="col-text">
            <h2 style={{ color: fileData.color }} >{fileData.name}</h2>
            <p>
              You are replacing the file<br/>
              <span>{props.file.name}</span><br/>
              What do you want to replace?<br/>
            </p>
            {/* <button className="btn-blue-rounded" onClick={() => sumbitFile("5")}>
              Submit the last 5 years
            </button> */}
            <button className="btn-blue-rounded" onClick={() => sumbitFile("all")}>
              Submit all years
            </button>
          </div>
        </div>
      )
    } else if (activeView  === 'waiting') {
      return (
        <div className="modale-row">
          <img src={waitingImg} alt="waiting" />
          <div id={activeView} className="col-text anim-bottom">
            <Loader color={ fileData.color } />
            <p>
              Your file <span style={{ color: fileData.color }} >{ fileData.name}</span><br/>
              is in the process of updating, it may take several minutes<br/>
            </p>
          </div>
        </div>
      )
    } else if (activeView  === 'completed') {
      return (
        <div className="modale-row">
          <img src={waitingImg} alt="waiting" />
          <div id={activeView} className="col-text anim-bottom">
            <h3 className="text-success">Success</h3>
            <p>
              Your file <span style={{ color: fileData.color }}>{fileData.name}</span> is updated
            </p>
            <input
              className="input-file d-none"
              id={fileData._id}
              type="file"
              name={fileData._id}
              onChange={(e) => chooseNewFile(e)}/>
            <label className="btn-blue-rounded" htmlFor={fileData._id} >Choose a file</label>
            <button className="btn-blue-rounded" onClick={() => removeModale()}>
              close
            </button>
          </div>
        </div>
      )
    } else if (activeView  === 'error' && fileState) {
      return (
        <div className="modale-row">
          <img src={waitingImg} alt="waiting" />
          <div id={activeView} className="col-text anim-bottom">
            <p>
              the process of updating<br/> for your file <span style={{ color: fileData.color }}>{ fileData.name}</span> failed
            </p>
            <p>
              <span className="text-error">{fileState.message}</span>
            </p>
            <input
              className="input-file d-none"
              id={fileData._id}
              type="file"
              name={fileData._id}
              onChange={(e) => chooseNewFile(e)}/>
            <label className="btn-blue-rounded" htmlFor={ fileData._id} >Choose a new file</label>
          </div>
        </div>
      )
    }
  };

  return (
    <div className={`modale-admin ${props.modaleIsActive && props.modaleType === "stock-values" ? "active" : "not-active" }`}>
      <div className="modale-container">
        {props.modaleIsActive ? renderModale() : null}
        <button className="btn-close" onClick={() => removeModale()}>
          <img src={process.env.PUBLIC_URL + "assets/images/close-blue.svg"} alt="close"/>
        </button>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return ({
    lists: state.admin.adminLists,
    uploadedList : state.admin.uploadedList,
  })
};

const mapDispatchToProps = {
  postStockValueFile : postStockValueFile
}

export default connect(mapStateToProps, mapDispatchToProps)( ModaleStockValues)
