import React, { Component } from 'react';
import {ResponsiveBar } from '@nivo/bar';
import '../../styles/css/financial.css';

// import * as d3 from 'd3'

export default class DataVizGhostBar extends Component {
  render() {
    return <ResponsiveBar
            data={this.props.data}
            keys={this.props.keys}
            minValue={0}
            indexBy={this.props.indexBy}
            margin={{
                "top": 35,
                "right": 35,
                "bottom": 50,
                "left": 50
            }}
            padding={0.5}
            groupMode="grouped"
            colors="nivo"
            colorBy={function(e){var t = e.id ;return e.data[ t+"Color"]}}
            color="#004a98"
            borderColor="inherit:darker(1.6)"
            axisTop={null}
            axisRight={null}
            axisBottom={{
                "tickSize": 0,
                "tickPadding": 15,
                "tickRotation": 0,
                "legendPosition": "middle",
                "legendOffset": 32
            }}
            axisLeft={{
                "tickSize": 0,
                "tickPadding": 5,
                "tickRotation": 0,
                "legendPosition": "middle",
                "legendOffset": -40
            }}
            markers={this.props.markers}
            theme={{
                markers: {
                    textColor: "#FFFFF",
                    fontSize: 10
                }
            }}
            enableLabel={false}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor="inherit:darker(1.6)"
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            legends={[]}
            tooltip={d => (
                <span style={{ color: '#000000' }}>
                 <strong style={{ color: d.color }}> {d.indexValue} {d.id} </strong> { d.value.toFixed(2)} {this.props.unit}
                </span>
            )}
        />
  }
}
