import { SET_API_DATA } from '../actions/types';

const initialState = []

export default function(state = initialState, action ) {
    switch (action.type) {
        case SET_API_DATA:
            return  action.payload
        default: 
            return state;
    }
}