import React, { Component } from 'react';
import Filters from './Filters';
import Search from './Search';

export class NavExplorer extends Component {
  constructor(props) {
    super(props)
      this.state = {
        filtersAreDisplayed: false,
        searchIsDisplayed: false,
        filters: null,
        filterIndex: null
      }
  }

  displayFilters = () => {
    if (this.state.searchIsDisplayed) {
      this.setState({
        searchIsDisplayed: !this.state.searchIsDisplayed
      })
    }
    this.setState({
      filtersAreDisplayed: !this.state.filtersAreDisplayed
    })
  }

  displaySearch = () => {
    if (this.state.filtersAreDisplayed) {
      this.setState({
        filtersAreDisplayed: !this.state.filtersAreDisplayed
      })
    }
    this.setState({
      searchIsDisplayed: !this.state.searchIsDisplayed
    })
  }

  render() {
    return (
      <div className="filters-container">
        <Filters
          // filterIsDisable={this.props.filterIsDisable}
          toggleFilterSpinner={this.props.toggleFilterSpinner}
          displayFilters={this.displayFilters}
          getAllDatasFromApi={this.props.getAllDatasFromApi}
          filtersAreDisplayed={this.state.filtersAreDisplayed} />
        <Search
          displaySearch={this.displaySearch}
          searchIsDisplayed={this.state.searchIsDisplayed}
          searchOptions={this.props.searchOptions}
          getZonesFromApi={this.props.getZonesFromApi}
          getCompaniesFromApi={this.props.getCompaniesFromApi}
          getProductsFromApi= {this.props.getProductsFromApi}
          displayCompanyDetails={this.props.displayCompanyDetails}
          />
      </div>
    )
  }
}

export default NavExplorer;
