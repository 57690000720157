import React, { Component } from 'react';
import '../../styles/css/balanceBar.css';
import { hexToRGBa , lightOrDark } from '../../utils/utils';

export default class DataVizBalanceBar extends Component {

    setLabelStyle = ( color ) => {
        var style = {}
        if (lightOrDark(color) === "dark") {
            style.color = "#FFFFFF";
            style.textShadow = `1px 1px 2px ${color}`
        } else {
            style.color = "#000000";
        }
        return style;
    };

    render() {
        let { activeIdList , balanceData } = this.props
    
        let mainValue1 = balanceData.value1 + "%";
        let secondValue1 = 100 - balanceData.value1 +"%";
        let label1 = balanceData.label1;
        let label1value = balanceData.label1value;
        let bgColor1 = hexToRGBa(balanceData.color, 0.4);
        let valueColor1 = balanceData.color;


        let mainValue2 = balanceData.value2 + "%";
        let secondValue2 = 100 - balanceData.value2 +"%";
        let label2 = balanceData.label2;
        let label2value = balanceData.label2value;
        let bgColor2 = hexToRGBa(balanceData.color, 0.4);
        let valueColor2 = balanceData.color
  
        const isActive = this.props.idIsOnActiveIdList(this.props.id)

        return (
            <div className={activeIdList.length === 0 || isActive ? "balance-main-container" : "balance-main-container dataViz-unfocus" }>
                <div className="color-left" style={{backgroundColor: balanceData.color}}></div>
                <div className="balance-bars-trade">
                <h4 className="balance-bar-name">{balanceData.name}</h4>
                    <div className="balance-bar-trade">
                        <div className="balance-value"
                            style={{
                                backgroundColor: valueColor1,
                                width: mainValue1 ,
                                justifyContent :'flex-start'
                                }}>
                        </div>
                        <div
                            className="balance-value"
                            style={{
                                backgroundColor: bgColor1 ,
                                width: secondValue1,
                                justifyContent :'flex-end'
                                }}>
                        </div>
                    </div>
                    <div className="balance-label-trade" style={this.setLabelStyle(balanceData.color)}>{label1value !== 0 ? <span>{label1} {label1value} ktp</span> : <span >Data not available</span> }</div>
                    <div className="balance-bar-trade">
                        <div className="balance-value"
                            style={{
                                backgroundColor: valueColor2,
                                width: mainValue2 ,
                                justifyContent :'flex-start'
                                }}>
                        </div>
                        <div
                            className="balance-value"
                            style={{
                                backgroundColor: bgColor2 ,
                                width: secondValue2,
                                justifyContent :'flex-end'
                                }}>
                        </div>
                    </div>
                    <div className="balance-label-trade" style={this.setLabelStyle(balanceData.color)}>{label2value !== 0 ? <span> {label2} {label2value} kt sold</span>: <span>Data not available</span> } </div>
                </div>
            </div>
        )
    }
}
