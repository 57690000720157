import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loginSso } from './../../actions/authentification';
import Spinner from './../Explorer/Spinner';

export class ConnexionSso extends Component {

  componentDidMount() {
    this.props.setActiveView('sso');
    this.props.loginSso(this.props.match.params.token);
  }

  componentDidUpdate(prevProps) {
    if(this.props.auth.isAuthenticated) {
        this.props.history.push('/explorer')
    }
  }

  render() {
    return (
      <div className="connexion-container">
        <Spinner />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
})

const mapDispatchToProps = {
  loginSso: loginSso
}

export default connect(mapStateToProps, mapDispatchToProps)(ConnexionSso)
