import React, { Component } from 'react';
import * as d3 from 'd3';
import { hexToRGBa } from '../../utils/utils';
import * as _ from 'lodash'

import '../../styles/css/financial.css';
import '../../styles/css/analysis.css';

import { setLabelColor } from '../../utils/utils';

import DataVizGhostBar from '../DataViz/DataVizGhostBar';
import DataVizMultipleBars from '../DataViz/DataVizMultipleBars';
import DataVizNoData from '../DataViz/DataVizNoData';
import DataVizLinesChart from '../DataViz/DataVizLinesChart';

export default class Financial extends Component {
    componentDidMount() {
        this.props.scrollToTop("financial-top")
    }

    renderMultipleBars = (data , graphName ) => {
            let barsData = [];
            let totalBarsToDisplay = []
            let unit = "";
            let minValue = 0
            let maxValue = 0
            let keys = [];
            let type = ""
            for (let i = this.props.selectedYear - 3; i < this.props.selectedYear + 1 ; i++ ) {
                keys.push(i.toString())
            }

            if (data) {
            data.map((item) => {
                const isActive = this.props.idIsOnActiveIdList(item.id);
                const color = this.props.activeIdList.length === 0 || isActive ? item.color : "rgba(180, 180, 180, 0.2)";
                let yearData = item.data.filter( d => parseFloat(d.year) <= this.props.selectedYear);
                yearData = yearData.filter( d => parseFloat(d.year) > this.props.selectedYear - 4);
                if (yearData.length > 0) {
                    totalBarsToDisplay.push(yearData)
                }
                let coloredObj = {}
                let rank = []
                yearData.map((item,index) => {
                    type = item.type
                    if (item.year === this.props.selectedYear.toString()) {
                        rank.push(item.rank)
                    }
                    if (keys.find(y => y === item.year)) {
                        if (parseFloat(item.value) < minValue) {
                            minValue = parseFloat(item.value)
                        }
                        if (parseFloat(item.value) > maxValue) {
                            maxValue = parseFloat(item.value)
                        }
                        coloredObj[item.year] = parseFloat(item.value)                        
                    } else {
                        coloredObj[item.year] = 0
                    }
                    if (index % 2 === 0 ) {
                        coloredObj[item.year + "Color"] = color
                        coloredObj[item.year + "Type"] = item.type
                    } else {
                        coloredObj[item.year + "Color"] = d3.rgb(color).darker(0.8).toString()
                        coloredObj[item.year + "Type"] = item.type
                    }
                    return coloredObj
                });
                unit = item.unit
                coloredObj.rank = _.max(rank)
                coloredObj.company = type[0] !== undefined ? item.name + "(" + type[0].toUpperCase() + ")" : item.name
                return barsData.push(coloredObj)
            })

            barsData.sort(function(a, b){return a.rank - b.rank})
            
            if (data && totalBarsToDisplay.length > 0 ) {
                return <DataVizMultipleBars
                            margin={{
                                "top": 35,
                                "right": 35,
                                "bottom": 50,
                                "left": 50
                            }}
                            unit={unit}
                            minValue={minValue}
                            maxValue={maxValue}
                            keys={keys.slice(0,4)}
                            displayLabel={false}
                            padding={0.65}
                            tickPadding={20}
                            groupMode="grouped"
                            indexBy="company"
                            colorBy={function(e){var t = e.id ;return e.data[ t+"Color"]}}
                            data={barsData}
                            legends={[]}
                            tooltip={(item ) => {
                                if (unit === "Ratio (€)" || graphName ===  "Gearing") {
                                    return (
                                        <span style={{ color: '#bbb' }}>
                                           {item.id} ({item.data[item.id + "Type"]}) <strong style={{ color: setLabelColor(item.color) }}>{item.value.toFixed(2)} {unit}</strong>
                                        </span>
                                    )
                                } else if (unit === "M€") {
                                    return (
                                        <span style={{ color: '#bbb' }}>
                                           {item.id} ({item.data[item.id + "Type"]}) <strong style={{ color: setLabelColor(item.color) }}>{item.value.toFixed()} {unit}</strong>
                                        </span>
                                    )
                                } else {
                                    return (
                                        <span style={{ color: '#bbb' }}>
                                           {item.id} ({item.data[item.id + "Type"]}) <strong style={{ color: setLabelColor(item.color) }}>{item.value.toFixed(1)} {unit}</strong>
                                        </span>
                                    )
                                }
                            }}
                            />
            } else {
                return <DataVizNoData />
            }
        }
    }

    renderSalesVStireMarket = () => {
        if (this.props.analysisData.salesVStiremarket) {
            const yearData = this.props.analysisData.salesVStiremarket.filter( item => item.label === this.props.selectedYear.toString())
            const nextYearData = this.props.analysisData.salesVStiremarket.filter( item => item.label === (this.props.selectedYear - 1).toString())
            if (yearData.length > 0 && yearData[0].data[0].sales !== undefined) {
                const ghostData = []
                if (nextYearData.length > 0 && nextYearData[0].data[0].sales !== undefined) {
                    yearData[0].data.map(item => {
                        const lastSales = nextYearData[0].data.filter(d => d.zone === item.zone)
                        return ghostData.push(Object.assign(item , { "last sales": lastSales[0].sales}, {"last salesColor": hexToRGBa(item.salesColor, 0.4)}))
                    })
                    return <DataVizGhostBar
                                keys={[
                                    "last sales",
                                    "sales",
                                ]}
                                indexBy="zone"
                                markers={[
                                    {
                                        axis: 'y',
                                        value: 4000000,
                                        lineStyle: { stroke: 'rgba(0, 0, 0, .35)', strokeWidth: 1 },
                                        legendOrientation: 'vertical',
                                    },
                                    {
                                        axis: 'y',
                                        value: 3000000,
                                        lineStyle: { stroke: 'rgba(0, 0, 0, .35)', strokeWidth: 1 , strokeDasharray: 4 },
                                        legendOrientation: 'vertical',
                                    },
                                ]}
                                data={yearData[0].data}
                                unit={"Mu"}
                                />
                } else {
                    return <DataVizNoData />
                }

            } else {
                return <DataVizNoData />
            }
        } else {
            return (
                <div className="no-data">
                    <DataVizNoData />
                </div>
            )
        }
    }

    renderStockValue = () => {

        const titles = {
            title: `Stock values ${this.props.selectedYear}`,
            details: "",
            flag: ""
        };

        var unit = ""

        let minArray = [];

        if( this.props.analysisData.stockValue ) {
            
            unit = this.props.analysisData.stockValue[0].unit;

            titles.details = `(${unit})`;

            const startDate = new Date(this.props.selectedYear.toString() +"-01-01");
            const endDate = new Date(this.props.selectedYear.toString() +"-12-31");
            const getDateArray = function(start, end, yearData) {

              var arr = [];
              var dt = new Date(start);
              let lastValue = null
            
              while (dt <= end) {
                let jsonDate = new Date(dt).toJSON()
                const dayData = yearData.find(date => (date.x).substring(0,10) === jsonDate.substring(0,10))

                if (dayData !== undefined) {
                  minArray.push(dayData.y)
                  lastValue = dayData.y
                  arr.push({ x: jsonDate , y: dayData.y });
                } else {
                  arr.push({ x: jsonDate , y: lastValue })
                }
                dt.setDate(dt.getDate() + 1);
              }
              return arr;
            
            }

            const dataSet = this.props.analysisData.stockValue.map((item) => {
                    let year = item.data.filter(year => (year.x).substring(0,4) === this.props.selectedYear.toString())
                    // let yearsValues = yearsFilter.filter(year => year.x > (this.props.selectedYear - 4).toString())
                      year.sort((a,b) =>{
                        var dateA = Date.parse(a.x);
                        var dateB = Date.parse(b.x);
                        return dateA > dateB ? 1 : -1;  
                      }
                    )

                    const isActive = this.props.idIsOnActiveIdList(item.id)
                    const lineChartItem = {
                    "id": item.name,
                    "color": this.props.activeIdList.length === 0 || isActive ? item.color : "rgba(180, 180, 180, 0.2)",
                    "data": getDateArray(startDate ,endDate , year)
                    }
                return lineChartItem
            })

            return <DataVizLinesChart
                        minValueY={Math.min(...minArray)}
                        animate={false}
                        xScale={{
                          "type": "point"
                        }}
                        format={(d) => {
                          if (d.substring(8,10) === "01") {
                             const formatter = new Intl.DateTimeFormat('en', { month: 'short' });
                              return formatter.format(new Date(d))
                          } else {
                              return ""
                          }
                        }}
                        enableDots={false}
                        unit={unit}
                        tooltip={slice => {
                            let month = new Date(slice.id).getMonth()
                            return (
                              <div style={{ color: '#bbb' }}>
                                <div>{month || month === 0 ?
                                        slice.id.substring(0,10) 
                                    :
                                      slice.id
                                    }
                                </div>
                                {slice.data.map((d, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      color: "#000000",
                                      padding: '3px 0',
                                    }}
                                  >
                                    <strong style={{
                                      color: setLabelColor(d.serie.color)
                                    }} >{d.serie.id} </strong>
                                    {d.data.y !== null ? d.data.y.toFixed(2) : "no data"}
                                  </div>
                                ))}
                              </div>
                          )}}
                        data={dataSet}
                        titles={titles}/>
        }
    }

    renderCompaniesFinancial = () => {
        return (
            <>
                <section className="row">
                    <div className="col-2">
                        {this.props.renderGrowthRate()}
                    </div>
                    <div className="col-2">
                    <div className="analysis-title">
                    <span className="font-weight-bold">ROCE per year <span className="lighter-details">({this.props.selectedYear - 3} to {this.props.selectedYear}) (G for Group / T for Tyre)</span></span>
                    {/* <span className="lighter-details type-flag">{this.props.analysisData.roce[0].type}</span> */}
                    </div>
                        <div className="analysis-card svg-legend-blue height-250">
                            {this.renderMultipleBars(this.props.analysisData.roce)}
                        </div>
                    </div>
                </section>
                <section className="row">
                    <div className="col-2">
                        <div className="analysis-title">
                          <span className="font-weight-bold">Operating margin <span className="lighter-details">({this.props.selectedYear - 3} to {this.props.selectedYear}) (G for Group / T for Tyre)</span></span>
                        </div>
                        <div className="analysis-card svg-legend-blue height-250">
                            {this.renderMultipleBars(this.props.analysisData.operatingmargin)}
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="analysis-title">
                          <span className="font-weight-bold">Operating income before NR <span className="lighter-details">({this.props.selectedYear - 3} to {this.props.selectedYear}) (G for Group / T for Tyre)</span></span>
                        </div>
                        <div className="analysis-card svg-legend-blue height-250">
                            {this.renderMultipleBars(this.props.analysisData.ro)}
                        </div>
                    </div>
                </section>
                <section className="row">
                    <div className="col-2">
                        <div className="analysis-title">
                          <span className="font-weight-bold">ATR</span>
                          <span className="lighter-details"> Asset Turnover Ratio ({this.props.selectedYear - 3} to {this.props.selectedYear}) (G for Group / T for Tyre)</span>
                        </div>
                        <div className="analysis-card svg-legend-blue height-250">
                            {this.renderMultipleBars(this.props.analysisData.atr)}
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="analysis-title">
                          <span className="font-weight-bold">Working Capital <span className="lighter-details"> ({this.props.selectedYear - 3} to {this.props.selectedYear}) (G for Group / T for Tyre)</span></span>
                        </div>
                        <div className="analysis-card svg-legend-blue height-250">
                            {this.renderMultipleBars(this.props.analysisData.workingcapital)}
                        </div>
                    </div>
                </section>
                <section className="row">
                    <div className="col-2">
                        {this.renderStockValue()}
                    </div>
                    <div className="col-2">
                        <div className="analysis-title">
                          <span className="font-weight-bold">Gearing <span className="lighter-details">({this.props.selectedYear - 3} to {this.props.selectedYear}) (G for Group / T for Tyre)</span></span>
                        </div>
                        <div className="analysis-card svg-legend-blue height-250">
                            {this.renderMultipleBars(this.props.analysisData.gearing , "Gearing" )}
                        </div>
                    </div>
                </section>
            </>
        )
    }

    renderProductsFinancial = () => {
        return (
            <section className="row">
                <div className="col-1">
                    <h4 className="financial-title">Competitor's sales vs Tire market sales <span className="lighter-details">({this.props.selectedYear - 1} - {this.props.selectedYear})(PC/LT and TB)</span></h4>
                    <div className="analysis-card svg-legend-blue height-300">
                        <div className="chart-legend">Mu</div>
                        {this.renderSalesVStireMarket()}
                    </div>
                </div>
            </section>
        )
    }

    renderZonesFinancial = () => {
        return (
            <section className="row">
                <div className="no-data-container">
                    <DataVizNoData />
                </div>
            </section>
        )
    }

    render() {
        return (
            <div id="financial-top">
                {this.props.activeAnalysis === "companies" ? this.renderCompaniesFinancial() : null }
                {this.props.activeAnalysis === "products" ? this.renderProductsFinancial() : null }
                {this.props.activeAnalysis === "zones" ? this.renderZonesFinancial() : null }
            </div>
        )
    }
}
