import React, { Component } from 'react';
import { ResponsiveSankey } from '@nivo/sankey';
import '../../styles/css/industry.css';

export default class DataVizSankey extends Component {
  render() {
    return <ResponsiveSankey
            data={this.props.data}
            margin={{
                "top": 40,
                "right": 50,
                "bottom": 40,
                "left": 50
            }}
            align="start"
            nodeOpacity={1}
            nodeThickness={24}
            nodeInnerPadding={1}
            nodeSpacing={5}
            nodeBorderWidth={0}
            nodeBorderColor="inherit:darker(0.8)"
            linkOpacity={0.2}
            linkHoverOthersOpacity={0.1}
            enableLinkGradient={true}
            labelPosition="inside"
            labelOrientation="horizontal"
            labelPadding={10}
            labelTextColor="#1C4998"
            animate={true}
            colorBy={d => d.color}
            motionStiffness={140}
            motionDamping={13}
            label={(node) => {
                if (node.id === "Off") {
                    return "Off The Road"
                } else {
                    return node.id
                }}}
            nodeTooltip={node => <span><strong style={{ color: node.color }}> {node.label} </strong> {node.value.toFixed(0) + " ktc"} </span>}
            linkTooltip={node => (
              <span>
                <strong style={{ color: node.source.color }}> {node.source.label} </strong>
                {" " + node.source.value.toFixed(0) + " ktc "}
                >
                <strong style={{ color: node.target.color }}> {node.target.label} </strong>
                {" " + node.target.value.toFixed(0) + " ktc"}
              </span>)}
        />
  }
}
