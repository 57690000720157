import React from 'react';
import * as d3 from 'd3';

import { parentWidth , parentHeight , setColorForPie , setLabelColor } from '../../../utils/utils';
import geoJson from '../Map/custom.geo.json';
import DataVizPie from '../DataVizPie';

export default class DataVizMap extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            countrySelected: null,
            zoomTransform: null,
            zoomStroke: null,
            piePanel: {},
        }
    }

    componentDidMount() {
        this.renderMap()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps !== this.props) {
            d3.select(this.refs.map).select("svg").remove();
            this.renderMap()
            this.setState({ piePanel: {}})
        }
    }

    componentWillUnmount() {
        d3.select(this.refs.map).select("svg").remove();
    }

    updateFactoryPie = (d , color)=> {
        this.setState({ piePanel: {
            pieData : setColorForPie(d.products),
            name : d.name,
            value: d.value,
            color: color
        }})
    }

    renderMap = () => {
        let _this = this
        let width = parentWidth(document.getElementById('map'));
        let height = parentHeight(document.getElementById('map'));

        const data = this.props.mapDatas

        const color = this.props.color
        const unit = this.props.unit
        let scale = (width - 1) / 2 / Math.PI
        let active = d3.select(null);
        
        let projection = d3.geoMercator()
            .translate([width /2, height /1.4])
            .scale(scale);
        
        let zoom = d3.zoom()
            .scaleExtent([1, scale])
            .on("zoom", zoomed);

        let path = d3.geoPath()
            .projection(projection);

        const svg = d3.select(this.refs.map).append('svg')
            .attr('width', width)
            .attr('height', height)
            .style('background', "transparent");
    
        let div = d3.select("body").append("div")
            .attr("class", "tooltip")
            .style("display", "none")
            .style("position", "absolute")
            .style("opacity", 0);

        let g = svg.append('g')

            svg.call(zoom);

            g.selectAll("path")
            .data(geoJson.features)
            .enter()
            .append('path')
            .attr('class', 'land')
            .attr('d', path )
            .attr('cursor', 'auto')
            .style('stroke-width', 0.25)
            .style('stroke', (d) => {
                if (data[d.id] && this.props.noBorders) {
                    if (data[d.id].colorZone ) {
                        return data[d.id].colorZone
                    } else {
                        return data[d.id].color
                    }
                } else {
                    return '#FFFFFF'
                }
            })
            .attr('fill', (d) => {
                if (data[d.id]) {
                    if (data[d.id].colorZone ) {
                        return data[d.id].colorZone
                    } else {
                        return data[d.id].color
                    }
                } else {
                    return '#e5e5e5'
                }
            })
            .on("click", _this.props.enableZoom ? clicked : null)
            .on("mouseover", function(d) {
                    if (data[d.id] && _this.props.enableZoom) {
                        div.transition()
                        .duration(200)
                            .style("display", "block")
                            .style("opacity", 1);
                        div.html(d.properties.name + " <span style='color: #000000'>" + Math.round(data[d.id].value) + " " + unit + "</span>")
                            .attr("class", "country-tooltip")
                            .style("left", (d3.event.pageX + 20) + "px")
                            .style("top", (d3.event.pageY) + "px")
                            .style("z-index", "3002")
                            .style("background-color", "white")
                            .style("padding", "4px")
                            .style("color", data[d.id].colorId ? setLabelColor(data[d.id].colorId) : color )
                            .style("border-radius","3px")
                            .style("box-shadow", "0.2rem 0.5rem 1rem rgba(48, 48, 48, 0.5)");
                    }
                })
            .on("mouseout", function(d) {
                div.transition()
                    .duration(500)
                    .style("display", "none")
                    .style("opacity", 0);
                });

        function zoomed() {
            if (_this.props.enableZoom) {
                g.style("stroke-width", 0.25 / d3.event.transform.k + "px");
                g.attr("transform", d3.event.transform);
            }
        }

        function clicked(d) {
                if (active.node() === this) return reset();
                active.classed("active", false);
                active = d3.select(this).classed("active", true);

                if (data[d.id] === undefined) {
                    data[d.id] = {
                        "value": 0,
                        "color": "#e5e5e5"
                    }
                }

                let bounds = path.bounds(d),
                    dx = bounds[1][0] - bounds[0][0],
                    dy = bounds[1][1] - bounds[0][1],
                    x = (bounds[0][0] + bounds[1][0]) / 2,
                    y = (bounds[0][1] + bounds[1][1]) / 2,
                    scale = Math.max(1, Math.min(8, 0.9 / Math.max(dx / width, dy / height))),
                    translate = [width / 2 - scale * x, height / 2 - scale * y];

                if (d !== _this.state.countrySelected ) {
                    div.transition()
                    .duration(500)
                    .style("display", "none")
                    .style("opacity", 0);
                    g.selectAll("path").attr('fill', (d) => {
                        if (data[d.id]) {
                            return data[d.id].color
                        } else {
                            return '#e5e5e5'
                        }
                    })
                    if (data[d.id].markers) {
                        g.selectAll("image").remove()
                    }
                    svg.transition()
                    .duration(750)
                    .call( zoom.transform, d3.zoomIdentity.translate(translate[0],translate[1]).scale(scale) ); // updated for d3 v4

                } else {
                    svg.call( zoom.transform, d3.zoomIdentity.translate(translate[0],translate[1]).scale(scale) ); // updated for d3 v4
                }

                let colorId = data[d.id].colorId

                let imgWidth = (_this.props.iconSize * 1.5) / scale

                if (_this.props.enableMarkers === true ) {
                    if (data[d.id].markers) {
                        g.selectAll("image")
                        .data(data[d.id].markers).enter()
                        .append("svg:image")
                        .attr("class", "map-marker")
                        .attr("id", function(d, i) { return "img_" + i })
                        .attr("xlink:href", process.env.PUBLIC_URL + "assets/images/circle_map.svg")
                        .attr("x", - imgWidth / 2)
                        .attr("y", - imgWidth / 2)
                        .attr("transform", function (d) { return "translate(" + projection(d.markerPosition)[0] + "," + projection(d.markerPosition)[1] + ")"})
                        .attr("width", imgWidth + "px")
                        .attr("height", imgWidth + "px")
                        .on("click", function(d, i) {
                            _this.updateFactoryPie(d, color)
                        })
                        .on("mouseover", function(d, i) {
                            g.select("#img_" + i ).transition()
                                .duration(200)
                                .attr("x", - (imgWidth * 1.25 / 2 ) )
                                .attr("y", - (imgWidth * 1.25 / 2 ) )
                                .attr("width", imgWidth * 1.25 + "px")
                                .attr("height", imgWidth * 1.25 + "px")
                            div.transition()
                                .duration(200)
                                .style("display", "block")
                                .style("opacity", 1);
                            div.html(d.name + " <span style='color: #000000'>" + Math.round(d.value) + " " + unit + "</span>")
                                .style("left", (d3.event.pageX + 20) + "px")
                                .style("top", (d3.event.pageY) + "px")
                                .style("z-index", "3002")
                                .style("background-color", "white")
                                .style("padding", "4px")
                                .style("color", colorId ? setLabelColor(colorId) : color )
                                .style("border-radius","3px")
                                .style("box-shadow", "0.2rem 0.5rem 1rem rgba(48, 48, 48, 0.5)")
                        })
                        .on("mouseout", function(d ,i) {
                            //_this.setState({ pieData: []})
                            g.select("#img_" + i ).transition()
                                .duration(200)
                                .attr("x", - imgWidth / 2)
                                .attr("y", - imgWidth / 2)
                                .attr("width", imgWidth + "px")
                                .attr("height", imgWidth + "px")
                            div.transition()
                                .duration(500)
                                .style("display", "none")
                                .style("opacity", 0);
                        });
                }
            }
            _this.setState({
                countrySelected: d,
            })
        }

        function reset() {
            _this.setState({
                countrySelected: null,
                // zoomTransform: null,
                // zoomStroke: null,
            })
            g.selectAll("image").remove()
            active.classed("active", false);
            active = d3.select(null);
            _this.setState({ piePanel: {}})

            g.selectAll("path").attr('fill', (d) => {
                if (data[d.id]) {
                    if (data[d.id].colorZone) {
                        return data[d.id].colorZone
                    } else {
                        return data[d.id].color
                    }
                } else {
                    return '#e5e5e5'
                }
            })
          
            svg.transition()
                .duration(750)
                .call( zoom.transform, d3.zoomIdentity ); // updated for d3 v4
        }
    }

    render() {
        return (
            <>
            <div id="map" ref="map">
            </div>
            {this.state.piePanel.pieData ?
                <div className="pie-map-container">
                    <div className="factory-legend">
                        <span className="factory-name"style={{color : setLabelColor(this.state.piePanel.color) }}>{this.state.piePanel.name}</span><br/>
                        <span>{this.state.piePanel.value.toFixed(2) + " " + this.props.unit}</span>
                    </div>
                    <div className="pie-map">
                        <DataVizPie
                            enableSlicesLabels={false}
                            pieDatas={this.state.piePanel.pieData}
                            innerRadius={0.65}
                            tooltip={({ id, value, color }) => {
                                // const logo = id.split('')
                                return (
                                    <div className="tooltip">
                                        {/* <img src={process.env.PUBLIC_URL + "/assets/images/" + logo[0] + logo[1] + ".png"} className="picto-vehicles" alt="close"/> */}
                                        <span>
                                        <strong style={{ color }}>{id}</strong> : {Math.round(value)} {this.props.unit}
                                        </span>
                                    </div>
                                )
                            }}
                            margin={{
                                "top": 10,
                                "right": 10,
                                "bottom": 10,
                                "left": 10
                            }}/>
                    </div>
                    <button className="btn-circle" onClick={() => this.setState({ piePanel: {}})}>
                        <img src={process.env.PUBLIC_URL + "assets/images/close-no-circle.svg"} className="picto-close" alt="close"/>
                    </button>
                </div>
                :
                null     
            }
            </>
            
        );
    }

}