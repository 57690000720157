import { ADD_TO_ANALYSIS , REMOVE_FROM_ANALYSIS } from './types';

export const addToAnalysis = (data ) => {
    return {
        type: ADD_TO_ANALYSIS,
        analysisType: data.analysisType,
        payload: data
    }
}

export const removeFromAnalysis = (data ) => {
    return {
        type: REMOVE_FROM_ANALYSIS,
        analysisType: data.analysisType,
        payload: data
    }
}
