import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import * as _ from 'lodash';
import { postFinancialFile , fetchCompanies } from '../../actions/adminActions';

import '../../styles/css/adminModale.css';

import Loader from '../Partials/Loader';
import waitingImg from '../../assets/images/waiting.svg';


function ModaleFinancial(props) {

  const [ activeView , setActiveView ] = useState('upload');
  const [ fileState , setFileState] = useState({});
  const [ errorsList , setErrorsList ] = useState();
  const [ financeLastPeriod , setFinanceLastPeriod ] =  useState();
  const [ financeLastYear , setFinanceLastYear ] =  useState();

  const customFilters = {
    container: (provided, state) => ({
      ...provided,
      width: 200,
      border: state.isSelected || state.isFocused ? '1px solid #194084' : '1px solid #194084',
      borderRadius: 5,
      marginBottom: 5
    }),
    option: (provided, state) => ({
        ...provided,
        cursor: "pointer",
        color: '#194084',
        backgroundColor: state.isSelected || state.isFocused ? 'transparent' : "transparent",
        paddingLeft: 12,
    }),
    indicatorSeparator: (provided, state) => ({
        ...provided,
        display: 'none'
    }),
    value: (provided, state) => ({
      ...provided,
      border: state.isSelected || state.isFocused ? '1px solid #194084' : '1px solid #194084',
    }),
    control: (provided, state) => ({
        ...provided,
        cursor: "pointer",
        borderColor: state.isSelected || state.isFocused ? 'transparent' : "transparent",
        border: "none",
    }),
    menuList: (provided, state) => ({
      ...provided,
      maxHeight: 200
    }),
    menu: (provided, state) => ({
        ...provided,
        fontSize: 14,
    }),
    multiValue: (provided, state) => ({
        ...provided,
        backgroundColor: "#DAE6F2",
        color: '#194084',
    }),
    multiValueLabel: (provided, state) => ({
        ...provided,
        color: '#194084',
    }),
    placeholder: (provided, state) => ({
        ...provided,
        fontSize: 14,
        textAlign: 'left'
    })
  }

  useEffect(() => {
    if (props.modaleIsActive && props.uploadedList && props.uploadedList.length > 0 && props.fileData && props.fileData._id) {
    var isOnUpdateList = props.uploadedList.find(item => item._id === props.fileData._id);
      if (isOnUpdateList) {
        setFileState(isOnUpdateList)
        if(isOnUpdateList.fileState === "progress") {
          setActiveView('waiting');
        };
        if(isOnUpdateList.fileState === "fileUpdated") {
          if(isOnUpdateList.response &&  isOnUpdateList.response.messages &&  isOnUpdateList.response.messages.length > 0 ) {
            // removeModale();
            setErrorsList(isOnUpdateList.response.messages)
            setActiveView('completed');
            props.fetchCompanies();
          } else if (isOnUpdateList.response &&  isOnUpdateList.response.message) {
            setActiveView('completed');
            props.fetchCompanies();
          };
        };
        if(isOnUpdateList.fileState === "error") {
          // removeModale();
          setActiveView('error');
        };
      };
    };

  }, [props.uploadedList , props.fileData , props.modaleIsActive ]);



  useEffect(() => {
    setActiveView('options')
    if (!props.file) {
      removeModale();
    }
  }, [props.file])

  function removeModale() {
    props.removeFile();
    setActiveView('upload')
    setFinanceLastPeriod();
  }

  function sumbitFile(value) {
    const formData = new FormData();

    const options = {
      _id: props.fileData._id,
      financeLastPeriod: financeLastPeriod.value,
      financeLastYear: financeLastYear.value,
      selectedYears: value
    };

    formData.append("file", props.file, props.file.name);
    formData.append("options", JSON.stringify(options) );
    
    props.postFinancialFile(formData , props.fileData );
    setActiveView('waiting');
    setFinanceLastPeriod();
  }

  function chooseNewFile(e) {
    props.onChangeFile(e ,props.fileData );
    setFinanceLastPeriod();
  }

  function renderErrorList() {
    if (errorsList && errorsList.length > 0) {
      let sheetErrors = []
      let sheetsNames = _.uniqBy(errorsList.map(item => item.sheet), (e) => e );
      sheetsNames.forEach(sheetName => {
        return sheetErrors.push({
          name: sheetName,
          list: errorsList.filter(error =>  error.sheet === sheetName)
        })
      });
      return (
        <>
        <h4 className="title-errors">List of possible errors</h4>
        <div className="file-errors-list">
          {sheetErrors.map((sheet , i ) => {
            return (
              <div key={i}>
              <h4>{sheet.name}</h4>
              {sheet.list.map((error, i ) => <p key={i}>{error.row}</p>)}
              </div>
            )
          })}
        </div>
        </>
      );
    }
  }

  function renderModale() {
    if (activeView  === 'upload') {
      return (
        <div className="modale-row">
          <img src={waitingImg} alt="waiting" />
          <div id={activeView} className="col-text anim-bottom">
            <p>
             Upload your financial file for <span style={{ color: props.fileData.color !== '#FFFF00' ? props.fileData.color  : '#004a98' }} >{props.fileData.name}</span>
            </p>
            <input
              className="input-file d-none"
              id={props.fileData._id}
              type="file"
              name={props.fileData._id}
              onChange={(e) => chooseNewFile(e)}/>
            <label className="btn-blue-rounded" htmlFor={props.fileData._id} >Choose a file</label>
          </div>
        </div>
      )
    } else if(activeView  === 'options') {
      var yearsOptions = [];
      var startYear = 2002;

      while (startYear < 2025) {
        yearsOptions.push({ value:  startYear , label : startYear })
        startYear++
      }

      const options = [
        {
          value: 'Q1',
          label:'Q1'
        },
        {
          value: 'H1',
          label:'H1'
        },
        {
          value: '9M',
          label:'9M'
        },
        {
          value: 'FY',
          label:'FY'
        }
      ];
      return (
        <div className="modale-row">
          <img src={waitingImg} alt="waiting" />
          <div className="col-text">
            <h2 style={{ color: props.fileData.color !== '#FFFF00' ? props.fileData.color  : '#004a98' }} >{props.fileData.name}</h2>
            <p>
              You are replacing the file<br/>
              <span>{props.file.name}</span><br/>
              Which period would you like to replace ?<br/>
            </p>

            <Select
              styles={customFilters}
              options={options}
              value={financeLastPeriod}
              placeholder="Choose the finance last period"
              className="select-filters"
              onChange={(value) => setFinanceLastPeriod(value)} />

            <Select
              styles={customFilters}
              options={yearsOptions}
              value={financeLastYear}
              placeholder="Choose the finance last year"
              className="select-filters"
              onChange={(value) => setFinanceLastYear(value)} />

            {financeLastPeriod && financeLastPeriod.value && financeLastYear && financeLastYear.value &&
             <>
             
              <button className="btn-blue-rounded" onClick={() => sumbitFile("5")}>
                Submit the last 5 years
              </button>
              <button className="btn-blue-rounded" onClick={() => sumbitFile("all")}>
                Submit all years
              </button>
            </>
            } 

          </div>
        </div>
      )
    } else if (activeView  === 'waiting') {
      return (
        <div className="modale-row">
          <img src={waitingImg} alt="waiting" />
          <div id={activeView} className="col-text anim-bottom">
            <Loader color={ props.fileData.color !== '#FFFF00' ? props.fileData.color  : '#004a98' } />
            <p>
              Your file <span style={{ color: props.fileData.color !== '#FFFF00' ? props.fileData.color  : '#004a98' }} >{props.fileData.name}</span><br/>
              is in the process of updating, it may take several minutes<br/>
            </p>
          </div>
        </div>
      )
    } else if (activeView  === 'completed') {

      return (
        <div className="modale-row">
          <img src={waitingImg} alt="waiting" />
          <div id={activeView} className="col-text anim-bottom">
            <p>
              Your file <span style={{ color: props.fileData.color !== '#FFFF00' ? props.fileData.color  : '#004a98' }}>{props.fileData.name}</span> is updated
            </p>
            {renderErrorList()}
            <input
              className="input-file d-none"
              id={props.fileData._id}
              type="file"
              name={props.fileData._id}
              onChange={(e) => chooseNewFile(e)}/>
            <label className="btn-blue-rounded" htmlFor={props.fileData._id} >Choose a file</label>
          </div>
        </div>
      )
    } else if (activeView  === 'error' && fileState) {
      return (
        <div className="modale-row">
          <img src={waitingImg} alt="waiting" />
          <div id={activeView} className="col-text anim-bottom">
            <p>
              the process of updating<br/> for your file <span style={{ color: props.fileData.color !== '#FFFF00' ? props.fileData.color  : '#004a98' }}>{props.fileData.name}</span> failed
            </p>
            <p>
              <span className="text-error">{fileState.message}</span>
            </p>
            <input
              className="input-file d-none"
              id={props.fileData._id}
              type="file"
              name={props.fileData._id}
              onChange={(e) => chooseNewFile(e)}/>
            <label className="btn-blue-rounded" htmlFor={props.fileData._id} >Choose a new file</label>
          </div>
        </div>
      )
    }
  };

  return (
    <div className={`modale-admin ${props.modaleIsActive && props.modaleType === "financial" ? "active" : "not-active" }`}>
      <div className="modale-container">
        {props.modaleIsActive && props.fileData ? renderModale() : null}
        <button className="btn-close" onClick={() => removeModale()}>
          <img src={process.env.PUBLIC_URL + "assets/images/close-blue.svg"} alt="close"/>
        </button>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return ({
    lists: state.admin.adminLists,
    uploadedList : state.admin.uploadedList,
  })
};

const mapDispatchToProps = {
  postFinancialFile : postFinancialFile,
  fetchCompanies: fetchCompanies
}

export default connect(mapStateToProps, mapDispatchToProps)( ModaleFinancial)
