import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import '../../styles/css/FiltersandSearch.css';
import axios from 'axios';
import _ from 'lodash';

import { API_URL } from '../../utils/utils';
import { setCurrentFilter, setApiData } from '../../actions/filterActions';

const customFilters = {
    option: (provided, state) => ({
        ...provided,
        cursor: "pointer",
        color: '#194084',
        backgroundColor: state.isSelected || state.isFocused ? 'transparent' : "transparent",
        paddingLeft: 12,
    }),
    indicatorSeparator: (provided, state) => ({
        ...provided,
        display: 'none'
    }),
    control: (provided, state) => ({
        ...provided,
        cursor: "pointer",
        borderColor: state.isSelected || state.isFocused ? 'transparent' : "transparent",
        border: "none",
    }),
    menu: (provided, state) => ({
        ...provided,
        fontSize: 14,
    }),
    multiValue: (provided, state) => ({
        ...provided,
        backgroundColor: "#DAE6F2",
        color: '#194084',
    }),
    multiValueLabel: (provided, state) => ({
        ...provided,
        color: '#194084',
    }),
    placeholder: (provided, state) => ({
        ...provided,
        fontSize: 14,
    })
}

export class Filters extends Component {
  constructor(props) {
    super(props)
    this._isMounted = false;
    this.state = {
      filters: null,
      filterIndex: null,
    }
  }

  componentWillMount = () => {
    this._isMounted = true;
    this.getFilters();
  }


	componentWillUnmount() {
		this._isMounted = false;
	}

  getFilters() {
    axios.get(API_URL + "/dataviz/filter")
      .then(res => {
        const data = res.data.filters;
        if(this._isMounted) {
          this.setState({
            filters: this.props.filtersFromStore.length > 0 ? this.props.filtersFromStore : data,
          })
        }
      })
  }

  removeOptions = (filtersData) => {
    var arr = _.cloneDeep(filtersData)
    return arr.map(item => {
      delete item.options
      return item
    })
  }

  handleSelectChange = (selectedOption, e) => {
    let newFilters = [...this.state.filters]
    newFilters[e.name].selected = selectedOption;
    this.props.setCurrentFilter(newFilters)
    this.setState({
        filters: newFilters
    })
  }

  displaySelectGeneral = (obj) => {
    const filterIndex = this.state.filters.indexOf(obj)
    if (obj.type === "General") {
      var  name = obj.name; 
      if (obj.name === "Produits") {
        name = "Products"
      } else if (obj.name === "Marchés") {
        name = "Markets";
      }
      return (
        <div className="select-containers">
          <label htmlFor="continent-select" className="label-filters">{name}</label>
          <Select
            styles={customFilters}
            isMulti={true}
            options={obj.options}
            name={filterIndex}
            defaultValue={obj.selected}
            className="select-filters"
            onChange={this.handleSelectChange} />
        </div>
      )
    }
  }

  displaySelectGeo = (obj) => {
    const filterIndex = this.state.filters.indexOf(obj);
    if (obj.type === "Geographique") {
      return (
        <div className="select-containers">
          <label htmlFor="continent-select" className="label-filters">{obj.name}</label>
          <Select
              styles={customFilters}
              isMulti={true}
              options={obj.options}
              defaultValue={obj.selected}
              name={filterIndex}
              className="select-filters"
              onChange={this.handleSelectChange} />
        </div>
      );
    }
  }

  submitFilters = () => {
    const withoutOptions = this.removeOptions(this.state.filters);
    let that = this
    that.props.toggleFilterSpinner()
    axios.post(API_URL + "/dataviz/filter", { filters: withoutOptions })
      .then(function (res) {
          that.props.getAllDatasFromApi(res);
      })
      .catch(function (error) {
          console.log(error);
      });
    this.props.displayFilters();
  }

  render() {
    const { filters } = this.state
    return (
      <div className={"filters-content"} >
        {!this.props.filtersAreDisplayed ?
          <img onClick={() => this.props.displayFilters()} src={process.env.PUBLIC_URL + "assets/images/burger.png"} className="picto-filters" alt="logo" />
          :
          <div>
            <div className="header-filters">
              <div className="align-items-center">
                <img onClick={() => this.props.displayFilters()} src={process.env.PUBLIC_URL + "assets/images/burger.png"} className="picto-filters" alt="logo" />
                <span className="main-title-filters">Filters</span>
              </div>
              <img onClick={() => this.props.displayFilters()} src={process.env.PUBLIC_URL + "assets/images/close.svg"} className="picto-search" alt="close" />
            </div>
            <div className="select-containers">
              <h4 className="titles-filters" >General</h4>
              {filters.map((item, index) => <div key={index}>{this.displaySelectGeneral(item)}</div>)}
              <h4 className="titles-filters" >Geographical</h4>
              {filters.map((item, index) => <div key={index}>{this.displaySelectGeo(item)}</div>)}
            </div>
            <div className="justify-content-center">
              <button className="btn-secondery-rounded" onClick={this.submitFilters}>Submit filters</button>
            </div>
          </div>
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
    filtersFromStore: state.filter
})

const mapDispatchToProps = {
    setCurrentFilter: setCurrentFilter,
    setApiData: setApiData
}

export default connect(mapStateToProps, mapDispatchToProps)(Filters)
