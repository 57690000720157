import axios from 'axios';
import { API_URL } from '../utils/utils'
import { GET_ERRORS, SET_CURRENT_USER } from  './types';
import history from '../history';
import setAuthToken from './setAuthToken';
import jwt_decode from 'jwt-decode';

// export const registerUser = (user, history ) => dispatch => {
//     axios.post( 'http://localhost:5000/api/users/register', user)
//         .then(history.push('/'))
//         .catch(err => {
//             dispatch({
//                 type: GET_ERRORS,
//                 payload: err.response.data
//             });
//     });
// }

export const loginSso = (sendToken) => dispatch => {
    const jwtToken = 'JWT '+sendToken

    let config = { headers: {
        Authorization : jwtToken
    }}

    axios.get(API_URL + '/auth/login-saml/user', config )
        .then(res => {
          const { token } =res.data;
          localStorage.setItem('jwtToken', token );
          setAuthToken(token);
          const decoded = jwt_decode(token);
          dispatch(setCurrentUser(decoded));
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            });
        });
}

export const loginUser = (user) => dispatch => {
    axios.post(API_URL + '/auth/login', user)
        .then(res => {
            const { token } =res.data;
            localStorage.setItem('jwtToken', token );
            setAuthToken(token);
            const decoded = jwt_decode(token);
            dispatch(setCurrentUser(decoded));
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            });
        });
}

export const setCurrentUser = decoded => {
    return {
        type: SET_CURRENT_USER,
        payload: decoded
    }
}

export const logoutUser = () => dispatch => {
    localStorage.removeItem('jwtToken');
    setAuthToken(false);
    dispatch(setCurrentUser({}));
    history.push('/');
}
