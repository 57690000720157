import { FETCH_USERS_LIST , USERS_ERROR , DELETE_USER, ADD_USER,  UPDATE_USER } from '../actions/types';

const initialState = {
  usersList : [],
  usersError: {}
}

export default function(state = initialState, action ) {
    var filtredList;
    var user;
    switch (action.type) {

        case FETCH_USERS_LIST:
            return  {
              ...state,
              usersList: action.payload.users
          }

        case ADD_USER:
            user = action.payload.user
            user.profile = { firstName: user.firstName , lastName: user.lastName }
            return {
              ...state,
              usersList: [...state.usersList , user]
            }

        case UPDATE_USER:
            filtredList = state.usersList.filter(item => item._id !== action.payload.user._id)
            user = action.payload.user
            user.profile = { firstName: user.firstName , lastName: user.lastName }
            return  {
              ...state,
              usersList: [...filtredList , user]
          }
        
        case DELETE_USER:
            filtredList = state.usersList.filter(item => item._id !== action.payload)
            return  {
              ...state,
              usersList: filtredList
          }

        case USERS_ERROR:
            return  {
              ...state,
              usersError : action.payload
          }
          
        default: 
            return state;
    }
}