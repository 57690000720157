import React, { Component } from 'react';
import '../../styles/css/adminNav.css';

export default class NavAdmin extends Component {

  render() {
    const { list } = this.props;
      const renderNavButton = [
        {
            function: () => this.props.setCompaniesIsActive(),
            icon: "assets/images/factory.png",
            isActive: this.props.activeList,
            list: list.companies,
            label: "Manufacturer"
        },
        {
            function: () => this.props.setProductsIsActive(),
            icon: "assets/images/tires.png",
            list: list.products,
            label: "Products"
        },
        {
            function: () => this.props.setZonesIsActive(),
            icon: "assets/images/localisation.png",
            list: list.zones,
            label: "Zones"
        }
    ];
    return (
      <div>
        {renderNavButton.filter(item => item.list ).map((item, index) => {
          return (
          <div key={index} className="btn-admin-nav" onClick={item.function}>
              <img
                  src={process.env.PUBLIC_URL + item.icon}
                  className={this.props.activeList === item.label ? "icon-admin-nav-active":"icon-admin-nav" }
                  alt={item.label}
                  />
              <span className={this.props.activeList === item.label ? "label-admin-nav-active" : "label-admin-nav"} >{item.label}</span>
          </div>
          )
      })}
      </div>
    )
  }
}
