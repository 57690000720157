import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logoutUser } from '../../actions/authentification';
import { withRouter } from 'react-router-dom';

export class UserConnection extends Component {

    render() {
        const { user } = this.props
        return (
            <div className="user-container">
                <span className="text-blue">{user.firstName} {user.lastName}</span>
                <img src={process.env.PUBLIC_URL + "assets/images/exit.png"} onClick={() => this.props.logout(this.props.history)}  className="picto-logout" alt="logo"/>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({ 

})
  
const mapDispatchToProps = {
    logout: logoutUser
}
  
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserConnection));
