import { SET_CURRENT_FILTER, SET_API_DATA } from './types';

export const setCurrentFilter = (data ) => {
    return {
        type: SET_CURRENT_FILTER,
        payload: data
    }
}

export const setApiData = (data ) => {
    return {
        type: SET_API_DATA,
        payload: data
    }
}
