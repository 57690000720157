//EXPLORER 

export const GET_ERRORS = 'GET_ERRORS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_CURRENT_FILTER = 'SET_CURRENT_FILTER';
export const SET_API_DATA = 'SET_API_DATA';
export const ADD_TO_ANALYSIS = 'ADD_TO_ANALYSIS';
export const REMOVE_FROM_ANALYSIS = 'REMOVE_FROM_ANALYSIS';

//ADMIN
export const ADMIN_ERROR = 'ADMIN_ERROR';
export const FETCH_COMPANIES_LIST = 'FETCH_COMPANIES_LIST';
export const SET_ADMIN_IS_ACTIVE = 'SET_ADMIN_IS_ACTIVE';
export const POST_FILE = 'POST_FILE';
export const RESET_ADMIN_LIST ='RESET_ADMIN_LIST';
export const SET_UPLOADED_LIST ='SET_UPLOADED_LIST';
export const POST_FINANCIAL_FILE ='POST_FINANCIAL_FILE';
export const FETCH_ZONES_LIST = 'FETCH_ZONES_LIST';
export const FETCH_PRODUCTS_LIST ='FETCH_PRODUCTS_LIST';
export const EXPORT_FINANCIAL = 'EXPORT_FINANCIAL';
export const RESET_EXPORT = 'RESET_EXPORT';
//USERS 
export const USERS_ERROR = 'USERS_ERROR';
export const FETCH_USERS_LIST = 'FETCH_USERS_LIST';
export const ADD_USER = "ADD_USER";
export const DELETE_USER ="DELETE_USER";
export const UPDATE_USER ="UPDATE_USER";


