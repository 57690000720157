import React, { Component } from 'react';
import '../../styles/css/balanceBar.css';
import { hexToRGBa , lightOrDark } from '../../utils/utils';
import pc from '../../assets/images/PC.png';
import pl from '../../assets/images/PL.png';


export default class DataVizBalanceBar extends Component {

    setLabelStyle = ( color ) => {
        var style = {}
        if (lightOrDark(color) === "dark") {
            style.color = "#FFFFFF";
            style.textShadow = `1px 1px 2px ${color}`
        } else {
            style.color = "#000000";
        }
        return style;
    }

    render() {
        let { activeIdList } = this.props
        let { balanceData } = this.props
        // console.log(balanceData)
        let colorOpactity = hexToRGBa(balanceData.color, 0.4);
    
        let tcoe = balanceData.tcoe + "%";
        let tcoedif = 100 - balanceData.tcoe+"%";

        let tcrt = balanceData.tcrt + "%";
        let tcrtdif = 100 - balanceData.tcrt +"%";

        let ploe = balanceData.ploe + "%";
        let ploedif = 100 - balanceData.ploe+"%";

        // let plrt = balanceData.plrt + "%";
        // let plrtdif = 100 - balanceData.plrt +"%";
  
        const isActive = this.props.idIsOnActiveIdList(this.props.id)

        return (
            <div className={activeIdList.length === 0 || isActive ? "balance-main-container" : "balance-main-container dataViz-unfocus" }>
                <div className="color-left" style={{backgroundColor: balanceData.color  }}></div>
                <div className="balance-bars-col">
                    <h4 className="balance-bar-name">{balanceData.name}</h4>
                    <div className="balance-bars-row">
                        <img src={pc} alt="cars"/>
                        <div className="balance-bars-container">
                            <div className="balance-bar">
                                <div className="balance-value"
                                    style={{
                                        backgroundColor: balanceData.color ,
                                        width: tcoe ,
                                        justifyContent :'flex-start'
                                        }}>
                                </div>
                                <div
                                    className="balance-value"
                                    style={{
                                        backgroundColor: colorOpactity ,
                                        width: tcoedif,
                                        justifyContent :'flex-end'
                                        }}>
                                </div>
                            </div>
                            <span className="balance-label" style={this.setLabelStyle(balanceData.color)}>OE {balanceData.tcoeLabel} kt sold</span>
                            <div className="balance-bar">
                                <div className="balance-value"
                                    style={{
                                        backgroundColor:  balanceData.color,
                                        width: tcrt ,
                                        justifyContent :'flex-start'
                                        }}>
                                </div>
                                <div
                                    className="balance-value"
                                    style={{
                                        backgroundColor: colorOpactity,
                                        width: tcrtdif,
                                        justifyContent :'flex-end'
                                        }}>
                                </div>
                            </div>
                            <span className="balance-label" style={this.setLabelStyle(balanceData.color)}>RT {balanceData.tcrtLabel} kt sold</span>
                        </div>
                    </div>
                    <div className="balance-bars-row">
                        <img src={pl} alt="trucks"/>
                        <div className="balance-bars-container">
                            <div className="balance-bar">
                                <div className="balance-value"
                                    style={{
                                        backgroundColor: balanceData.color ,
                                        width: ploe ,
                                        justifyContent :'flex-start'
                                        }}>
                                </div>
                                <div
                                    className="balance-value"
                                    style={{
                                        backgroundColor: colorOpactity ,
                                        width: ploedif,
                                        justifyContent :'flex-end'
                                        }}>
                                </div>
                            </div>
                            <span className="balance-label" style={this.setLabelStyle(balanceData.color)}>OE {balanceData.ploeLabel} kt sold</span>
                            {/* <div className="balance-bar">
                                <div className="balance-value"
                                    style={{
                                        backgroundColor:  balanceData.color,
                                        width: plrt ,
                                        justifyContent :'flex-start'
                                        }}>
                                </div>
                                <div
                                    className="balance-value"
                                    style={{
                                        backgroundColor: colorOpactity,
                                        width: plrtdif,
                                        justifyContent :'flex-end'
                                        }}>
                                </div>
                            </div>
                            <span className="balance-label" style={{ textShadow: `1px 1px 2px ${balanceData.color}` }}>RT {balanceData.plrtLabel} kt</span> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
