import React, { Component } from 'react';
import { connect } from 'react-redux';
import { removeFromAnalysis , addToAnalysis} from '../../actions/analysisActions';
import { hexToRGBa , API_URL } from '../../utils/utils';
import axios from 'axios';

import '../../styles/css/analysisList.css';
import Spinner from '../Explorer/Spinner';

export class AnalysisList extends Component {
    constructor(props) {
        super(props)
        this.state ={
            analysis : this.props.companiesAnalysis,
            displayExcelSpinner : false,
            exportIsActive: false
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.analysis.length !== this.state.analysis.length && !this.props.explorerIsActive && this.state.analysis.length > 0 ) {
            this.props.setAllToActiveId();
        }
        if (prevState.analysis !== this.state.analysis ) {
            this.props.setSelectedAnalysisList(this.state.analysis);
            this.props.setAllToActiveId();
        }
        if(prevProps !== this.props ) {
            switch (this.props.activeAnalysis) {
                case "zones":
                    this.setState({
                        analysis: this.props.zonesAnalysis,
                        exportIsActive: false
                    })
                    break;
                case "products":
                    this.setState({
                        analysis: this.props.productsAnalysis,
                        exportIsActive: false
                    })
                    break;
                    
                default:
                    this.setState({
                        analysis: this.props.companiesAnalysis,
                        exportIsActive: true
                    })
                    break;
            }
        }
    }

    jsonToExcel = (param) => {
        this.setState({ displayExcelSpinner : true});
        const ExportJsonExcel = require('js-export-excel');
        let idList = []
        this.state.analysis.map(item => idList.push(item._id))
        let reqBody = {
            year: this.props.selectedYear,
            idList: idList
        }
        let data = {};
        if (param === "control") {
            axios.post(API_URL + `/export/control`, reqBody)
            .then(res => {
                data.fileName = res.data.fileName;
                data.datas = res.data.dataToExport;
                let toExcel = new ExportJsonExcel(data);
                this.setState({ displayExcelSpinner : false });
                return toExcel.saveExcel();
            })
            .catch(function (error) {
                console.log(error);
                this.setState({ displayExcelSpinner : false });
            })
        }
    }

    render() {
        if ( this.state.analysis.length === 0 && !this.props.explorerIsActive ) {
            this.props.removeExplorer()
        }
        return (
            <div className="analysis-list-container" id="dropzone">
                <div className="analysis-list">
                    {this.props.activeIdList.length > 0 ?
                        <>
                            {this.state.analysis.map((item,index) => {
                                const isActive = this.props.idIsOnActiveIdList(item._id)
                                return (
                                    <div className={this.props.activeIdList.length === 0 || isActive ? "analysis-item" : "analysis-item item-disable"}
                                        key={index}
                                        style={{backgroundColor: hexToRGBa(item.color, 0.4), border: '1px solid' + item.color}}
                                        onClick={() => this.props.setActiveIdList(item._id)}
                                        >
                                        <span>{item.name}</span>
                                        <img className="remove-icon" src={process.env.PUBLIC_URL + "assets/images/close-no-circle.svg"} alt="remove" onClick={() => (this.props.removeFromAnalysis(item))}/>
                                    </div>
                                )
                            })}
                        </>
                    :
                        <>
                            {this.state.analysis.map((item,index) => {
                                return (
                                    <div className="analysis-item"
                                        key={index}
                                        style={{backgroundColor: hexToRGBa(item.color, 0.4), border: '1px solid' + item.color}}
                                        onClick={() => this.props.setActiveIdList(item._id)}
                                        >
                                        <span>{item.name}</span>
                                        <img className="remove-icon" src={process.env.PUBLIC_URL + "assets/images/close-no-circle.svg"} alt="remove" onClick={() => (this.props.removeFromAnalysis(item))}/>
                                    </div>
                                )
                            })}
                        </>
                    }
                </div>
                <div>
                    {this.props.explorerIsActive ?
                        <>
                            <button className={this.state.analysis.length === 0 ? "btn-blue-rounded btn-disabled" : "btn-blue-rounded"} disabled={this.state.analysis.length === 0 ? true : false } onClick={() => this.props.getAnalysisFromApi("global")}>Analysis</button>
                            <button className={this.props.activeAnalysis === "companies" && this.state.analysis.length > 0 ? "btn-circle" : "btn-circle btn-disabled" } disabled={this.props.activeAnalysis === "companies" && this.state.analysis.length > 0 ? false : true } onClick={() => this.props.displayModalToggle()}>
                                <img src={process.env.PUBLIC_URL + "assets/images/download.png"} className="save-icon" alt="save"/>
                            </button>
                        </>
                    :
                        <>
                            <button className="btn-blue-rounded" onClick={() => this.props.removeExplorer()}>Back</button>
                            <button className={this.props.activeAnalysis === "companies" && this.state.analysis.length > 0 ? "btn-circle" : "btn-circle btn-disabled" } disabled={this.props.activeAnalysis === "companies" && this.state.analysis.length > 0 ? false : true } onClick={() => this.props.displayModalToggle()}>
                                <img src={process.env.PUBLIC_URL + "assets/images/download.png"} className="save-icon" alt="save"/>
                            </button>
                        </>
                    }
                </div>
                <div className={this.props.displayModal ? "modal-container modal-block" : "modal-container modal-none" }>
                    <div className="modal-content">
                        {!this.state.displayExcelSpinner ?
                            <>
                                <button className="btn-blue-rounded" onClick={() => this.jsonToExcel("control")}>Control data</button>
                                <button className="btn-blue-rounded btn-disabled" disabled={true} >export all dataset</button>
                            </>
                        :
                            <>
                                <Spinner/>
                            </>
                        }
                        <button className="btn-circle close-modal" onClick={() => this.props.displayModalToggle()}>
                            <img src={process.env.PUBLIC_URL + "assets/images/close-no-circle.svg"} className="picto-close" alt="close"/>
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    analysis: state.analysis,
    companiesAnalysis: state.analysis.companies,
    zonesAnalysis: state.analysis.zones,
    productsAnalysis: state.analysis.products
})

const mapDispatchToProps = {
    removeFromAnalysis: removeFromAnalysis,
    addToAnalysis: addToAnalysis
}

export default connect(mapStateToProps, mapDispatchToProps)(AnalysisList)
