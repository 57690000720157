import { getData, postFiles , postData } from './index';

import {
  SET_ADMIN_IS_ACTIVE ,
  FETCH_COMPANIES_LIST ,
  FETCH_ZONES_LIST ,
  FETCH_PRODUCTS_LIST,
  RESET_ADMIN_LIST ,
  SET_UPLOADED_LIST,
  POST_FILE ,
  EXPORT_FINANCIAL ,
  RESET_EXPORT, 
  ADMIN_ERROR
} from './types';

export const setAdminIsActive = (data) => {
  return {
    type: SET_ADMIN_IS_ACTIVE,
    payload: data
  }
};

export const resetAdminError = () => {
  return {
    type: ADMIN_ERROR,
    payload: null
  }
};

export const setUploadedList = (data) => {
  return {
    type: SET_UPLOADED_LIST,
    payload: data
  }
};

export const resetAdminList = () => {
  return {
    type: RESET_ADMIN_LIST,
  }
};


export function fetchCompanies() {
  resetAdminError()
  const url = "/company";
  return dispatch => getData(FETCH_COMPANIES_LIST, ADMIN_ERROR, true, url, dispatch);
};

export function fetchZones() {
  const url = "/zone";
  return dispatch => getData(FETCH_ZONES_LIST, ADMIN_ERROR, true, url, dispatch);
};

export function fetchProducts() {
  
  const url = "/product";
  return dispatch => getData(FETCH_PRODUCTS_LIST, ADMIN_ERROR, true, url, dispatch);
};

export function postFinancialFile(data , info) {
  const url = "/finance/uploadfile";
  return dispatch => postFiles(POST_FILE, ADMIN_ERROR, true, url, dispatch , data , info);
};

export function postStockValueFile(data , info) {
  const url = "/finance/uploadstockvalue";
  return dispatch => postFiles(POST_FILE, ADMIN_ERROR, true, url, dispatch , data , info);
};

export function postExchangeFile(data , info) {
  const url = "/finance/exchange";
  return dispatch => postFiles(POST_FILE, ADMIN_ERROR, true, url, dispatch , data , info);
};

export function postFormulaFile(data , info) {
  const url = "/finance/uploadformula";
  console.log("formula" , data , info)
  return dispatch => postFiles(POST_FILE, ADMIN_ERROR, true, url, dispatch , data , info);
};

export function exportFinancial(data) {
  const url = "/export";
  return dispatch => postData(EXPORT_FINANCIAL, ADMIN_ERROR, true, url, dispatch , data );
};

export function resetExport() {
  return {
    type: RESET_EXPORT,
  }
}
