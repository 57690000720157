import React, { Component } from 'react';
import { connect } from 'react-redux';

import '../../styles/css/analysis.css';

import { setLabelColor } from '../../utils/utils';
import Spinner from '../Explorer/Spinner';
import Global from './Global';
import Industry from './Industry';
import Financial from './Financial';
import DataVizLinesChart from '../DataViz/DataVizLinesChart';

export class AnalysisNav extends Component {
    constructor(props) {
        super(props)
        this.state ={
            activeView: "global",
            activeAnalysis: this.props.activeAnalysis,
            globalIsActive : true,
            financialIsActive : false,
            industryIsActive : false
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.analysis !== this.props.analysis) {
            this.props.updateAnalysis(this.state.activeView, this.props.analysis)
        }
    }

    scrollToTop = (id) => {
        const top = document.getElementById(id);
        top.scrollIntoView({behavior : "smooth"})
    }

    setGlobalIsActive = () => {
        this.props.updateAnalysis("global", this.props.analysis)
        this.setState({
            activeView: "global"
        })
    }

    setFinancialIsActive = () => {
        this.props.updateAnalysis("financial", this.props.analysis)
        this.setState({
            activeView: "financial"
        })
    }

    setIndustryIsActive = () => {
        this.props.updateAnalysis("industry", this.props.analysis)
        this.setState({
            activeView: "industry"
        })
    }

    renderMarketShare = () => {
        if (this.props.analysisData.marketShare) {
            const titles = {
                title: "Market shares evolution",
                details: " for the last 10 years",
                flag: ""
            }
            const unit = this.props.analysisData.marketShare[0].unit + " (in t)"
            const dataSet = this.props.analysisData.marketShare.map((item) => {
                    let yearsFilter = item.data.filter(year => year.x <= this.props.selectedYear.toString())
                    const yearsValues = yearsFilter.filter(year => year.x >= (this.props.selectedYear - 10).toString())
                    const isActive = this.props.idIsOnActiveIdList(item.id)
                    yearsValues.map(item => {
                        if (item.y === 0) {
                           return item.y = null
                        }
                        return item
                    })
                    const lineChartItem = {
                    "id": item.name,
                    "color": this.props.activeIdList.length === 0 || isActive ? item.color : "rgba(180, 180, 180, 0.2)",
                    "data": yearsValues
                    }
                return lineChartItem
            })
            return <DataVizLinesChart
                    animate={true}
                    xScale={{
                      "type": "point"
                    }}
                    format={(d) => {
                        if (d.length > 4) {
                            if (d.split("-")[1] === "Q1.R") {
                                return d.split("-")[0]
                            } else {
                                return " "
                            }
                        } else {
                            return d
                        }
                    }}
                    unit={unit}
                    enableDots={true}
                    tooltip={slice => {
                        let month = new Date(slice.id).getMonth()
                        return (
                          <div style={{ color: '#bbb' }}>
                            <div>{month || month === 0 ?
                                    slice.id.substring(0,10) 
                                :
                                  slice.id
                                }
                            </div>
                            {slice.data.map((d, index) => (
                              <div
                                key={index}
                                style={{
                                  color: "#000000",
                                  padding: '3px 0',
                                }}
                              >
                                <strong style={{
                                  color: setLabelColor(d.serie.color)
                                }} >{d.serie.id} </strong>
                                {d.data.y !== null ? d.data.y.toFixed(1) + " " + unit : "no data"}
                              </div>
                            ))}
                          </div>
                      )}}
                    data={dataSet}
                    titles={titles}/>
        }
    }

    renderGrowthRate = () => {
        const titles = {
            title: "Sales growth rate YoY for the tire activity",
            details: `(${this.props.selectedYear - 4} to ${this.props.selectedYear})` ,
            flag: ""
        }
        if( this.props.analysisData.lineChart ) {
            const unit = "%"
            const dataSet = this.props.analysisData.lineChart.map((item) => {
                    let yearsFilter = item.data.filter(year => year.x <= (this.props.selectedYear + 1).toString())
                    let yearsValues = yearsFilter.filter(year => year.x > (this.props.selectedYear - 4).toString())
                    const isActive = this.props.idIsOnActiveIdList(item.id)
                    const lineChartItem = {
                    "id": item.name + " (" + item.type +")",
                    "color": this.props.activeIdList.length === 0 || isActive ? item.color : "rgba(180, 180, 180, 0.2)",
                    "data": yearsValues
                    }
                return lineChartItem
            })

            return <DataVizLinesChart
                        animate={true}
                        xScale={{
                          "type": "point"
                        }}
                        format={(d) => {
                          if (d.length > 4) {
                              if (d.split("-")[1] === "Q1.R") {
                                  return d.split("-")[0]
                              } else {
                                  return " "
                              }
                          } else {
                              return d
                          }
                        }}
                        unit={unit}
                        tooltip={slice => {
                            let month = new Date(slice.id).getMonth()
                            return (
                              <div style={{ color: '#bbb' }}>
                                <div>{month || month === 0 ?
                                        slice.id.substring(0,10) 
                                    :
                                      slice.id
                                    }
                                </div>
                                {slice.data.map((d, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      color: "#000000",
                                      padding: '3px 0',
                                    }}
                                  >
                                    <strong style={{
                                      color: d.serie.color,
                                    }} >{d.serie.id} </strong>
                                    {d.data.y !== null ? d.data.y.toFixed(1) + " " + unit : "no data"}
                                  </div>
                                ))}
                              </div>
                          )}}
                        enableDots={true}
                        data={dataSet}
                        titles={titles}/>
        }
    }

    render() {
        const { activeView } = this.state
        let btnDisabled = ""
        if (this.state.activeAnalysis === "zones") {
          btnDisabled = "btn-disabled"
        }
        const { activeIdList, idIsOnActiveIdList } = this.props
        return (
            <div className={this.props.displayModal ? "analysis-container blur" : "analysis-container"}>
                {!this.props.displayAnalysisSpinner ?
                    <div>
                        <div className="analysis-nav-container">
                            <button onClick={this.setGlobalIsActive} className={activeView === "global" ? "analysis-nav-button-active":"analysis-nav-button"}>Global</button>
                            <button onClick={this.setFinancialIsActive} disabled={this.state.activeAnalysis === "zones" ? true : false } className={activeView === "financial" ? `analysis-nav-button-active`: `analysis-nav-button ${btnDisabled}`}>Financial</button>
                            <button onClick={this.setIndustryIsActive} className={activeView === "industry"  ? "analysis-nav-button-active":"analysis-nav-button"}>Industry</button>
                        </div> 
                        <div className="analysis-scroll-container">
                            {activeView === "global" ?
                                    <Global
                                        scrollToTop={this.scrollToTop}
                                        idIsOnActiveIdList={idIsOnActiveIdList}
                                        selectedYear={this.props.selectedYear}
                                        analysis={this.props.analysis}
                                        analysisData={this.props.analysisData}
                                        renderGrowthRate={this.renderGrowthRate}
                                        renderRoEvolution={this.renderRoEvolution}
                                        activeAnalysis={this.props.activeAnalysis}
                                        renderMarketShare={this.renderMarketShare}
                                        activeIdList={activeIdList}/>
                                    :
                                    null
                            }
                            {activeView === "financial" ?
                                <Financial
                                    activeIdList={activeIdList}
                                    idIsOnActiveIdList={idIsOnActiveIdList}
                                    analysis={this.props.analysis}
                                    scrollToTop={this.scrollToTop}
                                    analysisData={this.props.analysisData}
                                    activeAnalysis={this.props.activeAnalysis}
                                    selectedYear={this.props.selectedYear}
                                    renderGrowthRate={this.renderGrowthRate}
                                    renderRoEvolution={this.renderRoEvolution}
                                />
                                :
                                null
                            }
                            {activeView === "industry" ?
                                <Industry
                                    idIsOnActiveIdList={idIsOnActiveIdList}
                                    activeIdList={activeIdList}
                                    activeAnalysis={this.props.activeAnalysis}
                                    analysis={this.props.analysis}
                                    scrollToTop={this.scrollToTop}
                                    analysisData={this.props.analysisData}
                                    selectedYear={this.props.selectedYear}
                                    />
                                :
                                null
                            }
                        </div>
                    </div>
                :
                    <div className="analysis-spinner-container">
                        <Spinner />
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    companiesAnalysis: state.analysis.companies,
    zonesAnalysis: state.analysis.zones,
    productsAnalysis: state.analysis.products
})

// const mapDispatchToProps = {

// }

export default connect(mapStateToProps, null )(AnalysisNav)
