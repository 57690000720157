import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as d3 from 'd3';
import _ from 'lodash';


import { addToAnalysis , removeFromAnalysis } from '../../actions/analysisActions';
import { hexToRGBa } from '../../utils/utils'
import '../../styles/css/companyDetails.css';
import DataVizPie from '../DataViz/DataVizPie';
import DataVizMap from '../DataViz/Map/DataVizMap';
import DataVizNoData from '../DataViz/DataVizNoData';

export class CompanyDetails extends Component {
  constructor(props) {
    super(props) 
      this.state = {
        companyIsNotOnAnalysis: true,
        pieDatas: [],
        activeView: "products",
        removePanel: false,
        mapDatas: {},
      }
  }

    componentDidMount() {
        this.isCompanyOnAnalysis()
        this.displayProducts()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.selectedYear !== this.props.selectedYear) {
            if (this.state.removePanel === false) {
                this.props.displayCompanyDetails(this.props.companyDetails)
            }
        }
        if (prevProps.explorerIsActive !== this.props.explorerIsActive ) {
            if (this.state.removePanel === false) {
                this.removeCompanyPanel()
            }
        }
        if (prevProps.companyDetails !== this.props.companyDetails ) {
            this.isCompanyOnAnalysis()
            this.setState({
                removePanel: false
            })
            if (this.state.activeView === "products") {
                this.displayProducts()
            } else if (this.state.activeView === "sales") {
                this.displaySales()
            } else {
                this.displayFinancial()
            }
        }
        if (prevState.activeView !== this.state.activeView) {
            if (this.state.activeView === "products") {
                this.displayProducts()
            } else if (this.state.activeView === "sales") {
                this.displaySales()
            } else {
                this.displayFinancial()
            }
        }
    }

    isCompanyOnAnalysis = () => {
        let found = this.props.analysis.find((d) =>{
            return d._id === this.props.companyDetails._id
        })
        if (found !== undefined ) {
            this.setState({
                companyIsNotOnAnalysis: false
            })
        } else { 
            this.setState({
                companyIsNotOnAnalysis: true
            })
        }
    }

    compareWithActualAnalysis = (companyToCompare) => {
        let found = this.props.analysis.find((d) =>{
            return d._id === companyToCompare._id
        })
        if (found === undefined ) {
            this.props.addToAnalysis(companyToCompare)
            this.setState({
                companyIsNotOnAnalysis: false
            })
        }
    }

    removeCompanyFromAnalysis = (companyToRemove) => {
        this.props.removeFromAnalysis(companyToRemove) 
        this.setState({
            companyIsNotOnAnalysis: true
        })
    }

    displayProducts = () => {
        if (this.props.companyDetails.mapDatas !== undefined) {
            this.generateNewMap(this.props.companyDetails.mapDatas.productsMap)
        } else {
            this.setState({
                mapDatas: [],
            })
        }
        if (this.props.companyDetails.pieDatas !== undefined) {
            this.setState({
                pieDatas: this.props.companyDetails.pieDatas.productsPie,
            })
        } else {
            this.setState({
                pieDatas: [],
            })
        }
        this.setState({
            activeView: "products"
        })
    }

    displaySales = () => {
        if (this.props.companyDetails.mapDatas !== undefined) {
            this.generateNewMap(this.props.companyDetails.mapDatas.salesMap)
        } else {
            this.setState({
                mapDatas: [],
            })
        }
        if (this.props.companyDetails.pieDatas !== undefined) {
            this.setState({
                pieDatas: this.props.companyDetails.pieDatas.salesPie,
            })
        } else {
            this.setState({
                pieDatas: [],
            })
        }
        this.setState({
            activeView: "sales"
        })
    }

    displayFinancial = () => {
        if (this.props.companyDetails.mapDatas !== undefined) {
            this.generateNewMap(this.props.companyDetails.mapDatas.financialMap)
        }
        if (this.props.companyDetails.pieDatas !== undefined) {
            this.setState({
                pieDatas: this.props.companyDetails.pieDatas.financialPie,
            })
        }
        this.setState({
            activeView: "financial"
        })
    }

    removeCompanyPanel = () => {
        const top = document.getElementById("top");
        top.scrollIntoView({behavior : "smooth"})
        this.setState({
            removePanel: !this.state.removePanel
        })
    }

    generateNewMap = (dataForMap) => {
        let maxArray = []
        for (let i in dataForMap) {
            maxArray.push(dataForMap[i].value);
        }
        const max = _.max(maxArray)

        const companyColor = this.props.companyDetails.color !== "#A1BCDE" ? this.props.companyDetails.color : "#1C4998"

        let color = d3.scaleLinear()
        .domain([0, max ])
        .range([hexToRGBa(companyColor, 0.2), companyColor ]);

        for (let val in dataForMap) {
           val = Object.assign( dataForMap[val], {color : color(dataForMap[val].value)})
        }

        this.setState({
            mapDatas: dataForMap,
        })
    }

    renderProductionOrSales = () => {
        let unit = this.state.activeView === "products" ? "ktp" : "kt sold"
        return (
            <>
            <div className="justify-content-center">
                <div className="nivo-pie">
                    {this.state.pieDatas.length > 0 ?
                        <DataVizPie
                            pieDatas={this.state.pieDatas}
                            margin={{
                                "top": 30,
                                "right": 10,
                                "bottom": 20,
                                "left": 10
                            }}
                            innerRadius={0.5}
                            enableSlicesLabels={true}
                            tooltip={({ id, value, color }) => {
                                const logo = id.split('')
                                return (
                                    <div className="tooltip">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/" + logo[0] + logo[1] + ".png"} className="picto-vehicles" alt="close"/>
                                        <strong style={{ color }}>
                                        {id}: {Math.round(value)} {unit}
                                        </strong>
                                    </div>
                                )
                            }}
                            />
                    :
                        <DataVizNoData />
                    }
                </div>
            </div>
            {this.state.activeView === "products" && this.state.pieDatas.length > 0 ? <p className="pie-legend">Production by type of product</p> : null }
            {this.state.activeView === "sales" && this.state.pieDatas.length > 0 ? <p className="pie-legend">Sales by type of product</p> : null }
            <div className="map-container justify-content-center">
                {this.state.mapDatas.length > 0 ?
                    <>
                        {this.state.activeView === "products" ?
                                <>
                                    <DataVizMap
                                        enableZoom={false}
                                        unit={"ktp"}
                                        color={"#1C4998"}
                                        mapDatas={this.state.mapDatas}/>
                                    <p className="pie-legend" >Production Footprint</p>
                                </>
                            :
                                null
                        }
                        {this.state.activeView === "sales" ?
                                <>
                                    <DataVizMap
                                        enableZoom={false}
                                        unit={"kUp"}
                                        color={"#1C4998"}
                                        noBorders={true}
                                        mapDatas={this.state.mapDatas}/>
                                    <p className="pie-legend" >Sales per region</p>
                                </>
                            :
                                null
                        }
                    </>
                :
                    <DataVizNoData />
                }
            </div>
            </>
        )
    }

    renderFinancialNumbers = (name,value) => {
        return (
            <div className="financial-number">
            <div className="color-left" style={{backgroundColor: this.props.companyDetails.color }}>
            </div>
                <div className="financial-number-content">
                    <h4 className="percentage-name">{name}</h4>
                    {value !== undefined ?
                        <span className="percentage" style={{color: this.props.companyDetails.color }}>{} {value}</span>
                    :
                        <span className="percentage-no-data">Data not Found</span>
                    }
                </div>
            </div>
        )
    }

    renderFinancial = () => {
        if (this.props.companyDetails.financial && this.props.companyDetails.financial !== {}) {
            return (
                <div className="financial-numbers-container">
                    {this.renderFinancialNumbers('Sales', this.props.companyDetails.financial.ventes) }
                    {this.renderFinancialNumbers('Operating margin', this.props.companyDetails.financial.ro )}
                    {this.renderFinancialNumbers('Gearing', this.props.companyDetails.financial.gearing )}
                    {this.renderFinancialNumbers('ROCE', this.props.companyDetails.financial.roce )}
                </div>
            )
        } else {
            return (
                <div className="no-data">
                    <DataVizNoData />
                </div>
            )
        }
    }

  render() {
    const { companyDetails } = this.props
    const { removePanel, activeView } = this.state
    let classDisableIdCard = "avoid-clicks"
    let idCardUrl;
    if (this.props.companyDetails.idCardUrl) {
        classDisableIdCard = ""
        idCardUrl = this.props.companyDetails.idCardUrl
    }
    return (
      <div className={removePanel ? "company-details-container anim-leave": "company-details-container anim-enter"}>
        <div className="remove-button-container">
          <button className="btn-circle" onClick={() => this.removeCompanyPanel()}>
              <img src={process.env.PUBLIC_URL + "assets/images/close-no-circle.svg"} className="picto-close"alt="close"/>
          </button>
        </div>
        <div className="scrollable-container">
            <div>
                <h1 id="top" className="company-name">{companyDetails.name}</h1>
                <h5 className="m-none text-grey">{companyDetails.legalName}</h5>
            </div>
            <div>
                <a href={idCardUrl} rel="noopener noreferrer" className={classDisableIdCard} target="_blank">
                    <button className={"btn-blue-rounded btn-id-card " + classDisableIdCard} >id card</button>
                </a>
            </div>
            <div className="buttons-container">
                <div className={activeView === "products" ? "pie-button-active" : "pie-button" } onClick={() => this.displayProducts()}>Tire production</div>
                <div className={activeView === "sales" ? "pie-button-active" : "pie-button" } onClick={() => this.displaySales()}>Tire sales</div>
                <div className={activeView === "financial" ? "pie-button-active" : "pie-button" } onClick={() => this.displayFinancial()}>Financials</div>
            </div>
            {activeView === "products" ?
                <div className="numbers-container">
                    <div className="number-and-label">
                        <span className="number text-blue">{companyDetails.rank}</span>
                        <span className="number-label text-grey">Rank</span>
                    </div>
                    <div className="number-and-label">
                        <span className="number text-blue">{companyDetails.nbFactories}</span>
                        <span className="number-label text-grey">Tire factories</span>
                    </div>
                    <div className="number-and-label">
                        <span className="number text-blue">{companyDetails.nbCountries}</span>
                        <span className="number-label text-grey">Countries</span>
                    </div>
                </div>
            :
                null
            }
            {this.state.activeView === "financial" ?
                this.renderFinancial()
                :
                this.renderProductionOrSales()
            }
        </div>
        <div className="analysis-buttons-container">
            {this.state.companyIsNotOnAnalysis?
                <button className="btn-blue-rounded" onClick={() => this.compareWithActualAnalysis(companyDetails)}>
                    Add analysis
                </button>
            :
                <button className="btn-blue-rounded bg-red" onClick={() => this.removeCompanyFromAnalysis(companyDetails)}>
                    Remove analysis
                </button>
            }
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
    analysis: state.analysis.companies
})

const mapDispatchToProps = {
    addToAnalysis: addToAnalysis,
    removeFromAnalysis: removeFromAnalysis
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetails)
