import React, { useState } from 'react';
import { connect } from 'react-redux';

import '../../styles/css/userModale.css';
import { validateEmail } from '../../utils/utils';
import { addUserAction } from '../../actions/usersActions';

function ModaleAddUser(props) {
  const [ email, setEmail ] = useState("");
  const [ buttonIsDisabled , setButtonIsDisabled ] = useState(true);
  const [ message , setMessage ] = useState();

  function handelChangeEmail(email) {
    if (validateEmail(email)) {
      setEmail(email);
      setButtonIsDisabled(false);
      setMessage();
    } else {
      setMessage("This email is not valid");
    }
  }

  function closeModale() {
    props.ModaleAddUserToggle();
    setEmail();
    document.getElementById("user-email").reset()
    setButtonIsDisabled(true);
    setMessage();
  }

  function addUser() {
    closeModale()
    props.addUserAction({ email: email })
  }

  return (
    <div className={`modale-user ${props.modaleUserIsActive? "active" : "not-active" }`}>
      <div className="modale-container">
        <h4>New user</h4>
        <span className="text-error">{message}</span>
        <form  id="user-email">
          <input
            type='email'
            className="input"
            placeholder="email"
            onChange={(e) => handelChangeEmail(e.target.value)} />
        </form>
        <button
          className={`btn-blue-rounded ${buttonIsDisabled ? "disabled" : ""}`}
          onClick={() => addUser()}
          disabled={buttonIsDisabled}>
          Add user
        </button>
        <button className="btn-close" onClick={() => closeModale()}>
          <img src={process.env.PUBLIC_URL + "assets/images/close-blue.svg"} alt="close"/>
        </button>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {

  }
};

const mapDispatchToProps = {
  addUserAction: addUserAction
}

export default connect(mapStateToProps, mapDispatchToProps)(ModaleAddUser)
