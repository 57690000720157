import * as _ from "lodash";
import * as d3 from "d3";

export const API_URL = "https://api.crossdata.intelligencemaker.com/api";

export const hexToRGBa = (hex, alpha) => {
    let r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
        return "rgb(" + r + ", " + g + ", " + b + ")";
    }
}

export const lightOrDark = (color) => {

    // Variables for red, green, blue values
    var r, g, b, hsp;
    
    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {

        // If HEX --> store the red, green, blue values in separate variables
        color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
        
        r = color[1];
        g = color[2];
        b = color[3];
    } 
    else {
        
        // If RGB --> Convert it to HEX: http://gist.github.com/983661
        color = +("0x" + color.slice(1).replace( 
        color.length < 5 && /./g, '$&$&'));

        r = color >> 16;
        // eslint-disable-next-line
        g = color >> 8 & 255;
        b = color & 255;
    }
    
    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    hsp = Math.sqrt(
    0.299 * (r * r) +
    0.587 * (g * g) +
    0.114 * (b * b)
    );

    // Using the HSP value, determine whether the color is light or dark
    if (hsp>127.5) {

        return 'light';
    } 
    else {

        return 'dark';
    }
}

export const setLabelColor = (colorValue) => {
    var color = colorValue;
    if (colorValue === "#FFFF00") {
        color = "#dede0d"
    };
    return color;
}

export const parentWidth = (elem) => {
    return elem.parentElement.clientWidth;
}

export const parentHeight = (elem) => {
    return elem.parentElement.clientHeight;
}

export const  setColorForPie =(dataForPie) => {
    let maxArray = []
    dataForPie.map((item ) => {
        return maxArray.push(item.value);
    })
    const max = _.max(maxArray)
    let minArray = []
    dataForPie.map((item ) => {
        return minArray.push(item.value);
    })
    const min = _.min(minArray)

    let color = d3.scaleLinear()
    .domain([min, max ])
    .range(['rgb(161, 188, 222)', 'rgb(0, 74, 152)']);

    dataForPie.map((item ) => {
       return item.color = color(item.value)
    })
    return dataForPie.sort(function(a, b){
        return a.value-b.value
    });
}

export const validateEmail = (email) => {
    // eslint-disable-next-line
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
