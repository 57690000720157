import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import axios from 'axios';
import * as d3 from 'd3';

import '../../styles/css/Explorer.css';
import { API_URL } from '../../utils/utils';
import { setApiData } from '../../actions/filterActions';
import { addToAnalysis , removeFromAnalysis} from '../../actions/analysisActions';
import { resetAdminList } from '../../actions/adminActions';

import Spinner from './Spinner';
import NavExplorer from './NavExplorer';
import BubblesNav  from './BubblesNav';
import AnalysisList  from './AnalysisList';
import YearsSlider from './YearsSlider';
import AnalysisNav from '../Analysis/AnalysisNav';

export class Explorer extends Component {
	constructor(props) {
		super(props)
		this._isMounted = true;
		this.state = {
			companyDetails: null,
			activeAnalysis: "companies",
			analysisList: this.props.analysis.companies,
			selectedYear: 2018,
			explorerIsActive: true,
			companies: [],
			products: [],
			zones: [],
			activeIdList: [],
			searchOptions: [],
			analysisData: [],
			displaySpinner: false,
			displayAnalysisSpinner: false,
			displayFilterSpinner: false,
			displayModal: false,
			// filterIsDisable: " avoid-clicks",
		}
	}

	componentWillMount() {
		this.props.setActiveView('explorer')
		this.props.resetAdminList();
		if(!this.props.auth.isAuthenticated) {
				this.props.history.push("/");
		}
		axios.get(API_URL + "/dataviz/explorer")
		.then(res => {
			this.getAllDatasFromApi(res);
		})
		this.setState({
				activeAnalysis: "companies",
		})
	}

	componentDidUpdate(prevState, prevProps) {
		if (prevState.analysis !== this.props.analysis) {   
			if (this.state.activeAnalysis !== "companies") {
				if (this.props.analysis.zones.length > 0 ) {
					this.removeExplorer();
				}
				if (this.props.analysis.products.length > 0) {
					this.removeExplorer();
				}
			}
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
		this.props.setApiData([])
	}

	// setFilterDisable = (string) => {
	//     this.setState({ filterIsDisable: string })
	// }

	getAllDatasFromApi = (res) => {
		let companiesData  = [];
		res.data.companies.map(item => {
			return companiesData.push(Object.assign(item ,{ analysisType: "companies"}));
		})
		const zonesData = [];
		res.data.zones.map(item => {
			return zonesData.push(Object.assign(item,{ analysisType: "zones" }));
		})
		const productsData = [];
		res.data.products.map(item => {
			return productsData.push(Object.assign(item ,{ analysisType: "products" }));
		})
			let optionsData = [...companiesData, ...zonesData, ...productsData];
			let optionsArray = [];
			optionsData.map(item => {
				return optionsArray.push(Object.assign(
					{_id : item._id},
					{label : item.name},
					{name : item.name},
					{value : item.name},
					{color : item.color},
					{analysisType: item.analysisType}
				));
			})
			if (this.state.activeAnalysis === "zones") {
				this.props.setApiData(zonesData);
			} else if (this.state.activeAnalysis === "products") {
				this.props.setApiData(productsData);
			} else {
				this.props.setApiData(companiesData);
			}
		if (this._isMounted) {
			this.setState({
				zones: zonesData,
				products: productsData,
				companies: companiesData,
				searchOptions: optionsArray,
				displayFilterSpinner: false
			})
		}
  }

	getCompaniesFromApi = () => {
			this.props.setApiData(this.state.companies)
			this.setState({
					activeAnalysis: "companies",
			})
	}

	getZonesFromApi =() => {
			this.props.setApiData(this.state.zones)
			this.setState({
					activeAnalysis: "zones",
			})
	}

	getProductsFromApi =() => {
			this.props.setApiData(this.state.products)
			this.setState({
					activeAnalysis: "products",
			})
	}

	displayCompanyDetails = (param) => {
			if (param.analysisType === "zones" || param.analysisType === "products") {
					this.props.addToAnalysis(param)
			}
			if(param.analysisType === "companies") {
					axios.get(API_URL + `/company/${param._id}`)
					.then(res => {
							const company = res.data.company
							const analysisType = { analysisType: "companies" }

							if (res.data.years.length > 0) {
									let data = res.data.years.filter(item => item.year === this.state.selectedYear.toString())
									data = data[0]
									const rank = {rank : data.rank ? data.rank : ""}
									const nbCountries = {nbCountries : data.nbCountries}
									const nbFactories = {nbFactories: data.nbFactories}
									this.setColorForPie(data.pieDatas.productsPie)
									this.setColorForPie(data.pieDatas.salesPie)
									const map = {
											mapDatas: {
													productsMap: this.getObjectFromArray(data.mapDatas.productsMap),
													salesMap: this.getObjectFromArray(data.mapDatas.salesMap),
											}
									}

									const pie = {
											pieDatas: {
													productsPie: this.getRoundedValue(data.pieDatas.productsPie),
													salesPie: this.getRoundedValue(data.pieDatas.salesPie),
											},

									}

									if (data.financialDatas.ventes) {
											let sales = data.financialDatas.ventes.split('').reverse().slice(6, data.financialDatas.ventes.length)
											let formatedNb = []
											for (let i = 0 ; i < sales.length ; i++) {
													if (i !== 3 ) {
																	formatedNb.push(sales[i])
													} else {
															formatedNb.push(" ")
															formatedNb.push(sales[i])
													}
											}
											data.financialDatas.ventes = formatedNb.reverse().join("") + " M€";
									}
									const financial = { financial : data.financialDatas}

									this.setState({
											companyDetails: Object.assign( rank, nbFactories , nbCountries , company , pie , map , financial, analysisType )
									})
							} else {
									this.setState({
											companyDetails: Object.assign( company , analysisType )
									})
							}
					})
			}
	}

	setColorForPie =(dataForPie) => {
		let maxArray = [];
		for (let i in dataForPie) {
			maxArray.push(dataForPie[i].value);
		}
		const max = _.max(maxArray);
		let color = d3.scaleLinear()
		.domain([0, max ])
		.range(['rgb(161, 188, 222)', 'rgb(0, 74, 152)']);

		for ( let val in dataForPie) {
			val = Object.assign( dataForPie[val], {color : color(dataForPie[val].value)})
		}
	}

	getObjectFromArray(array) {
		let result = {}
		array.forEach((item,i) => {
			let obj = {}
			obj.value = Math.round(item.value)
			result[item.id] = obj
			result.length = i+1
		})
		return result
	}

	getRoundedValue(array) {
		let result = [];
		array.map((item) => {
			let obj = {};
			obj.label = item.label;
			obj.id = item.id;
			obj.color = item.color;
			obj.value = Math.round(item.value);
			return result.push(obj);
		})
		return result;
	}

	getSelectedYear = (year) => {
		this.setState({
			selectedYear: year
		})
	}

	removeCompanyDetails = (param) => {
		this.setState({
			companyDetails: param
		})
	}

	removeExplorer = () => {
			this.setAllToActiveId()
			this.setState({
					explorerIsActive: !this.state.explorerIsActive,
					activeId:[]
			})
			if (this.state.explorerIsActive) {
					this.props.setActiveView('analysis')
			} else {
					this.props.setActiveView('explorer')
			}
	}

	setSelectedAnalysisList = (list) => {
			this.setState({
					analysisList: list 
			})
	}

	getAnalysisFromApi = ( activeView ) => {
			this.setState({
					displayAnalysisSpinner: true
			})
			const idList = [];
			this.state.analysisList.map(item => {
					return idList.push(item._id)
			})
			axios.post(API_URL + `/dataviz/${this.state.activeAnalysis}/${activeView}`, { idList: idList })
			.then(res => {
					const data  = res.data;
					if (data) {
							this.setState({
									analysisData: data,
									displayAnalysisSpinner: false
							})
					} else {
							this.setState({
									displayAnalysisSpinner: false
							})
					}
			});
			this.removeExplorer()
	}

	updateAnalysis =( activeView , analysis ) => {
			this.setState({
					displayAnalysisSpinner: true
			})
			const idList = [];
			analysis.map(item => {
					return idList.push(item._id)
			})
			axios.post(API_URL + `/dataviz/${this.state.activeAnalysis}/${activeView}`, { idList: idList })
			.then(res => {
					const data  = res.data;
					if (data) {
							this.setState({
									analysisData: data,
									displayAnalysisSpinner: false
							})
					} else {
							this.setState({
									displayAnalysisSpinner: false
							})
					}
			});
	}

	setAllToActiveId = () => {
			this.setState({
					activeIdList: []
			})
	}

	idIsOnActiveIdList = (id) => {
			const isActive = this.state.activeIdList.filter(item => item === id)
			if (isActive.length > 0) {
					return true
			} else {
					return false
			}
	}

	setActiveIdList = (id) => {
			const isActive = this.idIsOnActiveIdList(id)
			if (isActive)
					this.setState({
							activeIdList: this.state.activeIdList.filter(item => item === !id)
					})
			else {
					this.setState({
							activeIdList: [...this.state.activeIdList, id]
					})
			}
	}

	setActiveView =(viewType) => {
			this.setState({
					activeView: viewType
			})
	}
	
	toggleFilterSpinner = () => {
			this.setState({
					displayFilterSpinner: !this.state.displayFilterSpinner
			})
	}

	displayModalToggle = () => {
			this.setState({
					displayModal: !this.state.displayModal
			})
	}

	render() {
		const { dataFromApi } = this.props;
		const { activeAnalysis, explorerIsActive } = this.state;
		return (
			<div className="app-container">
				{dataFromApi.length > 0 ?
					<div className={explorerIsActive? "fullscreen-container explorer-enter" : "fullscreen-container explorer-leave"}>
						<NavExplorer
							// filterIsDisable={this.state.filterIsDisable}
							toggleFilterSpinner={this.toggleFilterSpinner}
							searchOptions={this.state.searchOptions}
							getAllDatasFromApi={this.getAllDatasFromApi}
							getZonesFromApi={this.getZonesFromApi}
							getCompaniesFromApi={this.getCompaniesFromApi}
							getProductsFromApi= {this.getProductsFromApi}
							displayCompanyDetails={this.displayCompanyDetails}
							/>
						<div className="explorer-container" style={{ opacity: explorerIsActive ? 1 : 0 }}>
							<BubblesNav
								// setFilterDisable={this.setFilterDisable}
								idIsOnActiveIdList={this.props.idIsOnActiveIdList}
								displayFilterSpinner={this.state.displayFilterSpinner}
								selectedYear={this.state.selectedYear}
								explorerIsActive={this.state.explorerIsActive}
								companyDetails={this.state.companyDetails}
								removeCompanyDetails={this.removeCompanyDetails}
								displayCompanyDetails={this.displayCompanyDetails}
								getZonesFromApi={this.getZonesFromApi}
								getCompaniesFromApi={this.getCompaniesFromApi}
								getProductsFromApi= {this.getProductsFromApi}
								displayModal={this.state.displayModal}
								dataFromApi={dataFromApi}/>
						</div>
						<div>
							< YearsSlider
								getSelectedYear={this.getSelectedYear}/>
							< AnalysisList
								selectedYear={this.state.selectedYear}
								setSelectedAnalysisList={this.setSelectedAnalysisList}
								idIsOnActiveIdList={this.idIsOnActiveIdList}
								setAllToActiveId={this.setAllToActiveId}
								updateAnalysis={this.updateAnalysis}
								activeIdList={this.state.activeIdList}
								setActiveIdList={this.setActiveIdList}
								getAnalysisFromApi={this.getAnalysisFromApi}
								removeExplorer={this.removeExplorer}
								explorerIsActive={this.state.explorerIsActive}
								activeAnalysis={activeAnalysis}
								displayCompanyDetails={this.displayCompanyDetails}
								displayModalToggle={this.displayModalToggle}
								displayModal={this.state.displayModal}/>
						</div>
					</div>
				:
					<div className="explorer-spinner-container">
						<Spinner />
					</div>
				}
				{!this.state.explorerIsActive ?
					<AnalysisNav
						explorerIsActive={this.state.explorerIsActive}
						displayModal={this.state.displayModal}
						updateAnalysis={this.updateAnalysis}
						idIsOnActiveIdList={this.idIsOnActiveIdList}
						analysis={this.state.analysisList}
						selectedYear={this.state.selectedYear}
						setActiveView={this.setActiveView}
						analysisData={this.state.analysisData}
						analysisIsActive={!this.state.explorerIsActive}
						activeIdList={this.state.activeIdList}
						activeAnalysis={activeAnalysis}
						displayAnalysisSpinner={this.state.displayAnalysisSpinner}
						/>
					:
					null
				}
			</div>
		)
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  auth: state.auth,
  dataFromApi: state.dataFromApi,
  analysis: state.analysis
})

const mapDispatchToProps = {
	setApiData : setApiData,
	addToAnalysis: addToAnalysis,
	removeFromAnalysis: removeFromAnalysis,
	resetAdminList: resetAdminList
}

export default connect(mapStateToProps, mapDispatchToProps)(Explorer)
