import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../styles/css/FiltersandSearch.css';
import { addToAnalysis } from '../../actions/analysisActions';

import Select from 'react-select';

const customSearch = {
    option: (provided, state) => ({
        ...provided,
        color:'white',
        backgroundColor: state.isSelected || state.isFocused? '#194084' : "transparent",
        paddingLeft: 12,
    }),
    control: () => ({
      width: 300,
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        display:'none',
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        color:'white',
        top: 5,
        left: 12,
        paddingLeft: 0,
        width: 250,
        borderBottom: '1px solid white'
    }),
    input:(provided, state) => ({
        ...provided,
        color:'white',
        fontSize: 14,
        marginLeft:0,
    }),
    menu:(provided, state) => ({
        ...provided,
        margin:0,
        padding: 0,
        fontSize: 14,
        top: 34,
        color: "white",
        backgroundColor:'#004A98',
        borderRadius: 8,
    }),
    menuList: (provided, state) => ({
        ...provided,
        color:'white',
        margin: 0,

    }),
    placeholder: (provided, state) => ({
        ...provided,
        color: 'white',
        fontSize: 14,
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
      const color = "white";
  
      return { ...provided, opacity, transition , color };
    }
}

export class Search extends Component {
    constructor() {
        super()
        this.state = {
            selectedOption: null
        }
    }

    handleChange = (selectedOption) => {
        this.setState({
            selectedOption
        })
        if (selectedOption.analysisType === "companies") {
            this.props.getCompaniesFromApi();
            this.props.displayCompanyDetails(selectedOption);
            this.props.displaySearch();
        } else if (selectedOption.analysisType === "zones") {
            this.props.getZonesFromApi();
            this.props.addToAnalysis(selectedOption);
            this.props.displaySearch();
        } else if (selectedOption.analysisType === "products") {
            this.props.getProductsFromApi();
            this.props.addToAnalysis(selectedOption);
            this.props.displaySearch();
        }
    }

    render() {
        return (
            <>
                {!this.props.searchIsDisplayed ?
                    <div className="search-close">
                    <img onClick={() => this.props.displaySearch()} src={process.env.PUBLIC_URL + "assets/images/search.png"} className="picto-search"alt="logo"/>
                    </div>
                :
                    <div className="search-open">
                        <div className="input-search-container">
                            <div className="picto-search-open">
                                <img onClick={() => this.props.displaySearch()} src={process.env.PUBLIC_URL + "assets/images/close.svg"} className="picto-search" alt="logo"/>
                            </div>
                            <Select
                                styles={customSearch}
                                className="input-search"
                                value={this.state.selectedOption}
                                placeholder="Your research"
                                options={this.props.searchOptions}
                                onChange={this.handleChange} isSearchable />
                        </div>
                    </div>
                }
            </>
        )
    }
}

const mapDispatchToProps = {
    addToAnalysis: addToAnalysis
}

export default connect(null, mapDispatchToProps)(Search)
