import React, { Component } from 'react';
import * as d3 from 'd3';
import _ from 'lodash';

export default class DataVizWaterfall extends Component {
    componentDidMount( ) {
        this.renderWaterfall()
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            d3.select(this.refs.waterfall).select("svg").remove();
            this.renderWaterfall()
        }
    }

    componentWillUnmount() {
        d3.select(this.refs.waterfall).select("svg").remove();
    }

    renderWaterfall = () => {
        let width = this.props.width
        if (document.getElementById("waterfall-size").offsetWidth < this.props.width ) {
            width = document.getElementById("waterfall-size").offsetWidth
        }

        const darwWaterfall = () => {
            let data = [];
            let maxValueLessMore = 0;

            const newDataSet = _.cloneDeep(this.props.waterfallData)
            newDataSet.map((d, index) => {
                let yearSum = 0
                d.data.forEach((d) => {
                    yearSum += d.value
                })
                let year = Object.assign(
                    d,
                    { "color" : "#004a98"},
                    { "total": yearSum}
                )
                data.push(year)

                let next = _.cloneDeep(this.props.waterfallData[index+1])
                if (next !== undefined) {
                    let lessData = []
                    let moreData = []
                    let totalLess = 0
                    let totalMore = 0
                    d.data.forEach((d, index) => {
                        let targetObj = _.filter(next.data, function(o) {
                            if (o.label === d.label) {
                                return o;
                            }
                        });
                        if (targetObj[0] === undefined) {
                            targetObj[0] = {
                                color: "#963634",
                                label: next.data[d.label],
                                value: 0
                            }
                        }

                        let value = d.value - targetObj[0].value;
                        if (value > 0 ) {
                            value = Math.abs(value)
                            let lessObj = Object.assign(
                                { "label" : d.label },
                                { "color": d.color },
                                { "value" : value }
                            )
                            lessData.push(lessObj)
                            totalLess+= value
                            if (totalLess > maxValueLessMore ) {
                                maxValueLessMore = totalLess
                            }
                        } 
                        if (value < 0 ) {
                            value = Math.abs(value)
                            let moreObj = Object.assign(
                                { "label" : d.label },
                                { "color": d.color },
                                { "value" : value }
                            )
                            moreData.push(moreObj)
                            totalMore+= value
                            if (totalMore > maxValueLessMore ) {
                                maxValueLessMore = totalMore
                            }
                        }
                    })

                    let less = Object.assign(
                        { "y": d.total - totalLess },
                        { "label": "less"},
                        { "color" : "red"},
                        {"total": totalLess },
                        { "data" : lessData }
                    )

                    let more = Object.assign(
                        { "y": d.total },
                        { "label": "more"},
                        { "color" : "green"},
                        { "total": totalMore },
                        { "data" : moreData }
                    )
                    data.push(less);
                    data.push(more);

                }
                return data;
            })

            const margin = { top: 35, right: 35, bottom: 50, left: 60 };
            width = width - margin.left -margin.right;
            let height = this.props.height - margin.top - margin.bottom;

            // const that = this;
            const xRange = data.map( (d , i) => {
                let barWitdh = width / data.length * i
                return barWitdh
            })
            var x = d3.scaleOrdinal().range(xRange)

            var y = d3.scaleLinear().range([height, 0]);

            const xAxis = d3.axisBottom(x)

            const yAxis = d3.axisLeft(y);

            var svg = d3.select(this.refs.waterfall)
            .append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

            data.forEach(function (d) {
                var y0 = 0;

                d.rates = d.data.map(function (obj) {
                    return {
                        name: obj.label,
                        y: d.y,
                        y0: y0,
                        y1: y0 += +obj.value,
                        amount: obj.value,
                        color: obj.color
                    };
                });
            });

            x.domain(data.map(function (d , i) {
                return i;
            }));

            let maxDomain = _.max(_.map(data, function(d){
                return _.sum(_.map(d.data,'value'))
            }))

            y.domain([0, maxDomain + maxValueLessMore])

            svg.append("g")			
            .attr("class", "grid")
            .call(make_y_gridlines()
                .tickSize(-width)
                .tickFormat("")
            )

            function make_y_gridlines() {		
                return d3.axisLeft(y)
            }

            svg.append('g')
                .attr('class', 'axis axis--x')
                .attr('transform', `translate( ${(width / data.length) / 2 },${height} )`)
                .call(xAxis)
                .selectAll("text")
                .style("font-size", "14px")
                .style('fill', "#004a98")
                .data(data)
                .text(function(d) {
                    if (d.label === "less" || d.label === "more") {
                        return "";
                    } else {
                        return d.label
                    }
                });

            svg.append('g')
                .attr('class', 'axis axis--y')
                .attr('transform', `translate(0,-5)`)
                .call(yAxis)
                .selectAll("text")
                .style('fill', "#004a98");

            svg.selectAll(".line")
                .data(data).enter()
                .append("line")
                .attr("class", "line")
                .attr('transform', `translate( ${(width / data.length) / 4 }, 0)`)
                .attr("y1", function(d) {
                    if (d.color === "green") {
                        return y(d.y)
                    }
                })
                .attr("y2", function(d) {
                    if (d.color === "green") {
                        return y(d.y)
                    }
                })
                .attr("x1", function(d, i) {
                    i++
                    if( i % 3 === 0 ) {
                        if( i % 6 === 0 ) {
                            return width / 2
                        } else {
                            return 0
                        }
                    }
                })
                .attr("x2", function(d, i) {
                    i++
                    if( i % 3 === 0 ) {
                        if( i % 6 === 0 ) {
                            return (width / data.length) * 5
                        } else {
                            return (width / data.length) * 2.5
                        }
                    }
                })
                .style("stroke", function(d) {
                    if (d.color === "green") {
                        return "rgba(100,100,100, 0.8)"
                    }
                })
                .style("stroke-dasharray", "4,2")
                .style("stroke-width", "1");

            var column = svg.selectAll(".column")
                .data(data).enter()
                .append("g")
                .attr("class", "column")
                .attr("transform", function (d ,i) {
                    return "translate(" + x(i) + ",0)";
                });

            svg.selectAll(".total")
                .data(data).enter()
                .append("g")
                .append("text")
                .attr("class", "total")
                .style("font-size", "12px")
                .attr('transform', `translate( ${(width / data.length) / 3 }, 0)`)
                .attr("x", function (d ,i) {
                    return x(i);
                })
                .attr("y", function (d ,i) {
                    if (d.label === "less" || d.label === "more") {
                        return y(d.y + d.total) - 5;
                    } else {
                        return y(d.total) - 5
                    }
                })
                .style("fill", function (d) {
                    return d.color;
                })
                .text(function(d) {
                    if (d.label === "less" ) {
                        return "-" + Math.round(d.total);
                    }
                    if (d.label === "more" ) {
                        return "+" + Math.round(d.total);
                    } else {
                        return Math.round(d.total);
                    }
                });

            let div = d3.select("body").append("div")
                .attr("class", "tooltip")
                .style("display", "none")
                .style("position", "absolute")
                .style("opacity", 0);

            column.selectAll("rect")
                .data(function (d) {
                    return d.rates;
                })
                .enter()
                .append("rect")
                .attr("width", (width / data.length) / 2 )
                .attr('transform', `translate( ${(width / data.length) / 4 }, 0)`)
                .attr("y", function (d) {
                    if (d.y === 0) {
                        return y(d.y1);
                    } else {
                        return y(d.y1 + d.y);
                    }
                })
                .attr("class", "waterfall-item")
                .attr("stroke", "white")
                .attr("stroke-width", "1")
                .attr("height", function (d) {
                    return y(d.y0) - y(d.y1);
                })
                .style("fill", function (d) {
                    return d.color;
                })
                .on("mouseover", function(d, i) {
                    d3.select(this)
                        .transition()
                        .duration(200)
                        .style("fill", function (d) {
                        return d3.color(d.color).darker(0.7);
                    });
                  div.transition()
                    .duration(200)
                        .style("display", "block")
                        .style("opacity", 1);
                    div.html("<strong>" + d.name + "</strong><span style='color: #000000'> " + d.amount.toFixed(0) + " ktc</span>")
                        .style("left", (d3.event.pageX + 20) + "px")
                        .style("top", (d3.event.pageY) + "px")
                        .style("z-index", "3002")
                        .style("background-color", "white")
                        .style("padding", "4px")
                        .style("color", d.color)
                        .style("border-radius","3px")
                        .style("box-shadow", "0.2rem 0.5rem 1rem rgba(48, 48, 48, 0.5)");
                    })
                .on("mouseout", function(d ,i) {
                    d3.select(this)
                        .transition()
                        .duration(500)
                        .style("fill", function (d) {
                        return d.color;
                    });
                    div.transition()
                        .duration(500)
                        .style("display", "none")
                        .style("opacity", 0);
                    });  
            }
        if ((width / this.props.waterfallData.length) / 2 !== Infinity ) {
            return darwWaterfall() 
        }
    }

    render() {
        return (
        <div ref="waterfall" id="waterfall-size">

        </div>
        )
    }
}
