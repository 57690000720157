import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { logoutUser } from '../../actions/authentification';
import { setAdminIsActive } from '../../actions/adminActions';

import UserConnection from '../Auth/UserConnection';
import '../../styles/css/header.css';

export class Header extends Component {

  render() {
    const { user , modaleIsActive } = this.props
    return (
      <header>
          <div className={`main-nav ${modaleIsActive ? "blur" : ""}`}>
            <img src={process.env.PUBLIC_URL + "assets/images/michelin-logo.png"} alt="logo"/>
            <div className="links">
              {user.role !== "Member" ?
                <>
                  {this.props.activeView === "explorer" ?
                    <>
                      <button onClick={() => this.props.history.push('/admin')} className="btn-blue-rounded">
                        <span>Administration</span>
                      </button>
                      {user.role !== "Owner" &&
                        <button onClick={() => this.props.history.push('/users')} className="btn-blue-rounded">
                          <span>Users</span>
                        </button>
                      }
                    </>
                    :
                    null
                  }
                  {this.props.activeView === "admin" ?
                    <>
                      <button onClick={() => this.props.history.push('/explorer')} className="btn-blue-rounded">
                        <span>Back to dataviz</span>
                      </button>
                      {user.role !== "Owner" &&
                        <button onClick={() => this.props.history.push('/users')} className="btn-blue-rounded">
                          <span>Users</span>
                        </button>
                      }
                    </>
                    :
                    null
                  }
                   {this.props.activeView === "users" ?
                    <>
                      <button onClick={() => this.props.history.push('/explorer')} className="btn-blue-rounded">
                        <span>Back to dataviz</span>
                      </button>
                      <button onClick={() => this.props.history.push('/admin')} className="btn-blue-rounded">
                        <span>Administration</span>
                      </button>
                    </>
                    :
                    null
                  }
                </>
                :
                null
              }
              <UserConnection user={user}/>
            </div>
          </div>
      </header>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  adminIsActive: state.admin.adminIsActive
})

const mapDispatchToProps = {
  logout: logoutUser,
  setAdminIsActive: setAdminIsActive
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
