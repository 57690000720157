import { SET_CURRENT_FILTER } from '../actions/types';

const initialState = []

export default function(state = initialState, action ) {
    switch (action.type) {
        case SET_CURRENT_FILTER:
            return  action.payload
        default: 
            return state;
    }
}