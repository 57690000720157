import { getData , postData , deleteData , putData } from './index';

import {  FETCH_USERS_LIST , ADD_USER , UPDATE_USER , USERS_ERROR , DELETE_USER } from './types';

export function fetchUsersList() {
  const url = "/user";
  return dispatch => getData(FETCH_USERS_LIST, USERS_ERROR, true, url, dispatch);
}

export function addUserAction(obj) {
  const url = "/auth/register";
  return dispatch => postData(ADD_USER , USERS_ERROR, true , url, dispatch, obj);
}

export function deleteUserAction(id) {
  const url = "/user/" + id;
  return dispatch => deleteData(DELETE_USER , USERS_ERROR, true , url, dispatch, id);
}

export function updateUserAction(obj) {
  const url = "/user/" + obj._id;
  return dispatch => putData(UPDATE_USER , USERS_ERROR, true , url, dispatch, obj);
}