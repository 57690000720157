import { combineReducers } from 'redux';
import errorReducer from './errorReducer';
import authReducer from './authReducer';
import filterReducer from './filterReducer';
import apiReducer from './apiReducer';
import analysisReducer from './analysisReducer';
import adminReducer from './adminReducer';
import usersReducer from './usersReducer';

export default combineReducers({
    errors: errorReducer,
    auth: authReducer,
    filter: filterReducer,
    dataFromApi: apiReducer,
    analysis: analysisReducer,
    admin: adminReducer,
    users: usersReducer
});
